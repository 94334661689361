import { useEffect, useContext } from "react";
import Context from '../context';


function SelectTipoNormativa(props) {

    const context=useContext(Context);

      useEffect(()=>{
        if(context.datosTipo.length==0){
          context.fetchTituloTipo('ttn')
        }
      },[])


   return (
      <>
            <select id="tn" onChange={e=>context.pushParam([e.target.value.split('/'),'tnorma'],false)} name="tn" className="form-control input-lg py-1">
                    <option  value={`${''}/${'tnorma'}`}>{context.tituloTipo}</option> 
                    {context.datosTipo!== undefined && context.datosTipo.length?
                      <>
                        {
                          context.datosTipo?.map((tip,i)=>{
                            return(
                              <option key={i} value={`${tip?.idtiponorma}/${tip?.descripcion}`}>{tip?.descripcion}</option>
                            )
                          }
                          )
                        }
                      </>
                      :
                      null
                      } 
            </select>
      </>
   )
}

export default SelectTipoNormativa; 