import React from 'react'
import {Link} from 'react-router-dom';
import Context from '../../components/context';
import {useContext} from 'react'
import {pathImgPerfil } from '../../components/paths'
import {sinFoto} from '../../contenido/constantes'

const Jgm = (props) => {

  const context=useContext(Context);
  const n1Jgm= '001'
  const elemJgm= context.listaMinHome?.filter((unidad)=>unidad.n1==n1Jgm)[0]
  const estiloH3 = elemJgm?.unidad?.length < 70 ? 'fontSizeMayor' : 'fontSizeMenor' 
  const nombreImg=  elemJgm?.versionImgP ? `${elemJgm?.n1}.png?v=${elemJgm?.versionImgP}` : `${sinFoto}.png` 
  
  return (
        <div>
              {
                elemJgm ?
                <div className="justify-content-center cardHome d-flex">
                    <div className="bg-white bg-shadow4 p-1 d-flex justify-content-start coljgm" >
                        <Link className={`${elemJgm?.clase} linkhome`} to={`ministerios/${elemJgm?.n1}`}>
                          <div className="text-left imgFlexHome">
                            <img 
                            fluid src={`${pathImgPerfil}/${nombreImg}`}/>                            
                          </div> 
                          <div className="flex-wrap card-body text-left px-0 py-0" >
                                <h3 className={`${estiloH3} jurbox bg-oscuro py-3`}>
                                    {elemJgm?.unidad}
                                </h3>
                                <h4 className="jurbox ft-lt-black px-3">
                                    {elemJgm?.autoridad}
                                </h4>
                          </div>
                        </Link>
                    </div> 
                </div> 
                :
                <></> 
              } 
        </div>
  )
}

export default Jgm