import {Row} from 'react-bootstrap'



function Referencias(props){

    const arrayRefs=['Administración Central', 'Organismo Desconcentrado', 'Organismo Descentralizado', 'Sociedad del Estado/Otros Entes']

    return(
        <div id="referencias" >
       
         
         <Row className="refListItems mx-0 my-4 justify-content-between align-items-center">  
         <h4 className="mb-2">Referencias</h4>
         <div className="itemsRefEscalar">
             {arrayRefs.map((li,index)=>{
               return(
                 <div key={index} >
                  <div className="d-flex align-items-center mr-4">
                    <div className={`mr-2 item${index}`}></div>
                   <div className="refContent">{li}</div>
                  </div>
                 </div>
               )
             })}
          </div>   
         </Row>
       </div>
    )
 	
}

export default Referencias