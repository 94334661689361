import { useEffect, useContext } from "react";
import axios from "axios";
import {headTitulo, headVolanta} from '../../contenido/normativas'
import {useHistory} from 'react-router-dom'
import {Container, Row, Spinner} from 'react-bootstrap'
import Context from '../context';
import {pathBackNorma} from '../paths'
import '../../css/styles.css'
import Header from '../../common/header'
import {db} from '../../../configuracion/environment'
import NormativaPanel from './normativaPanel'
import NormativaDatos from './normativaDatos'
import {sinResultados} from "../../contenido/normativas"


function NormativasEstructura(props) {
  //Devuelve la página de Normativas, filtradas por la jurisdicción que se seleccionó en el panel de ministerios o del escalar.
  

  const min=props.match.params.nUno
  const history=useHistory();

  function volver(){
    
    if(context.TN!=='Jur'&&context.TN!=='JurDes'){

      context.resetValues();
      context.tipoNormativa(context.TN)
    }
    else{
      sessionStorage.removeItem('search')
      sessionStorage.removeItem('pathNormativa')
      sessionStorage.removeItem('fdesde')
      sessionStorage.removeItem('fhasta')
      context.setDesde(null)
      context.setHasta(null)
      context.setearTextBA('')      
      sessionStorage.removeItem('newParam')
      context.setearNewParam([])
      
      if(context.TN=='Jur'){
        context.fetchNormativas(pathBackNorma,`db=${db}&id=8&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`,sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null )
      }     
    }   
  }
  

 useEffect(()=>{
    if(context.dataError==0){
      history.push('/database_error')
    }
    context.setFooterPath(pathBackNorma)
    context.setearRadio('rb-comp')                  
    sessionStorage.setItem('radio', 'rb-comp')
    window.scrollTo(0,0)
    context.setearLoading(true) 
    context.TNchange('Jur')
    const fetchTitulo = () => {      
        return(
          axios.post(pathBackNorma,`db=${db}&id=6&tl=e&n1=${min}`) 
              .then(res=>{
                if(res.data.metadatos[0].connect==0){
                  history.push('/database_error')
                  }
                else{              
                  context.setearBreadCrumb([{label:'Home',url:"/"},
                  {label:'Normativas' , url:'/normativas'},{label: res.data?.datos[0]?.unidad,url:`/normativas/estructura/${min}`} ])
                }
              })        
              .catch(error=>{
                 console.log('Error', error)
              })
          )
              };//Trae solo nombre de la jurisdicción pedida en la URL.
            sessionStorage.setItem('jurNor', min)
            sessionStorage.setItem('TN', 'Jur')
            context.fetchNormativas(pathBackNorma,`db=${db}&id=8&n1=${min}${context.desde?'&fdesde='+context.desde:''}${context.hasta ?'&fhasta='+context.hasta:''}`, min)
          fetchTitulo()
  
 },[])


 
  const context=useContext(Context);

  return (
  <>
  <Header h1={headTitulo} num={20} txt={headVolanta}/>
  <Container fluid id="container-normativas">  
    <NormativaPanel des={'des'} />
    {context.Datos ?
      <>
      {context.sinRes ?
         <>
          <div className="my-4 alert alert-warning">
            {sinResultados}<br/>
            {context.titleSearch}
            <div className="py-2 volver" onClick={volver}>
               Volver
            </div>
          </div>        
         </>
       :
        <>
         {context.normDatos && context.normDatos?.length!==0 ?
         <NormativaDatos jur={min}/>
         :
          <Row className="justify-content-center py-5">
            <Spinner animation="border" size="md" variant="secondary"/>
          </Row>
           }
         </>
      }
      </> 
      : 
      <>
      {sessionStorage.getItem('pathNormativa')&&
      <>
      {  context.TN!=='ba' || context.TN!=='df'||context.TN!=='de'?
        <Row className="justify-content-center py-5">
          <Spinner animation="border" size="md" variant="secondary"/>
        </Row> 
       :
       <></>}
      </>
      }
       </>
      }
         {/* Pregunta si hay datos para mostrar. Si hay, si los resultados de la busqueda son =0, muestra el cartel de Sin resultados, si son mas de 0, pregunta si el array que guarda los datos (normDatos) 
      es =undefined, si no es, renderiza <NormativaDatos/>, si es =undefined muestra "Sin resultados" */}
   
  </Container>

  </>
  )
}

export default NormativasEstructura;