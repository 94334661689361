import Context from './front/components/context';
import {useState} from 'react';
import {db} from './configuracion/environment'
import axios from "axios";
import { useHistory , withRouter} from "react-router-dom";
import {pathBack,pathBack1, pathBackNorma, pathBack2, pathBackFicha, pathBack3} from './front/components/paths'
import $ from 'jquery';


function GlobalState(props){

    const [arrayNumeros, setArrayNumeros]=useState([])
    const [bajada,setBajada]=useState('')
    const [datosArray, setDatosArray]=useState([])
    const [divAlt,setDivAlt]=useState('')
    const [widthSecres, setWidthSecres]=useState('widthcero')

const history = useHistory()
const [w100, setw100]=useState(false)
const [normaFicha,setNormaFicha]=useState([])
const [acciones,setAcciones]=useState([])
const [disclaimerGraf, setDisclaimerGraf]=useState(null)
const [dataFicha, setDataFicha]=useState([])
const [initialChart, setInitialChart]=useState([])
const [cantTotalCargosGraf, setCantTotalCargosGraf]=useState([])
const [cantCargosGraf, setCantCargosGraf]=useState([])
const [footerPath,setFooterPath]=useState(pathBack)
const [porcentajeMasc, setPorcentajeMasc]=useState(null)
const [porcentajeFem, setPorcentajeFem]= useState(null)           
const [labelRadio, setLabelRadio]=useState(null)
const [tipoOrganismos, setTipoOrganismos]=useState([])

    const arrayChars=['(','[',']','¨','º','~',';',':','´','@','|','!','¡','¿','·','%','&',',','>','<','?','*','+','^','$','{','}',')','\\','/']
    const [msj, setMsj]=useState('dispnoneMsj')
    const [msj3, setMsj3]=useState('dispnoneMsj')
    const [msj4, setMsj4]=useState('dispnoneMsj')
    const [msj5, setMsj5]=useState('dispnoneMsj')
    const [varFilt, setValFilt]=useState('')
    const [dVar,setDvar]=useState('d-block')
    const [loadOrgs, setLoadOrgs]=useState('d-none')
    const [resBusqueda, setResBusqueda] = useState(null);
    const [metaDatos, setMetadatos]= useState(null)
    const [hisCSV, setHisCSV]=useState(null)
    const [dataError,setDataError]=useState(null)
    const [ufaCSV, setUfaCSV]=useState(null)
    const [datosTemas, setDatosTemas]=useState([])
    const [tituloTemas, setTituloTemas]=useState('')
    const [tituloTipo,setTituloTipo]=useState('')
    const [datosTipo, setDatosTipo]=useState([])
    const [tadmOrg, setTadmOrg]=useState(JSON.parse(sessionStorage.getItem('tadmOrg'))||[])
    const [orgAPN, setOrgAPN]=useState(null)    
    let query=`db=${db}&id=8&ob=nba`;
    const [galery, setGalery]=useState(null)
    const [metaGalery, setMetaGalery]=useState(null)
    const [series, setSeries]=useState([])
    const [portalNumeros, setPortalNumeros]=useState([])
    
    const [documentoCargado, setDocumentoCargado] = useState(false);
    
    const [disclaimer, setDisclaimer]=useState('')

    const [portalNros, setPortalNros]=useState([])
    const [titulos, setTitulos]=useState([])
    const [title,setTitle]=useState('')
    const [leyendaVista, setLeyendaVista]=useState(null)

    const [titleDoc, setTitleDoc]=useState('')
    const [organismos,setOrganismos]=useState([])
    const [filtro, setFiltro]=useState(false)
    const [e404, setE404]=useState(false)
    const [jurNor, setJurNor]=useState(sessionStorage.getItem('jurNor')||'')
    const queryLm=`db=${db}&id=8&tn=lm`
    const querySSS=`db=${db}&id=8&tn=sss`
    const query12no=`db=${db}&id=8&tn=12no`;
    const queryDes=`db=${db}&id=8&ob=nd`;
    const [breadcrumb, setBreadcrumb]=useState(sessionStorage.getItem('bread')?JSON.parse(sessionStorage.getItem('bread')):[{
        label:'Home',
        url:'/',
        nivel:0
    }])
    const [cantidadCargosGrafico,setCantidadCargosGrafico]=useState([])
    const [paramUsuperior, setParamUsuperior]=useState('')
    const [minNorm, setMinNorm]=useState(sessionStorage.getItem('minNorm')||'')
    const [busquedaOrg, setBusquedaOrg]=useState([])
    const [jurOrganismos, setJurOrganismos]=useState(sessionStorage.getItem('jurOrganismos')?JSON.parse(sessionStorage.getItem('jurOrganismos')):[])
    const [textBA,setTextBA]=useState(sessionStorage.getItem('search')? JSON.parse(sessionStorage.getItem('search'))[0]:null)
    const [textDes,setTextDes]=useState(sessionStorage.getItem('uoape')? JSON.parse(sessionStorage.getItem('uoape'))[0]:null)
    const [URLNormativas, setURLNormativas]=useState(query)
    const [tipoNorma, setTipoNorma]=useState(sessionStorage.getItem('tnorma')?JSON.parse(sessionStorage.getItem('tnorma')):null)
    const [nnorma, setNnorma]=useState(sessionStorage.getItem('nnorma')? JSON.parse(sessionStorage.getItem('nnorma'))[0]:null)
    const [tipoAdmOrg,setTipoAdmOrg]=useState('')
    const [searchParam,setSearchParam]=useState(sessionStorage.getItem('busquedaHome'))
    const [busquedaHome, setBusquedaHome]=useState(sessionStorage.getItem('busquedaHome')||'')
    const [resultadosOrgs, setResultadosOrgs]=useState(false)
    const [nUno, setNuno]=useState(sessionStorage.getItem('n1')? JSON.parse(sessionStorage.getItem('n1')):null)
    const [temaNorma, setTemaNorma]=useState(sessionStorage.getItem('tema')? JSON.parse(sessionStorage.getItem('tema')):null)
    const [dni, setDni]=useState(sessionStorage.getItem('dni')? JSON.parse(sessionStorage.getItem('dni')):null)
    const [desde, setDesde]=useState(sessionStorage.getItem('fdesde')? JSON.parse(sessionStorage.getItem('fdesde'))[0]:undefined)
    const [hasta, setHasta]=useState(sessionStorage.getItem('fhasta')? JSON.parse(sessionStorage.getItem('fhasta'))[0]:undefined) 
    const [loading, setLoading]=useState(false)
    const [listaMin, setListaMin]=useState([])
    const [listaMinHome, setListaMinHome]=useState([])
    const [jurisdiccion,setJurisdiccion]=useState([])
    const [filtrosDesig,setFiltrosDesig]=useState(false)
    const [buscadorHztal,setBuscadorHztal]=useState(false)
    const [tipoVista, setTipoVista]=useState('')
    const [titulo,setTitulo]=useState([])
    const [searchValue, setSearchValue]=useState('')
    const [cierre, setCierre]=useState(false)
    const [seccionDatos, setSeccionDatos]=useState([])

    const [Datos, setDatos]=useState(sessionStorage.getItem('TN')==='df'||sessionStorage.getItem('TN')==='' ?false: true)

    //Pregunta si TN de sessionStorage pertenece a Designaciones o a vacío (caso Busqueda avanzada), 
    //si es cierto, Datos es false, y no se renderiza <NormativaDatos/>
    const [radio, setRadio]=useState(sessionStorage.getItem("radio") || 'rb-comp')
    var path= '';
    var pathNorm='';
    const [sinRes, setSinRes]=useState(false)
    const [resultados, setResultados]=useState('')
    const [TN, setTN]=useState(sessionStorage.getItem('TN') || 'ba')
    const [normDatos, setNormDatos]=useState([])
    const [errores,setErrores]=useState(null)
    const [tipoOrganigrama, setTipoOrganigrama]=useState('Escalar')    
    const [URLbuild, setURLbuild]=useState(sessionStorage.getItem('pathNormativa')||TN==='df'?queryDes:query)
    const [newParam, setNewParam]=useState(sessionStorage.getItem('newParam')?JSON.parse(sessionStorage.getItem('newParam')):[])
    const [titleSearch, setTitleSearch]=useState('')
    const [opcion,setOpcion]=useState(radio)


    const handleNorm=(t)=>{
     
     resetValues();
      tipoNormativa(t)
      //Al cliquear un ícono de tipo de normativa, cambia la sección.
      if(TN=='ba'){
     showBuscadorHztal()
      } 
      switch(t){
              case 'ba': setearURLbuild(`${pathBack1}id=8&ob=nba`)
              const win = window.open("/normativas", "_blank");
              win.focus();
              break;           
              case 'df': setearURLbuild(`${pathBack1}id=8&ob=nd`)
              const win2 = window.open("/normativas", "_blank");
              win2.focus();
      }    
             
  }


function setearTitleDoc(t){
    setTitleDoc(t)
}


//========================================== ORGANISMOS =======================================

function changedvar(v){
    setDvar(v)
}


function setearJurOrganismos(v){
    //Establece el valor seleccionado en el filtro de jurisdicción en la vista organismos    
        if(v===undefined||v.length===0){
            setJurOrganismos([])
            sessionStorage.removeItem('jurOrganismos')           
        }
        else{
            setJurOrganismos(v)
            sessionStorage.setItem('jurOrganismos', JSON.stringify(v)) 
        }    
    }
    
    function resetBusquedaOrg(){
        setBusquedaOrg([])
    }

function getBusqueda(busq){
    axios
    .post(pathBack2,`db=${db}&id=16&search=${busq}`)//Pide a la API los resultados de la búsqueda.
    .then((res) =>{      
      setMetadatos(res.data.metadatos[0])
      setResBusqueda(res.data)
            
    } )
    .catch(error=>{
        console.log('Error:', error)
     }) 
}


const getOrganismos=(p)=>{
    //Devuelve lista de todos los organismos en orden alfabético. Se llama desde organismos.js
     return(
      axios.post(p=='panel'? pathBack2: pathBack1,`db=${db}&id=5&ord=al`)
     .then(res=>{
        if(res.data.metadatos && res.data.metadatos[0]?.connect==0){
            setDataError(0)
            history.push('/database_error')
         }
         else{
            setOrganismos(res.data?.datos) 
         }
     })
       .catch(error=>{
        console.log('Error:', error)
     })   
     )
}

function setearFiltro(b){
    setFiltro(b)
}



//===============================================================================================




// ======================================== ESCALAR ===============================================


const setearRadio=(val)=>{
    setRadio(val)
    setOpcion(val)
}

const changeList=(n1, option, load)=>{ 
    //Devuelve los resultados de la API, para el n1 seleccionado, dependiendo el filtro elegido en el panel de jurisdicción(APN, AC,CFN o Org o Entes Públicos)      
    setJurisdiccion([])
    setRadio(option)    
    setLoading(load) 
    sessionStorage.setItem("radio", option)
        if(option==='as'){            
            // path=`db=${db}&id=6&cs=uno&tl=c&n1=${n1}`;    
            path=`db=${db}&id=6&tl=c&n1=${n1}`;
            setTipoVista('Autoridades Superiores APN')
            fetchJurisdiccionData(pathBack2,path)
         } 
        else if( option==='rb-comp'){
            // path=`db=${db}&id=6&n1=${n1}&tl=e&ta=ac&cfn=n`;
            path=`db=${db}&id=6&n1=${n1}&tl=e`;                        
            setTipoVista('Administración Central')
            fetchJurisdiccionData(pathBack2,path)
        }
        else if(option==='rb-ee'){         
            path=`db=${db}&id=6&n1=${n1}&cfn=s`;
            setTipoVista('Cargos Fuera de Nivel')
            fetchJurisdiccionData(pathBack2,path)
        }
        else if(option==='rb-oe'){            
            // path=`db=${db}&id=5&ord=al&n1=${n1}` 
            path=`db=${db}&id=6&tl=oye&n1=${n1}`  
            setTipoVista('Organismos/Entes Públicos')
            fetchJurisdiccionData(pathBack2,path)
            }
        /* (230118 - Revisión LM/AP - Se desactiva este atajo)
            else if(option==='ueet'){            
                // path=`db=${db}&id=5&ord=al&n1=${n1}` 
                path=`db=${db}&id=6&ueet=s&n1=${n1}`  
                setTipoVista('UUET')
                fetchJurisdiccionData(pathBack2,path)
        }*/
      };//Trae datos de la jurisdicción pedida en la URL.




    const fetchJurisdiccionData = (path_param, body) => { 
        //Pide a la API datos de un organismo con id=6&idu={idu} para mostrar en el organigrama escalar. 
        setJurisdiccion([])
        return(
            axios.post(path_param, body)           
           .then(function(res){           
            setLabelRadio(res.data?.content?.bajada_filtro ? res.data.content.bajada_filtro : '' )            
            if(res.data?.metadatos && res.data?.metadatos[0].connect==0){
                setDataError(0)
                history.push('/database_error')
             }
             else{
                 setLoading(false)
                 setJurisdiccion(res.data.datos?res.data.datos:false)            
                 setTipoAdmOrg(res.data?.datos? res.data?.datos[0]?.tipoadministracion:'') 
             }
        }
           )      
           .catch((error)=>{
            console.log('Error:', error)
            setErrores(error.message)
           })
           )
    };//Trae datos de la jurisdicción pedida en la URL. Esta función es llamada desde el escalar de organismo.


           const changeOrganigrama=(v)=>{ 
               // Es llamada desde tipoVista.js, y cambia de la vista del organigrama escalar a organigrama gráfico de unidades organizativas y viceversa           
            setTipoOrganigrama(v)      
         }


// ================================================================================================




// ===================================== BREADCRUMB ===============================================


function setearBreadCrumb (v){
    //Establece un nuevo valor para el array del breadcrumb.
    setBreadcrumb(v)
}


//==================================================================================================
async function getPortalNumerosGraf(){
    await axios.post(pathBack2,`db=${db}&id=18`)
    .then(res=>{     
        
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
            }
        else{         
            setDisclaimerGraf(res.data?.content[0]?.grafico_disclaimer)
        }
    }
        )
    .catch(error=>{
        console.log(error)
    })
}


function breadCrumb2(urls){
    setBreadcrumb(urls)    
}

// ========================================= NORMATIVAS ===========================================

function fetchTituloTipo(t){
    return(
    
    axios.post(pathBack,`db=${db}&lista=${t}`)      
    .then(function(res) {
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            setDataError(0)
            history.push('/database_error')
        }
        else{
             setDatosTipo(res.data?.datos)
             setTituloTipo(res.data?.metadatos[0]?.titulo) 
        }
    }
    )
    .catch(error=>{
      console.log(error)
    })    
    )
  }


  function fetchTitulo(t){
      
        return(
        
        axios.post(pathBack,`db=${db}&lista=${t}`)
        .then(function(res) {
            if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
                setDataError(0)
                history.push('/database_error')
             }
             else{
            setDatosTemas(res.data?.datos)            
            setTituloTemas(res.data?.metadatos[0]?.titulo)
            }
        }
        ) 
        .catch(error=>{
          console.log(error)
        }) 
        )
      }


const setearURLbuild=(url)=>{
    setURLbuild(url)
}


const TNchange=(tn)=>{
    setTN(tn)
    sessionStorage.setItem('TN', tn)
}
const setearTN=()=>{
    setTN('ba')
    sessionStorage.setItem('TN','ba')
  
}

const resetMinJurNor=()=>{
    setJurNor('')
    setMinNorm('')
    sessionStorage.removeItem('jurNor')  
    sessionStorage.removeItem('minNorm')
}

function setearNewParam(valor){
    setNewParam(valor)
}//Asigna valor al array newParam.

const fetchNormativas=async (path,pathNorm_param, xJuri)=>{     
    //Hace pedido a la API de normativas, variando la URL dependiendo el valor del argumento pathNorm. El argumento xJuri tiene valor y es utilizado cuando se llega a Normativas desde el atajo del panel de ministerio.js o de ministerios.js. y determina el título que va en el header.
    
    setTitulo([])
    showLoader();
    setSinRes(false)//Variable que determina si aparece el warning de 'Sin resultados'
    setNormDatos([]);
    sessionStorage.setItem('pathNormativa', pathNorm_param)    
    
       
        await axios.post(path, pathNorm_param)
        .then(function(res){ 
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            setDataError(0)
           
                history.push('/database_error')
                                     
        }
        else{
            if(res.data?.datos) {
               
                setNormDatos(res.data.datos)
            }                                
            setearDatos()
            setCierre(false)
            if(xJuri!==null && xJuri!==undefined){
                if(res.data?.metadatos[0]?.busqueda?.datosQ===0){
                    setSinRes(true)                   
                    hideLoader();
                }
                else{ 
                    setSinRes(false) 
                     if(res.data?.metadatos[0]?.seccion==="Designaciones"){                    
                         setTitulo(`${res.data?.datos[0]?.jurisdiccion}-Tema: Designaciones`)
                     }else{
                         setTitulo(`${res.data?.datos[0]?.jurisdiccion}-Tema: Normativas por Jurisdicción`)
                     }
                }
            }else{
                if(res.data?.metadatos[0]?.busqueda?.datosQ===0){
                    setSinRes(true)                
                    hideLoader();
                }
                else{
                    setSinRes(false)
                }
                if(res.data?.metadatos[0]){
                    setTitulo(res.data?.metadatos[0]?.seccion) 
                }
                 
            }

            setResultados(res.data?.metadatos[0]?.busqueda?.datosQ)
            
            setTitleSearch(res.data?.metadatos[0]?.busqueda?.title!==null?res.data?.metadatos[0]?.busqueda?.title?.slice(0,res.data?.metadatos[0]?.busqueda?.title?.length-1):'')
                                
            }
        }
            )
        .catch(error=>{
            console.log(error)
        }
    )
    
}

const resetOrg=()=>{
    sessionStorage.removeItem('tadmOrg')
    sessionStorage.removeItem('jurOrganismos')
    setJurOrganismos([])
    setTadmOrg([])    
    setearSearchValue('')
}

const resetValues=()=>{
    
    setInitialChart([])
    if(TN==='ba'){
      setURLNormativas(query)    
    }
    else if(TN==='df'){
        setURLNormativas(queryDes) 
    }  
    sessionStorage.removeItem('n1')
    sessionStorage.removeItem('tnorma')
    sessionStorage.removeItem('tema')
    sessionStorage.removeItem('pathNormativa')
    sessionStorage.removeItem('fdesde')
    sessionStorage.removeItem('fhasta')
    sessionStorage.removeItem('search')
    sessionStorage.removeItem('nnorma')    
    sessionStorage.removeItem('newParam')
    sessionStorage.removeItem('uoape')
    sessionStorage.removeItem('dni')   
    setNormDatos([])
    setURLbuild('')
    setNewParam([])
    setTextBA('')
    setTextDes('')    
    setDni('')
    setTitleSearch('')
    if(TN==='ba'){
        setURLbuild(query)
    }
    else if(TN==='df'){        
        setURLbuild(queryDes)
    }
    else if(TN==='Jur'){        
        setURLbuild(`db=${db}&id=8&n1=${jurNor}`)
    }
    else if(TN==='JurDes'){        
        setURLbuild(`db=${db}&id=8&ob=nd&n1=${jurNor}`)
    }
    setDesde(null)
    setHasta(null)
    setTipoNorma('')
    setNnorma('')
    setNuno('')
    setTemaNorma('')
    setTitulo('')
    setResultados('')  
    setDataFicha([]) 
    setw100(false)
}//Vuelve a 0 los valores de todos los parámetros de búsqueda avanzada.

const resetNormativas=()=>{
      
    setTN('ba')
}


const tipoNormativa=(tipo)=>{ // Función que pide datos de normativas a la API según la vista de Normativas elegida entre los botones del panel.
    
    //Lleva los valores de los <select> a el primer <option>
    var select = $('#n1');
    select.val(select.children('option:first').val());
    var select2 = $('#t');
    select2.val(select2.children('option:first').val());
    var select3 = $('#tn');
    select3.val(select3.children('option:first').val());

    window.scrollTo(0,0)

    if(tipo==='ba'){
        resetMinJurNor();
        setLoading(false)
        setTN('ba')
        sessionStorage.setItem('TN', 'ba') 
        if(sessionStorage.getItem('pathNormativa')){
            fetchNormativas(pathBack1,sessionStorage.getItem('pathNormativa'),null)
            if(dataError==0){
                history.push('/database_error')
              }
        }else{
            resetValues();
            hideDatos()            
            setBuscadorHztal(true)
            setURLbuild(query)
        }  
              
        
     }
    if(tipo==='lm'){ 
        sessionStorage.removeItem('pathNormativa')
        resetMinJurNor();
        setLoading(true)       
        setTN('lm')        
        sessionStorage.setItem('TN', 'lm')
        if(sessionStorage.getItem('pathNormativa')){
            fetchNormativas(pathBack1,sessionStorage.getItem('pathNormativa'), null) 
           
        }
        else{
           resetValues();
           pathNorm=`db=${db}&id=8&tn=lm`;                      
           fetchNormativas(pathBack1,pathNorm, null) 
        }
                 
     } 
    else if(tipo==='sss'){
        resetMinJurNor();
        setLoading(true)    
        setTN('sss')        
        sessionStorage.setItem('TN', 'sss')
        if(sessionStorage.getItem('pathNormativa')){
            fetchNormativas(pathBack1,sessionStorage.getItem('pathNormativa'),null) 
        }
        else{
            resetValues();
           pathNorm=`db=${db}&id=8&tn=sss`;                      
        fetchNormativas(pathBack1,pathNorm,null) 
        }       
    }
    else if(tipo==='12no'){
        resetMinJurNor();
        setLoading(true) 
        setTN('12no')        
        sessionStorage.setItem('TN', '12no')    
        if(sessionStorage.getItem('pathNormativa')){
            fetchNormativas(pathBack1, sessionStorage.getItem('pathNormativa'),null) 
        }
        else{
            resetValues();
           pathNorm=`db=${db}&id=8&tn=12no`;                      
        fetchNormativas(pathBack1, pathNorm,null) 
        }  
    }
    else if(tipo==='df'){
        resetMinJurNor();
        setLoading(false)
        setTN('df')    
        hideDatos()
           if(sessionStorage.getItem('pathNormativa')){
            fetchNormativas(pathBack1, sessionStorage.getItem('pathNormativa'),null) 
        }else{
            resetValues();
            hideDatos()            
            setFiltrosDesig(true)
            setURLbuild(queryDes)
        }
        sessionStorage.setItem('TN', 'df')  
        }      
}


const setearBusquedaDesig=()=>{
    //Deja de mostrar datos en Normativas, es llamada desde designacionesFiltros.js cuando no hay parámetros de búsqueda. 
    setDatos(false)
}
  
const setearSearchValue=(valor)=>{
    //Establece el texto a buscar en la página organismos.js
    setSearchValue(validateCharacter(valor))
}

const hideLoader=()=>{
    setLoading(false)
}// Esta funcion es ejecutada desde normativas.js dentro del useEffect

const showLoader=()=>{
    setLoading(true)
}
const setearDatos=()=>{
    if(normDatos===undefined){
        setDatos(true)
        setSinRes(true)//Si es true sinRes, se muestra el cartel de "Sin resultados para la búsqueda"
        hideLoader();
    
} 
else{    
   setDatos(true) //Cuando este estado es true, se renderiza el componente NormativaDatos         
}
    }
  
const hideDatos=()=>{
    setDatos(false) //Oculta el componente NormativaDatos. En los casos Búsqueda avanzada y Designaciones.
}
   
       
const setearTextDes=(v)=>{
    
    //Establece el valor del texto buscado en Designaciones.
    if(arrayChars.includes(v[v.length-1])==true ){
        setMsj4('displayMsj') 
        
    } else{
        setMsj4('dispnoneMsj')
        setTextDes(validateCharacter(v)) 
        pushParam([[validateCharacter(v),''],'uoape']) 
    }  
    }

const setearDni=(v)=>{
    //Establece el valor del DNI buscado en Designaciones.
   
        setDni(v) 
     pushParam([[v.replace('.',''),''],'dni']) 
    
    
}
const resetDni=(v)=>{
    //Establece el valor del DNI a buscar en Designaciones en vacío.
    setDni(v)
    sessionStorage.removeItem('dni')
}
const setearTextBA=(v)=>{
    //Establece el valor del texto a buscar en Búsqueda Avanzada .  
    if(arrayChars.includes(v[v.length-1])==true ){
        setMsj3('displayMsj') 
    }
    else{
        setMsj3('dispnoneMsj')
        setTextBA(validateCharacter(v))  
        pushParam([[validateCharacter(v),''],'search']) 
    }
      
}

const resetTextBA=(v)=>{
    //Establece el valor del texto a buscar en Búsqueda Avanzada en vacío.
    setTextBA(v)
    sessionStorage.removeItem('search')
}
const resetTextDes=(v)=>{
    //Establece el valor del texto buscado en Designaciones en vacío.
    setTextDes(v)
    sessionStorage.removeItem('uoape')
}
const setearNnorma=(v)=>{
    //Establece el valor del N° de norma buscado en Designaciones.
    setNnorma(validateCharacter(v))  
    pushParam([[validateCharacter(v),''], 'nnorma']) 
}
const resetNnorma=()=>{
   // Establece el valor del N° de norma buscado en Designaciones en vacío.
    setNnorma('')
    sessionStorage.removeItem('nnorma')
}

const setearTipoNorma=()=>{
    //Establece el valor del Tipo de Norma a buscar en Búsqueda Avanzada.
    setTipoNorma(null)
    sessionStorage.removeItem('tnorma')
}

const setearNuno=()=>{ 
    //Establece el valor del n1 a buscar en Búsqueda Avanzada.
    setNuno(null)
    sessionStorage.removeItem('n1')  
}

const setearTemaNorma=()=>{ 
    //Establece el valor de Tema a buscar en Búsqueda Avanzada.
    setTemaNorma(null) 
  sessionStorage.removeItem('tema') 
}
const setearDesde=(d)=>{
    setDesde(d.slice(0,10))
    pushParam([[d,''],'fdesde'])
}//Cambia el estado del parámetro Fecha Desde y lo agrega al array de parámetros.

const setearHasta=(h)=>{
    setHasta(h.slice(0,10))    
    pushParam([[h,''],'fhasta']) 
}//Cambia el estado del parámetro Fecha Hasta y lo agrega al array de parámetros.

const resetHasta=()=>{
    //Cambia el valor de Hasta a vacío.
    setHasta('')
    sessionStorage.removeItem('fhasta')
}
const resetDesde=()=>{
    //Cambia el valor de Desde a vacío.
    setDesde('')
    sessionStorage.removeItem('fdesde')
}

const showBuscadorHztal=()=>{
    //Cambia el valor del estado buscadorHztal a true, para que aparezcan en pantalla los filtros de búsqueda avanzada en formato horizontal(vista de llegada a la sección Búsqueda avanzada) 
    setBuscadorHztal(true)
    setDatos(false)
}
const showFiltrosDesig=()=>{
      //Cambia el valor del estado filtrosDesig a true, para que aparezcan en pantalla los filtros de búsqueda en formato horizontal(vista de llegada a la sección Designaciones) 
    setFiltrosDesig(true)
    setDatos(false)
}


async function pushParam(parametro,cierreTag){ 

    const repetido = (element) => element.includes(parametro[1]);//Expresion "el parametro está incluido en el elemento". Devuelve true o false.
    
    if(newParam?.findIndex(repetido)!==-1){        
      newParam.splice(newParam.findIndex(repetido),1)     
    }
    //newParam.findIndex(repetido) devuelve el indice donde está repetido el parametro.
    //newParam.splice(newParam.findIndex(repetido),1) borra del array el elemento con dicho indice.
    if(cierreTag){        
        //Se cumple cuando la funcion es invocada desde un cierre de etiqueta       
        const arrayStorage=JSON.parse(sessionStorage.getItem('newParam'))
        if(arrayStorage?.findIndex(repetido)!==-1){        
            arrayStorage.splice(arrayStorage.findIndex(repetido),1)           
        }    
        if(arrayStorage!==undefined&&arrayStorage?.length!==0){
            fetchNormativas(TN==='JurDes'||TN==='Jur'?pathBackNorma:pathBack1, concatParam(arrayStorage), sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null)
            window.scrollTo(0,0)
        }
        else{
            //El array de parámetros de búsqueda está vacío, la pantalla que debe mostrar a continuación tiene que ser la página de aterrizaje que corresponde al tipo de normativa.
            if(TN==="ba"){
              showBuscadorHztal() 
              window.scrollTo(0,0) 
            }
            else if(TN==="df"|| TN==="de"){
                showFiltrosDesig()
                window.scrollTo(0,0)
            }
            else if(TN==='Jur'){
                //Reconstruye la URL de pedido a la API de Normativas por jurisdicción. Y vacía los parámetros de búsqueda.
                fetchNormativas(pathBackNorma,`db=${db}&id=8&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`, sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null)
                setTextBA('')
                sessionStorage.setItem('pathNormativa',`db=${db}&id=8&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`)
                sessionStorage.removeItem('fdesde')
                sessionStorage.removeItem('fhasta')
                sessionStorage.removeItem('search')
            }
            else if(TN==='JurDes'){
                //Reconstruye la URL de pedido a la API de Designaciones por jurisdicción. Y vacía los parámetros de búsqueda.               
                fetchNormativas(pathBackNorma,`db=${db}&id=8&ob=nd&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`, sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null)
                setTextBA('')
                sessionStorage.setItem('pathNormativa',`db=${db}&id=8&ob=nd&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`)
                sessionStorage.removeItem('fdesde')
                sessionStorage.removeItem('fhasta')
                sessionStorage.removeItem('search')
            }
            else{
                //El tipo de normativa es 1y2 Nivel Operativo, Secres y Subses o Ley de Ministerios.
                resetValues();
                tipoNormativa(TN)
            }
            if(TN!=='Jur'&&TN!=='JurDes'){
                resetValues(); 
            }
        }
        setNewParam(arrayStorage)
        sessionStorage.setItem('newParam', JSON.stringify(arrayStorage))        
    }
    else if(!cierreTag){        
        if(parametro && parametro[0][0]!==''){                    
          setNewParam(newParam.concat([parametro]))//Actualiza el array de parametros sumando el que generó el onChange.  
        }
        else{            
            setNewParam(newParam)
            concatParam(newParam)
        }        
    }   
 }

 function concatParam(param){//Itera el array de parámetros concatenando el parametro y su valor al string que será la URL de solicitud a la API. 
    let u=''
    switch(TN){
        case 'ba':
        u=`db=${db}&id=8&ob=nba`  
        break;     
    case 'df':
        u=`db=${db}&id=8&ob=nd`
        break ;
    case 'lm':
        u=queryLm
        break;
    case '12no':
        u=query12no;
        break;
    case 'sss':
        u=querySSS;
        break;
    
    case 'Jur':        
        u=`db=${db}&id=8&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`
        break;
    case 'JurDes':        
        u=`db=${db}&id=8&ob=nd&n1=${sessionStorage.getItem('jurNor')?sessionStorage.getItem('jurNor'):null}`
        break;    
    }
     if(param !== undefined){
      
       for(var i=0;i<param?.length;i++){                   
         u+='&'+param[i][1]+'='+param[i][0][0]         
           if(i===param.length-1){
             setURLbuild(u)
             return(u)              
           }            
         }             

         if(param?.length===0){ //Si el array de parámetros está vacío, no concatena nada a la URL. 
             setURLbuild(u)
         }

     }    
   }



function setearParametros(uN){ //Establece valores de los estados de cada parametro seleccionado para generar su etiqueta al mostrar los resultados.
    
    for (var i=0; i<uN?.length;i++){
    
        if(i===uN.length-1){
            fetchNormativas(TN==='JurDes'||TN==='Jur'?pathBackNorma:pathBack1, URLbuild)
            
        }
        switch(uN[i][1]){
            case 'tnorma': 
                setTipoNorma(uN[i][0])
                sessionStorage.setItem('tnorma', JSON.stringify(uN[i][0]));
            break;
            case 'n1':
                setNuno(uN[i][0])
                sessionStorage.setItem('n1', JSON.stringify(uN[i][0]));
            break;
            case 'tema':
                setTemaNorma(uN[i][0])
                sessionStorage.setItem('tema', JSON.stringify(uN[i][0]));
            break;
            case 'fdesde':
                sessionStorage.setItem('fdesde', JSON.stringify(uN[i][0]));
                break;
            case 'fhasta':
                sessionStorage.setItem('fhasta', JSON.stringify(uN[i][0]));
                break;
            case 'search':
                sessionStorage.setItem('search', JSON.stringify(uN[i][0]));
                break;
            case 'nnorma':
                    sessionStorage.setItem('nnorma', JSON.stringify(uN[i][0]));
                    break;
            case 'uoape':
                sessionStorage.setItem('uoape', JSON.stringify(uN[i][0]));
                break; 
            case 'dni':           
                sessionStorage.setItem('dni', JSON.stringify(uN[i][0]));
                break;     
        }
    }
    sessionStorage.setItem('newParam', JSON.stringify(uN))
}

function setearCierre(tf){
    setCierre(tf)
}

// ================================================================================================





//========================================= HOME ==================================================



function setearBusquedaHome(v){
    //Establece el valor del texto buscado en el input de home.js
    
    if(arrayChars.includes(v[v.length-1])==true ){
        setMsj('displayMsj')
    }
    else{
        setMsj('dispnoneMsj')
    }
    setBusquedaHome(validateCharacter(v))
    if(v!==''){
      sessionStorage.setItem("busquedaHome", v)  
    }
    else{
      sessionStorage.removeItem("busquedaHome") 
    }
    }



function getCantRyJ(n1){   
        //Para el gráfico de pirámide. Pide a la api id=21, para la jurisdicción que indica el n1, las cantidades de unidades y sus nombres (Con agrupación).    
        try{
          var param=''
          var arregloCantsGrafico=[['',0],['',0],['',0],['',0],['',0],['',0],['',0]]
        //   axios.post(pathBack2, `db=b&id=21&n1=${n1}&metcos=uj`)
          axios.post(pathBack2, `db=b&id=25&n1=${n1}&piramide=juo`)

          .then(function(res){  
           
            var primero=[true,true,true,true,true,true,true]
            var desc=[null,null,null,null,null,null,null]
            var slash=''
            var totalCargos=0
            /*Por revisión LM/AP (230119)
                Buscar forma dinámica de generar el cambio
            */
            for(var b=0; b<res.data?.datos?.length;b++){
                totalCargos=totalCargos+res.data?.datos[b]?.QxJUO   
                let descActual=''
                descActual=res.data?.datos[b]?.abreviatura
                if(res.data?.datos[b]?.piramide>=5){
                   descActual=res.data?.datos[b]?.leyenda 
                }                 
                switch(res.data?.datos[b]?.piramide){
                    case 1:
                        if(primero[0]==true){ 
                            slash=''                    
                            primero[0]=false
                        }else{
                            slash=' / '
                        }
                         arregloCantsGrafico[0]=[arregloCantsGrafico[0][0]+slash+descActual, arregloCantsGrafico[0][1]+res.data?.datos[b]?.QxJUO] 
                    break;                
                    case 3:
                        if(primero[1]==true){
                            slash=''                         
                            primero[1]=false
                        }else{
                            slash=' / '
                        }
                        arregloCantsGrafico[1]=[arregloCantsGrafico[1][0]+slash+descActual, arregloCantsGrafico[1][1]+res.data?.datos[b]?.QxJUO]   
                    break;
                    case 4:
                        if(primero[2]==true){   
                            slash=''                      
                            primero[2]=false
                        }else{
                            
                            slash=' / '
                        }
                        arregloCantsGrafico[2]=[arregloCantsGrafico[2][0]+slash+descActual, arregloCantsGrafico[2][1]+res.data?.datos[b]?.QxJUO]
                    break;
                    case 5:                       
                        slash=' / '                        
                        arregloCantsGrafico[3]=[descActual, arregloCantsGrafico[3][1]+res.data?.datos[b]?.QxJUO]
                        
                    break;
                    case 6:
                        slash=' / '                        
                        arregloCantsGrafico[4]=[descActual, arregloCantsGrafico[4][1]+res.data?.datos[b]?.QxJUO]
                       
                    break;
                    case 7:
                        slash=' / '
                        arregloCantsGrafico[5]=[descActual, arregloCantsGrafico[5][1]+res.data?.datos[b]?.QxJUO] 
                    break;
                    case 8:
                        slash=' / '
                        arregloCantsGrafico[6]=[descActual, arregloCantsGrafico[6][1]+res.data?.datos[b]?.QxJUO] 
                    break;
    
                }
            }
            setCantTotalCargosGraf(totalCargos+1)
            setCantCargosGraf(arregloCantsGrafico)
            }   
            )
        }
        catch(error){
            console.error(error)
        }
        
    
} 



function calcPorcGenYPorcRyJ(array){
    var cantFem=0
    var cantMasc=0
    var cantSinDet=0
    var cantTotal=0
    var porcMasc=null
    var porcFem=null
  
    for(var k=0; k<array?.length;k++){       
        if(array[k]?.autoridad!==null){
             if(array[k].aut_sexo=='M'){
                cantMasc= cantMasc+1
            }
            else if(array[k].aut_sexo=='F'){
                cantFem= cantFem+1
            }
            else{
                cantSinDet= cantSinDet+1       
            }
            cantTotal=cantTotal+1
        }
    }
    porcFem=(cantFem*100)/(cantTotal-cantSinDet) 
    porcMasc=(cantMasc*100)/(cantTotal-cantSinDet)

    setPorcentajeMasc(porcMasc)
    setPorcentajeFem(porcFem)
}



const fetchMinisteriosHome=(mins, body)=>{
    //Pide a la API la lista de ministerios. Se llama desde home.js
     return(
         axios.post(mins, body)          
         .then(res =>{
             if(res.data?.metadatos[0]?.connect==0){
                setDataError(0)                
             }
             else{ 
                setListaMinHome(res.data?.datos)
                setOrgAPN(res.data?.parameters[0]?.org_apn)
                setHisCSV(res.data?.parameters[0]?.his_csv)
                setUfaCSV(res.data?.parameters[0]?.ufa_csv)  
             }
             setDivAlt('')
         }          
         )
         .catch((error)=>{ 
                 console.log('Error', error)
                 setErrores(error.message)
             })
             )  
     }



function setearParamSearch(param){
    //Establece el valor de searchParam, que es el estado que toma el valor del item busquedaHome (valor del input de home.js) del almacenamiento del navegador.
    setSearchParam(param)
}


//============================================================================================




//=================================== COMUNES ================================================


const fetchMinisterios=(mins,body)=>{
    //Pide a la API la lista de ministerios. Se llama desde ministerios.js
            setDataError(null)
            axios.post(mins, body)
            .then(res =>{                
                if(res.data?.metadatos && res.data?.metadatos[0].connect==0){
                    setDataError(0)
                    history.push('/database_error')
                    }
                else{  
                    setListaMin(res.data?.datos)                    
                 }
               }
             )  
            .catch((error)=>{
                    console.log('Error: ', error)
                    setErrores(error.message)
                })
        }

function setearLoading(l){
    setLoading(l)
}

function setearE404(v){
    setE404(v)
}

 
const contResultadosOrgs=()=>{
    setResultadosOrgs(true)
}
const resetResultadosOrgs=()=>{
    
    setResultadosOrgs(false)
}


function setearUsperior(v){
    setParamUsuperior(v)
}


function validateCharacter(string){    
    return string.replace(/([¨º~;:´@|!¡¿·%&,><?*+^$[\]\\(){}])/g, '')    
}

//============================================================================================

function setValidarFilt(s){
    
    setValFilt(validateCharacter(s))
}


function verError(){
          
    $('.hideMe').hide();
    $('.showErr').show();
  
}
  


  function getDataFicha(paramIDU, paramSearch){

    if(paramIDU!==undefined && paramIDU!==null && paramIDU!==''){      
       axios
      .post(paramSearch? pathBack3 : pathBackFicha, `db=${db}&id=17&idu=${paramIDU}`)
      .then((res) => {
        if(dataError!==0 && res.data?.datos===undefined){
          history.push('/404')
        } else{                
        setDataFicha(res.data?.datos)   
        }                  
      }
      )
      .catch(error=>{
        console.log(error)
      })  
      }
  } 

async function getNumeros(){
    await axios.post(pathBack,`db=${db}&id=15`)
    .then(res=>{  
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
            }
        else{          
        setArrayNumeros(res.data?.datos)            
        setBajada(res.data?.metadatos ? res.data?.metadatos[0]?.bajada?.split('\r\n') : '')
    }
}
    )
    .catch(error=>{
        console.log(error)
    })
}


function getDatos(){
    axios.post(pathBack,`db=${db}&id=10&desta=s`)//Solicita a la API los datos que se muestran en las tarjetas de Datos de Home.
    .then(res=>{
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
            }
        else{
        setDatosArray(res.data?.datos)
        }
    })
    .catch(error=>{
        console.log(error)
    })
}



async function getPortalNumeros(){
    await axios.post(pathBack,`db=${db}&id=18`)
    .then(res=>{     
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
        }
        else{ 
        setTitle(res.data?.metadatos[0]?.titulo)            
        setPortalNros([res.data?.Unidades?.Q, res.data?.Cargos?.Q, res.data?.Normativas?.Q])
        setTitulos([res.data?.Unidades?.etiqueta,res.data?.Cargos?.etiqueta,res.data?.Normativas?.etiqueta])
        setDisclaimer(res.data?.content[0]?.ufa_disclaimer)
        }
    }
    )
    .catch(error=>{
        console.log(error)
    })
}


function getGaleriaDatos(id){      
    axios.post(pathBack2,`db=${db}&id=10&idgalery=${id}`)
    .then(res=>{
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
        }
        else{
            setGalery(res.data.datos? res.data?.datos[0]:null) 
            setMetaGalery(res.data?.metadatos[0])
            setSeries(res.data?.series)
        }
    })
  .catch(error=>{
      console.log(error)
  })
}


    
function getSeccionDatos(){
    axios.post(pathBack1,`db=${db}&id=10&publi=s`)
    .then(res=>{         
        if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
            history.push('/database_error')
        }
        else{          
        setSeccionDatos(res.data?.datos)
        if(res.data?.metadatos && res.data?.metadatos[0]?.leyendaVista){
           setLeyendaVista(res.data?.metadatos[0]?.leyendaVista)
        }
        }
    })
    .catch(error=>{
        console.log(error)
    })
}


function fetchTa(){
    axios.post(pathBack,`db=${db}&lista=tta&ta=orgespn`)
    .then(res => 
     {
       if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
         history.push('/database_error')
         }
     else{
       setTipoOrganismos(res.data?.datos)
     }
     }) 
     .catch(error=>{
       console.log(error)
     })
  }//Pide a la api los valores a incluir en el input <select> de Tipo de Organismo.


return(
<Context.Provider value={{
     getSeccionDatos:getSeccionDatos,
      getGaleriaDatos:getGaleriaDatos,
      dataFicha:dataFicha,
      tipoOrganismos:tipoOrganismos,
      getPortalNumeros:getPortalNumeros,
      getDataFicha:getDataFicha,
      fetchJurisdiccionData:fetchJurisdiccionData,
      jurisdiccion:jurisdiccion,         
      changeList:changeList,      
      fetchTa:fetchTa,  
      setearBusquedaHome:setearBusquedaHome,
      fetchMinisterios:fetchMinisterios,      
      listaMin:listaMin,  
      msj3:msj3,
      datosArray:datosArray,
      dataError:dataError,
      setJurOrganismos:setJurOrganismos,
      setMsj3:setMsj3,
      msj4:msj4, 
      setMsj4:setMsj4,     
      listaMinHome:listaMinHome,
      radio:radio,
      setRadio:setRadio,
      dVar:dVar,
      getDatos:getDatos,
      changedvar:changedvar,
      busquedaHome:busquedaHome,
      setearLoading:setearLoading,
      resetTextBA:resetTextBA,     
      setearNnorma:setearNnorma,     
      resetNnorma:resetNnorma, 
      setMsj:setMsj,
      msj:msj,
      arrayNumeros:arrayNumeros,     
      setearParamSearch:setearParamSearch,
      setearParametros:setearParametros,
      pushParam:pushParam,    
      sinRes:sinRes,
      portalNumeros:portalNumeros,
      disclaimer:disclaimer,     
      portalNros:portalNros,
      titulos:titulos,
      title:title,
      URLbuild:URLbuild,
      leyendaVista:leyendaVista,
      setLeyendaVista: setLeyendaVista,
      newParam:newParam,
      setearE404:setearE404,
      e404: e404,
      bajada:bajada,
      getNumeros:getNumeros,
      galery: galery,
      metaGalery:metaGalery,
      series:series,
      tituloTipo:tituloTipo,
      datosTipo: datosTipo, 
      setearJurOrganismos:setearJurOrganismos,
      URLNormativas:URLNormativas,
      resetDesde:resetDesde,
      resetHasta:resetHasta,
      getOrganismos:getOrganismos,
      organismos:organismos,
      breadCrumb2:breadCrumb2,
      breadcrumb:breadcrumb,
      setDataError:setDataError,
      minNorm:minNorm,
      resetTextDes:resetTextDes,    
      showLoader:showLoader,
      busquedaOrg:busquedaOrg,
      resetResultadosOrgs:resetResultadosOrgs,
      setearDatos:setearDatos,
      loading:loading,      
      resetValues:resetValues,
      errores:errores,  
      fetchTituloTipo:fetchTituloTipo, 
      showBuscadorHztal:showBuscadorHztal,
      Datos:Datos,
      resultadosOrgs:resultadosOrgs,
      setearDni:setearDni,
      concatParam:concatParam,
      setearDesde:setearDesde,
      setearHasta:setearHasta,
      desde:desde,
      hasta:hasta,
      setSeries:setSeries,
      dni:dni,
      setearBreadCrumb:setearBreadCrumb,
      resetDni:resetDni,
      hideLoader:hideLoader,
      setearBusquedaDesig:setearBusquedaDesig,
      hideDatos:hideDatos,
      setearRadio:setearRadio,  
      changeOrganigrama:changeOrganigrama, 
      setTipoOrganigrama:setTipoOrganigrama,    
      tipoVista:tipoVista,
      setearTN:setearTN,    
      TN:TN,
      resBusqueda:resBusqueda,
      getBusqueda:getBusqueda,
      divAlt:divAlt,
      setDivAlt: setDivAlt,
      metaDatos:metaDatos,
      textDes:textDes,
      textBA:textBA,
      nnorma:nnorma,
      setGalery:setGalery,
      setMetaGalery:setMetaGalery,
      nUno:nUno,
      handleNorm:handleNorm,
      searchParam:searchParam,
      contResultadosOrgs:contResultadosOrgs,
      searchValue:searchValue,
      setearSearchValue:setearSearchValue,
      jurOrganismos:jurOrganismos,    
      tipoNorma:tipoNorma,
      temaNorma:temaNorma,       
      verError:verError,  
      buscadorHztal:buscadorHztal,
      setearTextBA:setearTextBA,      
      setearTextDes:setearTextDes,
      setearTipoNorma:setearTipoNorma,
      setearNuno:setearNuno,
      setearTitleDoc: setearTitleDoc,
      titleDoc:titleDoc,      
      orgAPN:orgAPN,
      varFilt:varFilt,
      setValidarFilt: setValidarFilt,
      hisCSV:hisCSV,
      setearTemaNorma:setearTemaNorma,
      titleSearch:titleSearch,        
      fetchNormativas:fetchNormativas,  
      resultados:resultados, 
      cierre:cierre,
      fetchMinisteriosHome:fetchMinisteriosHome,
      jurNor:jurNor,
      setearURLbuild:setearURLbuild,
      setearCierre:setearCierre, 
      normDatos:normDatos,
      tipoNormativa:tipoNormativa,
      setearFiltro:setearFiltro,
      filtro:filtro,
      tipoAdmOrg:tipoAdmOrg,
      titulo:titulo,
      fetchTitulo:fetchTitulo,
      setearUsperior:setearUsperior,
      paramUsuperior:paramUsuperior,
      validateCharacter:validateCharacter,
      setearNewParam:setearNewParam,
      resetBusquedaOrg:resetBusquedaOrg,
      filtrosDesig:filtrosDesig,      
      TNchange:TNchange,
      setDataFicha:setDataFicha,
      datosTemas:datosTemas,
      tituloTemas:tituloTemas,
      ufaCSV:ufaCSV,
      setDesde:setDesde,
      setHasta:setHasta,
      footerPath:footerPath,
      tadmOrg:tadmOrg,
      setTadmOrg:setTadmOrg,
      setResBusqueda:setResBusqueda,
      setFooterPath:setFooterPath,
      resetMinJurNor:resetMinJurNor,
      resetNormativas:resetNormativas,
      resetOrg:resetOrg,
      tipoOrganigrama:tipoOrganigrama, 
      calcPorcGenYPorcRyJ:calcPorcGenYPorcRyJ,
      porcentajeMasc:porcentajeMasc,
      porcentajeFem: porcentajeFem,
      getCantRyJ:getCantRyJ,
      cantCargosGraf:cantCargosGraf,
      cantTotalCargosGraf:cantTotalCargosGraf,
      initialChart:initialChart,
      setInitialChart:setInitialChart,
      setCantCargosGraf:setCantCargosGraf,
      setValFilt:setValFilt,
      opcion:opcion,
      setOpcion: setOpcion,
      disclaimerGraf:disclaimerGraf,
      getPortalNumerosGraf:getPortalNumerosGraf,
      setLabelRadio:setLabelRadio,
      labelRadio:labelRadio,
      normaFicha:normaFicha,
      setNormaFicha:setNormaFicha,
      acciones:acciones,
      setAcciones:setAcciones,
      w100:w100,
      setw100: setw100,
      seccionDatos:seccionDatos,
      documentoCargado:documentoCargado,
      setDocumentoCargado: setDocumentoCargado,
      widthSecres:widthSecres,
      setWidthSecres: setWidthSecres
      }}>
 {props.children}
</Context.Provider>
)

}

export default withRouter(GlobalState);