import React from 'react'
import Context from '../../components/context';
import {useContext, useState} from 'react'
import {useHistory} from "react-router-dom"
import Busqueda from './busqueda'

const BusquedaHome = () => {


    const context=useContext(Context);
    const history=useHistory()

  return (
    <div>
        <div className="py-5 title">
            <div id="background">
                <form id="formBusquedaHome" onSubmit={(e)=>{
                    //Formulario de búsqueda de Home.
                    e.preventDefault()
                    if(context.busquedaHome!==''){
                        //Si el valor pasado por el input es distinto de vacío, dirije hacia la página de los resultados.
                        history.push(`/busqueda/${context.busquedaHome?.replace(/\s+/g, '-')}`)                           
                    }                                            
                    }
                    }>
                   <Busqueda />
                </form>    
            </div>
        </div>
    </div>
  )
}

export default BusquedaHome