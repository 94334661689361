import {path, path2, path1, db,pathFicha,  path3} from '../../configuracion/environment'

export const pathBack=`${path}api/datos.php?`; 
export const pathBack3=`${path3}api/datos.php?`; 
export const pathBackFicha=`${pathFicha}api/datos.php?`;
export const pathBack2=`${path2}api/datos.php?`;
export const pathBack1=`${path1}api/datos.php?`;
export const pathBackNorma=`${path3}api/datos.php?`;
export const pathPDF=`${path2}includes/estructura_pdf.php?r=1`;
export const pathPDFO=`${path2}includes/estructura_pdf.php?r=21&idu=`
export const pathOrganigrama=`${process.env.PUBLIC_URL}/back/organigramas/`;
export const pathImgPerfil=`${process.env.REACT_APP_URL_1}imagenes/perfil` // 
export const pathImg=`${process.env.PUBLIC_URL}/back/imagenes` ;//back
export const pathIconos=`${process.env.PUBLIC_URL}/back/iconos`;
export const pathDatos=`${path2}datos`;
export const pathCsv=`${process.env.PUBLIC_URL}/back/seriesdetiempo/dataset`;

