import $ from 'jquery';
import { toast } from 'react-toastify';
//script: estructura_oescalar.php en la línea 106 y en organismos_oescalar.php en la linea 73

//Función para reemplazar las letras ñ por n. 
function rempN(s){
	s = s.replace(/ñ/gi,"n");
	return s;
}


function validateCharacter(string){
    return string.replace(/([¨º~;:´@|!¡¿·%&,><?*+^$[\]\\(){}])/g, '')    
}


//Función principal, que compara el texto ingresado en el input con los textos contenidos en elementos <li> dentro de <div id='estru-res'>.
export function buscarUnidadGrafico(){	
	$('.found').removeClass('found');	//Quita la clase 'found' (que agrega resaltado a los elementos que coinciden con la búsqueda) de los resultados de la búsqueda anterior.
	var filtro= document.getElementById('filtrar');	
	var valorBuscado=rempN(filtro.value);	
	var unidades= document.getElementsByClassName("box"); 
	if(valorBuscado!==''){
     


	for(let row of unidades){

		var contenidoUnidad =rempN($(row).text())
    	if(accent_fold(contenidoUnidad).toLowerCase().indexOf(accent_fold(valorBuscado).toLowerCase())!== -1){
    		row.classList.add('found');			
    	}

    }
	var coincidencias=document.getElementsByClassName('found');
	
	if(coincidencias.length===0){	
		toast.error('No hay coincidencias',{
			position: "bottom-center",
			autoClose: 5000,    
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			})	
		
		
	}else{
		$('html, body').animate({
			scrollTop: $(".found").offset().top-100
			}, 1000);
			//Scrollea automaticamente hasta el primer elemento que coincide con la búsqueda.
	}
    return false;
}
}

var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','ü':'u', 'Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
function accent_fold (s) {
  if (!s) { return ''; }
  var ret = '';
  for (var i = 0; i < s.length; i++) {
	ret += accent_map[s.charAt(i)] || s.charAt(i);
  }
  return ret;
}//Función que reemplaza letras con acento por letras sin acento.


// ESTILOS EN CSS 
// .found{ background-color: #0072bb !important; color:#e5e5e5 !important;}
// .found .autn1{color:#e5e5e5 !important;}