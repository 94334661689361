

import { useEffect, useState, useContext} from "react";
import Context from '../context';

function AccionesFichaUnidad(props){

    const colorFont=props.colorFont
    const bgColor=props.bgColor
    const context=useContext(Context);

    return(
        <>
            {context.acciones?.length!==0 && context.acciones[0]!=='' &&
                        <div className="divAcciones mx-3 pt-2 pb-3">
                            <h2 className={`${colorFont} bgFicha_${bgColor} rounded p-3 text-center ft-bold`}>
                            {context.acciones[0]?.replace(':','')}
                            </h2>
                            <div className={`borderFicha_${bgColor} rounded ft-lt-black`}>
                            {context.acciones?.map((accion,i)=>{
                                return(
                                <>
                                    { i > 0 &&
                                    <div className="accion">
                                        {accion}<br/>
                                    </div>
                                    }
                                </>
                                )
                            })}
                            </div>
                        </div>
            }
        </>
    )
       
}

export default AccionesFichaUnidad;