import {Carousel} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {pathDatos} from '../paths'
import $ from 'jquery'



function GaleriaImagenes(props){

  const [arrayImagenes,setArrayImagenes]=useState([])
  const [index, setIndex] = useState(0);


  const pathImagenesDatos=`${pathDatos}/${props.galery?.codigo}/${props.galery?.fpublicacion.replace('-','').slice(0,6)}`
  
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


 useEffect(()=>{
  setArrayImagenes([])
    $('.carousel-control-next-icon').css('color','#333 !important')
    window.scrollTo(0,0) 
    for (let i=0;i<props.metaGalery.countImg;i++){
      setArrayImagenes(arrayImagenes=>[...arrayImagenes,`${pathImagenesDatos}/${props.metaGalery?.imagenes[i]}`])      
      //Agrega al array las imágenes que vienen por API 
    }
 
 },[pathImagenesDatos, props.metaGalery])

 useEffect(()=>{
  
 },[arrayImagenes])


  return(
            <>
              <Carousel className="py-5 carrusel" interval={null} activeIndex={index} onSelect={handleSelect}>
                {arrayImagenes?.map((img,i)=>{
                 return(
                <Carousel.Item key={i}>
                  <div className="itemDiv d-flex justify-content-center">
                <img
                  className="d-block w-100 imgItem"
                  src={`${img}`}
                  alt="First slide"
                />
                </div>
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              )
                }        
                )
                  
                }
            </Carousel>
    
         
        </>
     
  
  
  
  )
}

export default GaleriaImagenes;