import { useEffect, useState, useContext } from "react";
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import Context from '../context';
import OrganismoBox from "./organismoBox";
import {FaSortDown} from 'react-icons/fa'
import $ from 'jquery'

function ListaOrganismos(props) {
//Devuelve los filtros de búsqueda y la lista de organismos de a 20.

 const [showMore, setShowMore]=useState(21) 
 const [res,setRes]=useState([]) 
 var stringLength= ''
 const context=useContext(Context); 
 const [orgs,setOrgs]=useState([])
 const [varLength, setVarLength]=useState(showMore-1)


 useEffect(()=>{
  setShowMore(21)
  
  if(context.jurOrganismos.length===0&&props.ta.length===0&&props.busqueda===''){
    context.setearFiltro(false)
  }  
  if(context.organismos?.length!==0){
    
    filter(context.organismos, scrollBajada)
  }   
  context.setearRadio('rb-comp')   
  localStorage.setItem('radio', 'rb-comp')   
  },[context.jurOrganismos, props.ta, props.busqueda]) //Se renderiza el componente nuevamente cada vez que cambia el array jurOrganismos, que contiene el nombre de la jurisdicción en el primer elemento y el n1 en el segundo elemento. También se renderiza nuevamente cuando cambian las props ta y busqueda, que componen los filtros.


 
    var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
    function accent_fold (s) {
      if (!s) { return ''; }
      var ret = '';
      for (var i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
      }
      return ret;
    }//Función que reemplaza letras con acento por letras sin acento.


   function scrollBajada(){
     document.getElementById("bajadaResultados").scrollIntoView();
   } 

useEffect(()=>{  
  $('.containerListaOrgs').css('display', 'none') 
  if(orgs.length>=showMore){
    setVarLength(showMore-1)
  }
  else{
    setVarLength(orgs.length-1)
  }
},[orgs, showMore])

function filter(arrayOrgs, callback){
 
  //Funcion que filtra los resultados de organismos de acuerdo a los filtros aplicados.
  var array=[]
  var array2=[]
  var array3=[]

  if(context.jurOrganismos?.length!==0){
   
    array= arrayOrgs.filter(org=> org.n1===context.jurOrganismos[1])
    
    if(props.ta?.length!==0&&props.ta!==undefined){
     if(props.ta[1]=='espn'){array2=array.filter(org=>org.idtipoadministracion>3)}
     else{array2=array.filter(org=>org.idtipoadministracion==props.ta[1])}
       if(props.busqueda!==''){
        array3=array2.filter(org=>accent_fold(org.idunidad+' '+org.unidad+' '+org.autoridad+' '+org.autoridad+' '+org.reporta_a+' '+org.tipoadministracion+' '+org.nombre_corto).toLowerCase().indexOf(accent_fold(props.busqueda).toLowerCase())!== -1)
         setOrgs(array3)
         setRes(array3)
         context.setearFiltro(true)
       }else{
         setOrgs(array2)
         setRes(array2)
         context.setearFiltro(true) 
       }
      
    }
    else if(props.busqueda!==''){
       array3=array.filter(org=>accent_fold(org.idunidad+' '+org.unidad+' '+org.autoridad+' '+org.autoridad+' '+org.reporta_a+' '+org.tipoadministracion+' '+org.nombre_corto).toLowerCase().indexOf(accent_fold(props.busqueda).toLowerCase())!== -1)
        setOrgs(array3)
         setRes(array3)
         context.setearFiltro(true)      
    }
    else{
      setOrgs(array)
      setRes(array)
      context.setearFiltro(true)
    }
  }


  else if(props.ta!==null&&props.ta!==undefined&&props.ta.length!==0){   
      if(props.ta[1]=='espn'){
        array2=arrayOrgs.filter(org=>org.idtipoadministracion>3)     
      }
      else{
      array2=arrayOrgs.filter(org=>org.idtipoadministracion==props.ta[1])
     }
    if(props.busqueda!==''){      
       array3=array2.filter(org=>accent_fold(org.idunidad+' '+org.unidad+' '+org.autoridad+' '+org.autoridad+' '+org.reporta_a+' '+org.tipoadministracion+' '+org.nombre_corto).toLowerCase().indexOf(accent_fold(props.busqueda).toLowerCase())!== -1)
      setOrgs(array3)
      setRes(array3)
      context.setearFiltro(true)
    }else{
      
      setOrgs(array2)
       setRes(array2)
       context.setearFiltro(true)
    }
       
  }

  
  else if(props.busqueda!==''){
     array3=arrayOrgs.filter(org=>accent_fold(org.idunidad+' '+org.unidad+' '+org.autoridad+' '+org.autoridad+' '+org.reporta_a+' '+org.tipoadministracion+' '+org.nombre_corto).toLowerCase().indexOf(accent_fold(props.busqueda).toLowerCase())!== -1)
    setOrgs(array3)
    setRes(array3)
    context.setearFiltro(true)    
  }
  else{
    setOrgs(arrayOrgs)
  }
  
}  

      return (   
        <div id="bajadaResultados">{ (context.jurOrganismos !==undefined|| props.ta!==undefined|| props.busqueda!=='')&&context.filtro==true ?
         <Container>
         <Row  className={`p-2 mx-2  ${res.length!==0? 'bajadaFiltros ft-04':'alert-warning' } `}>
            { context.jurOrganismos?.length!==0 ?
            <Col xl={12}>
            <p><strong>Jurisdicción: </strong>{context.jurOrganismos[0]} </p> 
                             
            </Col>
          :
          <></>
          }
          {props.ta?.length!==0 ?
            <Col xl={12}>   
               <p><strong>Tipo de Administración: </strong>{props.ta[0]} </p>              
            </Col>
          :
          <></>
          }
          {(context.jurOrganismos?.length!==0)||(props.ta?.length!==0)||(props.busqueda!=='')? 
          <Col xl={12}>          
          {res.length!==0? <p><strong>Resultados:</strong> {res.length}</p> :<p>Sin resultados</p> }
          </Col>
          :
          <></>
          }
          </Row>
          </Container>
          :
          <></>
          }
        
           {orgs?.length!==0 ?
           <>
           
           <Container className={`my-5 containerListaOrgs`}>
            
            <Row className="justify-content-start mx-0" >
            {orgs?.slice(0,showMore).map((elem,indice)=>{
              if(indice==varLength){
                
                $('.containerListaOrgs').css('display', 'block') 
              }
               if((elem.unidad+elem.autoridad).length>90){
                 stringLength=2
               }
               else{
                 stringLength=1
               }
        
              return(
                <>

                <OrganismoBox
                               link={elem.idunidad}
                               unidad={elem.unidad}
                               n1={elem.n1}
                               organigrama={accent_fold(elem.nombre_corto).replace(/\s+/g, '').toLowerCase()}
                               web={elem.web}
                               jur={elem.jurisdiccion}
                               orgpdf={elem.orgpdf}
                               aut={elem.autoridad}
                               length={stringLength}
                               repo={elem.reporta_a}
                               escalar={elem.oescalar}
                               key={indice}                 
                               idTipo={elem.idtipoadministracion}
                               tipo={elem.tipoadministracion}
                               sigla={elem.nombre_corto}/>
                               
                               </>
              )
           
            }
             
            )}
          
        </Row>
          {showMore < orgs?.length ? 
                    <Row className="showmore justify-content-center mt-5" onClick={()=>setShowMore(showMore+21)}>
                      <div className="text-center">
                        <p className="mb-0"> Mostrar más</p>
                        <FaSortDown size={42}/>
                          </div>                               
                    </Row>
                     : null}
         </Container>
         </>
        :
        <>
          {context.jurOrganismos.length===0 && props.ta.length===0 && props.busqueda=='' &&
            <Row className="justify-content-center py-4">
            <Spinner animation="border" size="md" variant="secondary"/>            
          </Row>
           }
         </>
           
        }
        </div>
      )
    
}

export default ListaOrganismos;