import { useEffect, useState, useContext } from "react";
import Header from '../common/header'
import {headTitulo, headVolanta} from '../contenido/datos'
import {Container, Row, Spinner} from 'react-bootstrap'
import {pathBack, pathBack1 } from '../components/paths'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios'
import $ from 'jquery'
import Context from '../components/context';
import {db} from '../../configuracion/environment'

function Datos(props){

    const context=useContext(Context);    
    const history=useHistory();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if(context.seccionDatos.length==0){
               context.verError() 
            }          
        }, 8000);
        return () => clearTimeout(timer);
      }, []);


useEffect(()=>{
    context.setSeries(null)
    context.setMetaGalery(null)
    context.setSeries([])
    context.setResBusqueda(null)
    context.setMsj3('dispnoneMsj')
    context.setMsj4('dispnoneMsj')
    context.setearTitleDoc('Mapa - Datos')
    context.setearRadio('rb-comp') 
    sessionStorage.setItem('radio', 'rb-comp')
    context.setearE404(false)    
    context.setearBreadCrumb([{label:'Home',url:"/"},{label:'Datos', url:'/datos'}])
    if(context.seccionDatos.length===0){
         context.getSeccionDatos()
    }
   
},[])

useEffect(()=>{
    
}, [ context.metaGalery])



useEffect(()=>{

},[context.seccionDatos])

    return(
        <>
        <Header h1={headTitulo} txt={headVolanta}/>
        <Container fluid className="py-3 bg-primary datosContainer">   
        <Container className="datosContainer2">
        {context.leyendaVista &&
           <div className='alert alert-warning infCons'>{context.leyendaVista}</div>
        }
        
            {context.seccionDatos?.length!==0 ?
            <Row className="justify-content-between mx-1"> 
                {context.seccionDatos?.map((item,i)=>{
                        return(
                           <Link to={`datos/${item.id}`} className="py-4 linkDatos col-md-6 px-2" key={i}>                                     
                                <div className="bg-white bg-shadow4 tarjetaSeccionDatos ">
                                    <div className="p-3">
                                        <div className="ft-16 tituloDatos">
                                            <h3>{item?.titulo}</h3>
                                        </div>
                                        <div id="contDatos">
                                        <p className="mb-4 bajadaDatos">{item?.bajada}</p>
                                            <p>Publicador: <small>{item?.publicador}</small></p>
                                            <p>Fuente: <small>{item?.fuente}</small></p>
                                            <p>Período: <small>{item?.periodo}</small></p>
                                            <p>Unidades: <small>{item?.unidades}</small></p>
                                            <p>Frecuencia: <small>{item?.frecuencia}</small></p>
                                            <p>Fecha de publicación: <small>{item?.fpublicacion}</small></p>
                                            <p>ID: <small>{item?.id}.{item?.version}_{item?.codigo}</small></p> 
                                        </div>
                                    </div>
                                    <div className="bg-19 footerDatos">                                        
                                    </div>            
                                </div>
                            </Link>
                        )
                    })
                }
            </Row>
            :
            <Row className="loader justify-content-center py-5">
              <Spinner className="hideMe" animation="border" size="md" variant='secondary'/>
              <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
            </Row> 
            }
        </Container>
    </Container>
    </>
    )
}

export default Datos;