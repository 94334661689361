import { useEffect, useState, useContext } from "react";
import axios from "axios";
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import Jurisdicciones_Escalar from '../components/escalar/jurisdiccionesEscalar'
import Header_Jurisdiccion from '../components/escalar/headerJurisdiccion'
import Jurisdicciones_Panel from "../components/escalar/jurisdiccionesPanel";
import Tipo_Vista from "../components/escalar/tipoVista"
import Context from '../components/context';
import { pathBack2} from '../components/paths'
import '../css/styles.css'
import { useParams, useHistory} from "react-router-dom";
import Errores from '../components/errores'
import OrganigramaGrafico from "../components/escalar/organigramaGrafico";
import Referencias from '../components/referencias'
import {db} from '../../configuracion/environment'

function Ministerio(props) {
//Retorna el organigrama escalar del ministerio seleccionado; el panel con información complementaria; la recursera del tipo de vista.

const history = useHistory()
const context=useContext(Context);
const [unidad,setUnidad]=useState([])
const param=useParams()
const nUno=param.id
const [leyendaEscalar, setLeyendaEscalar]=useState(null)

const [labelRadioMin, setLabelRadioMin]=useState(null)

useEffect(()=>{
  
  if(context.labelRadio){   
     setLabelRadioMin(context.labelRadio)    
  }
  
},[context.labelRadio, context.radio])


useEffect(() => {
 
  const timer = setTimeout(() => {
      if(unidad?.length==0){
         context.verError() 
      }          
  }, 8000);

  return () => clearTimeout(timer);

}, [context.jurisdiccion]);


const fetchTitulo = (nUno) => {  

 return(
     axios.post(pathBack2,`db=${db}&id=6&tl=e&n1=${nUno}`)       
        .then(res =>{              
              if(res.data?.datos){
                if(res.data?.metadatos[0]?.connect==0){
                 history.push('/database_error')
                }
                else{
                  context.setearBreadCrumb(
                  [{label:'Home',url:"/", nivel:0},
                  {label:'Ministerios' , url:'/ministerios', nivel:1},            
                  {label:res.data?.datos[0]?.unidad,url:`/ministerios/${nUno}`, nivel:2},
                  ]) 
                  setUnidad(res.data?.datos[0])              
                  if(res.data?.content?.leyenda_escalar){
                    setLeyendaEscalar(res.data.content.leyenda_escalar)
                  }
                  //Asigna a la variable unidad (que luego pasa como prop.unidad, la unidad en el  primer lugar del array, nombre de la jurisdicción)
                  context.setearTitleDoc(`Mapa del Estado - ${res.data?.datos[0]?.unidad}`) 
                }
              }
              else{
                  history.push('/404')
              }        
          })             
        .catch(error=>{
           console.log('Error', error)
        })
            )
        };//Trae solo nombre de la jurisdicción pedida en la URL.
 



  useEffect(()=>{
    if(context.tipoVista!=='Administración Central'){
      context.setw100(true)
    }
    else{
      context.setw100(false)
    }
  },[context.tipoVista])


   useEffect( async ()=>{    
  
    context.setFooterPath(pathBack2)
    if(nUno){
      await context.changeList(nUno,sessionStorage.getItem("radio")|| 'rb-comp', true)
    }      
    
    context.resetMinJurNor(); 
    context.setearE404(false)
    sessionStorage.removeItem('TN')
    sessionStorage.removeItem('pathNormativa')   
    window.scrollTo(0,0)    
    context.setTipoOrganigrama('Escalar')
    await fetchTitulo(nUno)    
    },[context.radio]) //Cada vez que el nUno cambia o se actualiza la página, se llama la funcion changeList, 
    //que trae los datos seleccionados segun el filtro.
    //Si no existe localStorage.getItem("radio") muestra Adm. Central

  return (
    <>
        {unidad?.length!==0 ?    
        <>     
          <Header_Jurisdiccion tipo={'min'} unidad={unidad}/>      
          <Container className="px-0 escalar">
            <Jurisdicciones_Panel web={unidad.web} juri={unidad?.unidad} nUno={nUno}/>
            <p className="labelRadio mt-2 mb-5">{labelRadioMin}</p>
            {leyendaEscalar &&
             <div className='alert alert-warning infCons'>{leyendaEscalar}</div>
            }
           
            <Tipo_Vista filtro={context.tipoVista} min={true}/>      
            {context.jurisdiccion==undefined &&
              <Errores desc={context.errores}/>
            }
            { context.loading==false?
            <>
            { context.jurisdiccion?

              <>
                {context.tipoOrganigrama=='Escalar' || context.radio=="rb-ee" || context.radio=="rb-oe" || context.radio=="ueet" ?
                <>              
                  { context.jurisdiccion?.length &&
                  
                    <Jurisdicciones_Escalar tipo={'min'} unidad={unidad} result={context.jurisdiccion} idu={false} nUno={nUno}/>
                  
                  }
                </>
                :          
                <OrganigramaGrafico  nUno={nUno}/>}   
              </> 
              :
              <Row className=" justify-content-center p-3">
              <p className="sinDatos">Sin información.</p>
              </Row>
            }
            </>
            : 
            <Row className="loader justify-content-center p-3">
              <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>  
              <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>   
            </Row> 
            }
            <Referencias/>
          </Container>
        </>
        :
          <Row className="loader justify-content-center p-3">
            <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>   
            <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>       
          </Row> 
        }
    </>
  )
}

export default Ministerio;