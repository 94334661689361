import DescargaArchivos from "./descargaArchivos";
import {Row} from 'react-bootstrap'
import GaleriaImagenes from "./galeriaImagenes";
import InformacionFuentes from './informacionFuentes'
import {Container} from 'react-bootstrap'

function InformeMultimedia(props){

    const galery=props.galery
    const metaGalery= props.metaGalery
    const pathInformes=props.pathInformes

 
    return(
           <div >
           <div className="bg-galeria">

                <Container className="my-4 py-5 ">
                    <h3>
                        Galería de Imágenes
                    </h3>
                    <Row className="mx-0" >
                        {metaGalery &&
                            <GaleriaImagenes galery={galery? galery:null} metaGalery={metaGalery? metaGalery:null}/> 
                        }  
                    </Row>  
                </Container>
            </div>
            <Container>
                <DescargaArchivos pathInformes={pathInformes} metaGalery={metaGalery}/>                
                <InformacionFuentes galery={galery} />
            </Container>    
         </div> 
    )
}
export default InformeMultimedia;