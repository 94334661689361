import React, { useEffect, useContext } from 'react'

import Context from './front/components/context';

function Doc(){

    const context=useContext(Context);

useEffect(()=>{
    document.title =context.titleDoc
},[context.titleDoc])
    
  

return(
    <></>
)
}
export default Doc;


