
import {Container, Row} from 'react-bootstrap'
import InformeMultimedia from './InformeMultimedia'
import {useEffect} from 'react'

function ContenidoInforme(props){

    const textoDato=props.textoDato
    const galery=props.galery
    const pathInformes=props.pathInformes
    const metaGalery=props.metaGalery


    return(
        <div className="bg-25">
        <Container fluid className="py-4 datosContenido px-0 text-justify">            
                    <Container className="px-0 contenidoDeTexto ">
                        <p>
                            {textoDato?.map((texto,i)=>{
                                return(
                                    <p key={i}>
                                        {texto}
                                    </p>
                                )
                            })
                            }
                            <small className="d-block mt-5">
                                {galery?.notas}
                            </small>
                        </p>
                    </Container> 

                    <InformeMultimedia pathInformes={pathInformes} galery={galery} metaGalery={metaGalery}/>
                    
            </Container> 
            </div>
    )
}

export default ContenidoInforme;
