

function HeaderFichaUnidad(props){

    const unidad=props.unidad
    const bgColor=props.bgColor
    
    return(
        <div id="headerFicha" className={`d-flex rounded justify-content-between ${bgColor} px-2 py-3 mx-3 mb-2`}> 
            <div id="headerFichaUnidad">
                <h1 className='ft-white text-center'>{unidad?.unidad.toUpperCase()}</h1>
                <p className="text-center ft-white">{unidad?.jurisdiccion!==unidad?.unidad? unidad?.jurisdiccion.toUpperCase():null}</p>
                <p className="text-center ft-white">{unidad?.tipoadministracion.toUpperCase()}</p>
            </div>
        </div>
    )
}

export default HeaderFichaUnidad;