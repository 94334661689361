

function ListadoNormas(props){

    const nestructura=props.nestructura!==null?props.nestructura:""
    const estructura=nestructura?.replace(/;\s+/g,';').split(";[").reverse()
    

    return(
        <div className="normasList mb-0 nueva">
            {estructura.length && estructura?.map((estru, i)=>{
                let normativa= estru.split("](")
                
                return(
                    <div key={i} className={` ${i==12?'maxList noneNormas':i<2?'blockNormas':'noneNormas'}`}>                               
                        {normativa!== undefined && normativa!==null &&
                        normativa[1]!==undefined && normativa[1]?.slice(0, normativa[1].length-1).replace(')','')!==''?
                        <a rel="noopener noreferrer" href={normativa[1]?.slice(0, normativa[1].length-1).replace(')','')} target="_blank">
                            <p  className="mb-0 ft-dark ">
                                {i === estructura.length-1 ? normativa[0]?.replace(']','').replace('[',''): normativa[0]?.slice(0).replace(']','')}
                            </p>
                        </a>
                        :
                        <p className="mb-0">
                            {i === estructura.length-1 ? normativa[0]?.replace(']','').replace('[','') : normativa[0]?.slice(0).replace(']','')}
                        </p>
                        }
                    </div>        
                )
            })}
        </div>
    )
}

export default ListadoNormas;