import {label_nn,label_apellido,label_detalle } from '../../contenido/normativaFiltros'
import { useEffect,useContext} from "react";
import {Container, Row, Col} from 'react-bootstrap'
import {pathBack} from '../paths'
import Context from '../context';
import $ from 'jquery';
import Fecha from '../fecha';
import BuscarButton from '../buscarButton';
import { CgClose, CgOpenCollective } from "react-icons/cg";
import SelectJurisdiccion from './selectJurisdiccion';
import SelectTipoNormativa from './selectTipoNormativa'
import SelectTemaNormativa from './selectTemaNormativa'


function BusquedaLateral(props){

const context=useContext(Context);


function etiquetas(){

// Cuando un parámetro de búsqueda toma un valor distinto de null, el input asociado a este se oculta y en su lugar se muestra la etiqueta, que muestra el valor seleccionado junto a un botón de cierre que al apretarlo actualiza los resultados de la búsqueda, ya sin ese parámetro, oculta la etiqueta y vuelve a mostrar el input.

   //Etiqueta de Tipo de Norma
  if(context.tipoNorma){
    $('#tn').hide();
    $('#tagTipo').show();
    $('#cerrarTag').click(function() {
     context.pushParam([['.','tnorma'],'tnorma'], true)
     context.setearTipoNorma()
    $('#tn').show();
    $('#tagTipo').hide();
  });
  }
  else{
      $('#tagTipo').hide();
  }

  //Etiqueta de Jur
  if(context.nUno){
    $('#n1').hide();
    $('#tagJur').show();
    $('#cerrarTag2').click(function() {
      context.pushParam([[null,'n1'],'n1'], true)
    $('#n1').show();
    $('#tagJur').hide();
    context.setearNuno()
  });
  }
  else{
      $('#tagJur').hide();
  } 

//Etiqueta de textDes
  if(sessionStorage.getItem('uoape')){
    $('#uoape').hide();
    $('#tagUoape').show();
    $('#cerrarTag10').click(function() {
      context.pushParam([[null,'uoape'],'uoape'], true)
    $('#uoape').show();
    $('#tagUoape').hide();
    context.resetTextDes('')
  });
  }
  else{
      $('#tagUoape').hide();
  } 

     //Etiqueta de Texto
     if(sessionStorage.getItem('dni')){
      $('#dni').hide();
      $('#tagDni').show();
      $('#cerrarTag11').click(function() {
        context.pushParam([['','dni'],'dni'], true)
      $('#dni').show();
      $('#tagDni').hide();
      context.resetDni('')      
    });
    }
    else{
        $('#tagDni').hide();
    }
  
  //Etiqueta de Tema de Norma
  if(context.temaNorma){
    $('#t').hide();
    $('#tagTema').show();
    $('#cerrarTag3').click(function() {
      context.pushParam([['.','tema'],'tema'], true)
    $('#t').show();
    $('#tagTema').hide();
    context.setearTemaNorma()
  });
  }
  else{
      $('#tagTema').hide();
  } 
   //Etiqueta de Texto
   if(sessionStorage.getItem('search')){
    $('#detalleSearch').hide();
    $('#tagSearch').show();
    $('#cerrarTag4').click(function() {
      context.pushParam([['','search'],'search'], true)
    $('#detalleSearch').show();
    $('#tagSearch').hide();
    context.resetTextBA('')
  });
  }
  else{
      $('#tagSearch').hide();
  }

   //Etiqueta de Año de Norma
   if(sessionStorage.getItem('anorma')){
    $('#AnormaInput').hide();
    $('#tagAnorma').show();
    $('#cerrarTag8').click(function() {
      context.pushParam([['','anorma'],'anorma'], true)
    $('#AnormaInput').show();
    $('#tagAnorma').hide();
    context.resetAnorma()
  });
  }
  else{
      $('#tagAnorma').hide();
  }
  //Etiqueta de N° de Norma
  if(sessionStorage.getItem('nnorma')){
    $('#NnormaInput').hide();
    $('#tagNnorma').show();
    $('#cerrarTag9').click(function() {
      context.pushParam([['','nnorma'],'nnorma'], true)
    $('#NnormaInput').show();
    $('#tagNnorma').hide();
    context.resetNnorma()
  });
  }
  else{
      $('#tagNnorma').hide();
  }
}
 

useEffect( ()=>{ 
  etiquetas();
  
  context.concatParam(context.newParam) //Concatena los parámetros de búsqueda seleccionados a la URL de solicitud a la API.
  },[context.newParam, context.normDatos])

  function handleSubmit(){
    context.setearParametros(context.newParam)// Asigna los valores de los parámetros que modificó el usuario a su respectivo estado.    
    context.fetchNormativas(pathBack,context.URLbuild, props.jur||null)// Realiza un nuevo pedido a la API con estos nuevos valores
    window.scrollTo(0,200)
	}

  function toggleOculto2(){    
     $('#bLateral').toggleClass("oculto");     
  }//Oculta o los filtros de búsqueda en la pantalla mobile, al cliquear el botón cerrar.



return(
	<Container className="px-2 busqLateral">
    <div id="Lateral" className="pr-0 d-flex col-lg-12 col-md-12">
      <div id="filtrosLateral" >
      <h4 className='my-0'>Aplicar filtros</h4>
      <Row className="px-0 pb-2 mx-0" id="fechasLateral">
          <Fecha Lateral={'s'}/>
      </Row>
      {context.TN==='ba'	&&
      //Si la sección es Búsqueda Avanzada, en la búsqueda lateral aparecen los campos de búsqueda de Tipo de Norma, N° de Norma y Tema. 
        <Row className="mx-0 tagsLateral">
          <Row className="px-0 pb-2 rowTags">
            <Col xl={11} className="colTags">          
                <div id="tagTipo" className="tagSelect bg-shadow4">            
                  Tipo: {context.tipoNorma && context.tipoNorma[1]}
                  <span id="cerrarTag"><CgClose/></span>            
                </div>          
                <SelectTipoNormativa />
            </Col>
          </Row>
          <Row className="px-0 pb-2 rowNorma rowTags">           
            <Col xl={11} className="colTags">
              <div id="tagNnorma" className="tagSelect bg-shadow4">            
                N° de Norma: {context.nnorma && context.nnorma}
                <span id="cerrarTag9"><CgClose/></span>            
              </div>
              <input onChange={e=>context.setearNnorma(e.target.value)} 
              type="text" class="input-lg form-control" name="nn"
              value={context.TN==='ba'? context.nnorma:''} 
              id="NnormaInput" autocomplete="off" 
              placeholder={label_nn}/>
            </Col>
          </Row>        
          <Row className="px-0 pb-1 mx-0 rowTags">
            <Col className="pl-0 colTags" xl={11}>
                <div id="tagTema" className="tagSelect bg-shadow4 ">                
                  Tema: {context.temaNorma && context.temaNorma[1]}
                  <span id="cerrarTag3"><CgClose/></span>              
                </div> 
                <SelectTemaNormativa/>              
            </Col>    
          </Row>
          <Row className="searcher px-0 my-1 pb-1 py-0 mx-0 rowTags">        
            <Col className="pl-0 colTags" xl={11}> 
              <div id="tagSearch" className="tagSelect bg-shadow4 colTags">            
                Texto detalle: {context.textBA && context.textBA}
                <span id="cerrarTag4"><CgClose/></span>          
              </div>
              <input value={context.textBA} type="text" class="form-control input-lg" name="detalle" autocomplete="off" id="detalleSearch" width="125" placeholder={label_detalle} onChange={e=>context.setearTextBA(e.target.value)}/>
              <div id="mensajediv" className={context.msj3}>No admite caracteres especiales</div>
            </Col>
          </Row>
        </Row>
      }
      
      {(context.TN==='ba' || context.TN==='df')	&&
     //Si la sección es Búsqueda Avanzada o Designaciones, aparece el campo Jurisdicción.
        <Row className="px-0 my-0 pb-2 mx-0 rowTags">
          <Col className="pl-0 colTags" xl={11}> 
            <div id="tagJur" className="tagSelect bg-shadow4">            
              Jurisdicción: {context.nUno && context.nUno[1]}
              <span id="cerrarTag2"><CgClose/></span>          
            </div>          
            <SelectJurisdiccion/>            
          </Col> 	
        </Row>
      }
      {context.TN==='df'&&
       //Si la sección es Designaciones, muestra los campos DNI y Detalle Unidad.
      <Row className="searcher px-0 py-0 my-0 mx-0 rowTags">
          <Col  className="colTags  my-1 tagUoapeLateral"  xl={11}>
              <div id="tagUoape" className="tagSelect bg-shadow4">            
                Detalle Unidad: {context.textDes && context.textDes}
                <span id="cerrarTag10"><CgClose/></span>          
              </div> 
              <input type="text" id="uoape" class="form-control input-lg" name="detalle" value={context.TN==='df'?context.textDes:''} autocomplete="off" width="125" placeholder={label_apellido} onChange={e=>context.setearTextDes(e.target.value)}/>
          </Col>
          <Row id="dniLateral" className="rowTags searcher px-0 py-0 my-1 mx-0">
             <Col className="mx-0 px-0 py-1 colTags " xl={11}>
                <div id="tagDni" className="tagSelect bg-shadow4">            
                  DNI: {context.dni && context.dni.replace('.','')}
                  <span id="cerrarTag11"><CgClose/></span>          
                </div>
                <input type='number' class="form-control input-lg" name="dni" id="dni" value={context.dni} onChange={e=>context.setearDni(e.target.value)} autocomplete="off" width="50" placeholder='DNI'/> 
             </Col >
         </Row>
      </Row>}
      <Row className="px-0 pb-3 py-0 mx-0 rowBusqLateral">   
        <BuscarButton customClickEvent={handleSubmit}/>
      </Row>
    </div>
    <div id="ocultarBlateral" onClick={toggleOculto2} className="my-2 justify-content-center align-items-start px-1">
       <div className="d-flex align-items-center justify-content-center btnClose">
       <CgClose size={13}/>
       </div>
    </div>
  </div>
  </Container>
)

}

export default BusquedaLateral;