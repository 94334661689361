require('dotenv').config({
    path:`../../.env.${process.env.NODE_ENV || 'development'}` 
})

export const path= process.env.REACT_APP_URL; 
export const pathFicha= process.env.REACT_APP_URL_FICHA
export const path3=process.env.REACT_APP_URL_3
export const path1=process.env.REACT_APP_URL_1
export const path2=process.env.REACT_APP_URL_2
export const db=process.env.REACT_APP_DB
 

// Esta variable REACT_APP_URL (las variables de entorno customizadas de react llevan el prefijo REACT_APP_) tiene un valor distinto en cada archivo de entorno.
// el valor será null si consume internamente la api y la url externa en caso contrario


