import React, { useEffect, useState, useContext } from "react";
import Context from '../components/context';
import {  pathBack2 } from "../components/paths";
import {Container, Button, Row, Col, Spinner} from 'react-bootstrap'
import {useHistory} from "react-router-dom"
import {FaSearch, FaSortDown} from 'react-icons/fa'
import {placeholder} from '../contenido/index'
import {tituloRes} from '../contenido/busqueda'
import { useParams} from "react-router-dom";
import $ from 'jquery'
import RefBusqueda from "../components/referenciasBusqueda";
import UnidadResultadoBusqueda from "../components/unidad-resultadoBusqueda";

//////Componente de búsqueda. En base a la url, que se genera en la vista anterior,
////vamos a acceder a la info a través del id de la url ({match})

//Contiene los resultados de la búsqueda realizada en Home y el input del buscador para realizar una nueva búsqueda.

const Busqueda = ({match}) => {

  const history=useHistory() 
  const [showMore, setShowMore]=useState(20) 
  const context=useContext(Context);
  const param=useParams()
  const busqueda=param.search
 
    useEffect(() => {
        $(window).click(function() {
          context.setMsj('dispnoneMsj')
        });
        const timer = setTimeout(() => {
            if(context.resBusqueda == null){
              context.verError() 
            }          
        }, 8000);
        
        return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    context.setFooterPath(pathBack2)
    context.setMsj4('dispnoneMsj')
    context.setMsj3('dispnoneMsj')
    sessionStorage.setItem('radio', 'rb-comp')
    context.setearRadio('rb-comp')  
    context.setearTitleDoc('Mapa - Búsqueda')
    context.setearE404(false)
    context.setearBusquedaHome(busqueda)//Asigna al estado busquedaHome el valor que se encuentra en el parámetro 'search' de la URL
    sessionStorage.setItem('busquedaHome', busqueda)//Asigna al item de sessionStorage 'busquedaHome' el valor que se encuentra en el parámetro 'search' de la URL  
    context.setearBreadCrumb([{label:'Home',url:"/"},
   {label:`Búsqueda: ${busqueda.replace(/-/g,' ')}` , url:`/busqueda/${context.busquedaHome}`}])
   context.getBusqueda(busqueda.replace(/-/g,' '))
   //Agrega al breadcrumb el elemento que vincula a la búsqueda realizada.
  }, [busqueda]);


function volverBusqueda(){
  //Vuelve a la página Home
  history.push('/')
}

function handleSubmit(e){
      //Lleva a la página 'busqueda' con el resultado de la nueva búsqueda. 
      e.preventDefault()
      if(context.busquedaHome!==''){        
        history.push(`/busqueda/${context.busquedaHome.replace(/\s+/g, '-')}`)     
      }
}



return(
    <div className='paginaBusqueda'>
     <header>
      <div className=" bg-16 p-2">
        <Container>
          <div className="col-10 p-0">
            <h1 className="pl-0">Búsqueda</h1>
          </div>
          <Row className="mx-0">    
            <div id="buscador" className='col-md-8 col-lg-7 col-xl-7 p-0'>
              <form className="buscador" onSubmit={handleSubmit}>
                <div className="input-group mb-3 buscador__input">
                  <input
                    placeholder={placeholder}
                    type="text"
                    value={context.busquedaHome.replace(/-/g,' ')}
                    className={`form-control `}
                    aria-label="Buscar por Organismo"
                    aria-describedby="basic-addon2"
                    onChange={e=>{context.setearBusquedaHome(e.target.value)}}
                  />
                  <div className={context.msj}>No admite caracteres especiales</div>
                  <div className="input-group-append">
                    <Button
                      className="buttonInputBusqueda"
                      type="submit">
                      <FaSearch/>
                    </Button>
                  </div>                  
                </div>
              </form>
            </div>
          </Row>
            {context.metaDatos!==null?
              <p className="text-left">{tituloRes}
              <p className="font-italic d-inline mr-1 font-weight-bold mx-1"> 
              {context.metaDatos?.subtitulo.replace(/-/g,' ')} - 
              </p>{context.metaDatos?.datosQ} 
              <span className='mx-1'>resultados encontrados</span>
              </p>           
              :
              null
            }
         </Container>
      </div>
    </header>

    <Container className="py-0 my-5">
         <RefBusqueda/>
         {context.metaDatos!== undefined && context.resBusqueda!==undefined &&
          <>
            {context.metaDatos?.datosQ!==0 ? 
              <>
                {context.resBusqueda !== null ?
                  <>      
                    {context.resBusqueda?.datos?.slice(0,showMore).map((dato,i)=>{
                      //Muestra los resultados y va sumando 20 al presionar 'Mostrar más'
                            return(
                              <>
                                <UnidadResultadoBusqueda dato={dato} i={i} search={match.params.search}/>
                              </>
                            )
                        })              
                    }
                  </>
                :
                  <Row className="justify-content-center p-3">
                    <Spinner className="hideMe" animation="border" size="md" variant='secondary'/>
                    <p className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
                  </Row> 
                }
              </>
          : 
           <>   
              {
                <div className="my-4 alert alert-warning">
                  Sin resultados para la búsqueda: <br/>
                  <p className="font-italic">{match.params.search}</p>
                  <div className="py-2 volver" onClick={volverBusqueda}>
                    Volver
                  </div>
                </div>
              }
           </>
         }
          {showMore < context.resBusqueda?.datos?.length ?
              <Row className="showmore justify-content-center my-5" onClick={()=>setShowMore(showMore+20)}>                                   
                <div className="text-center">
                  <p className="mb-0"> Mostrar más</p>
                  <FaSortDown size={32}/>
                </div>                               
              </Row>
          : null}
        </>
         }
    </Container>
    </div>
)
}


export default Busqueda