import { useEffect, useState, useContext } from "react";
import {pathBack} from '../components/paths'
import {db} from '../../configuracion/environment'
import Context from '../components/context';
import '../css/styles.css'
import InformacionUtil from '../components/seccionesHome/informacionUtil'
import AcercaDe from '../components/seccionesHome/acercaDe'
import {useHistory} from "react-router-dom"
import DatosHome from '../components/seccionesHome/datosHome'
import Composicionpen from '../components/seccionesHome/composicionpen';
import $ from 'jquery'
import BusquedaHome from './busquedaHome/index'
import Tarjetas from './tarjetas'


function Home(props){

    const context=useContext(Context);
    const history=useHistory()
 
    useEffect( ()=>{
    window.scrollTo(0,0)
    if(context.listaMinHome.length===0){
      context.setDivAlt('heightpage')            
      context.fetchMinisteriosHome(pathBack,`db=${db}&id=4&cslm=s`); //Hace pedido a la api de los datos de id=4, si el array está vacío.   
    } 
    context.resetOrg()
    context.setResBusqueda(null)
    context.setFooterPath(pathBack)
    context.setMsj3('dispnoneMsj')
    context.setMsj4('dispnoneMsj')
    $(".oculto").removeClass('oculto')
    context.setearTitleDoc('Mapa Home')
        context.setearE404(false)
        window.scrollTo(0,0)        
        context.setearBreadCrumb([{label:'Home',url:"/"}])
        context.resetValues(); //Vuelve a vacío todos los estados que modifican la URL
        sessionStorage.removeItem('TN')
        context.resetMinJurNor();//Vuelve a vacío los estados jurNor y minNorm(Que filtran Normativas cuando se abre Normativas o Desinaciones por jurisdicción)
        sessionStorage.removeItem('jurNor')//Elimina el item jurNor (que tiene el valor del estado jurNor) del almacenamiento del navegador  
        sessionStorage.removeItem('minNorm')//Elimina el item minNorm (que tiene el valor del estado minNorm) del almacenamiento del navegador
         
        if(context.dataError==0){
            history.push('/database_error')
        }     
        context.setearBusquedaHome('')//Vuelve a '' el input del buscador.   
        context.setearRadio('rb-comp')                  
        sessionStorage.setItem('radio', 'rb-comp') //Establece como predeterminado 'Administración central' en el filtro del organigrama escalar 
       
        $(window).click(function() {
            context.setMsj('dispnoneMsj')
        });
        
    },[])


       return(
        <>
        <div className={`homeDiv`}>                
                <div id="homeImg">
                          
                    {/* onLoad={functionLoad2}                
                    <img  alt="Foto frente de Casa Rosada" fluid src={`${pathImg}/porta2.jpg`}/>*/}
                    <BusquedaHome/>
                </div>
             {/* Muestra por pantalla las tarjetas con fotos cuando el array listaMinHome tiene elementos. */}
            <>
                <div >
                    <Tarjetas />   
                    <Composicionpen /> 
                    <InformacionUtil/>
                    <DatosHome/>
                    {/*<PortalNumeros/>*/ }
                    <AcercaDe/>
                </div>                
            </>
        </div>
              
        </>       
    ) 

}
export default Home;