

function InfoUnidadOrg(props){

    const reporta_a=props.reporta_a
    const bgColor=props.bgColor
    const car_escalafon=props.car_escalafon
    const carnivel= props.carnivel
    const unidad_ufa= props.unidad_ufa
    

    const formatearFecha=(fecha)=>(`${fecha?.slice(8,10)}-${fecha?.slice(5,7)}-${fecha?.slice(0,4)}`)
    

    return(
        <div className={`rounded py-3 borderFicha_${bgColor} ft-lt-black infoUnidadOrg `}>
            {
            <p className='reportap'>
                Reporta a {reporta_a}
            </p>
            }
            <p>{carnivel}</p>
            <p>Escalafón: {car_escalafon}</p>
            <p>Última Actualización de Estructura: {unidad_ufa!==null?formatearFecha(unidad_ufa):""} </p>            
        </div>
    )
}

export default InfoUnidadOrg;