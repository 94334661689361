
import {Container, Row} from 'react-bootstrap' 
import {db} from '../../../configuracion/environment'
import {FaSitemap} from 'react-icons/fa'
import {pathCsv ,pathOrganigrama, pathBack} from '../paths'
import {altEsYAut, altOrgs} from '../../contenido/informacionUtil'
import Context from '../context';
import { useEffect , useContext, useState } from 'react'
import { pathIconos } from '../paths'

function InformacionUtil(props){


const [csvHIS,setCsvHis]=useState('')
const [csvUFA,setCsvUfa]=useState('')
const context=useContext(Context);

const href_orgmaapn= `${pathOrganigrama}${context.orgAPN?context.orgAPN:null}`


function setearFecha(fhis){
   return `${fhis?.slice(6,8)}-${fhis?.slice(4,6)}-${fhis?.slice(0,4)}`
}

useEffect(()=>{
     setCsvHis(`BIME_estructura_autoridades_apn_${context.hisCSV}.csv`)
     setCsvUfa(`BIME_estructura_autoridades_apn_${context.ufaCSV}.csv`)
     
},[context.orgAPN, context.hisCSV, context.ufaCSV])

return(
    <div id="infoUtil"  className="bg-primary cntFlexHome py-5">
       <div className="mb-5 contenedor_items">
           <h3 className="tituloseccion py-2">Información Útil de la Administración Pública Nacional</h3>
           <div className="boxInfoUtil p-2 ft-white">
               <a href={`${pathBack}db=${db}&id=9&fi=csv`}
               className=" bg-oscuro  px-3"
               // download="BIME_estructura_autoridades_apn_20210902.csv"
               alt={altEsYAut}
               title={altEsYAut}>
                    <img alt="Ícono Descarga Archivo CSV" src={`${pathIconos}/icon_csv-2.svg`}
                    />
                   <p>Estructura y Autoridades de la Administración Pública Nacional<br/>-VIGENTE-
                   <br/>
                   <small>Vigencia: {setearFecha(context.ufaCSV)}</small></p>                
               </a> 
               <a 
                 target='_blank'
                 href={href_orgmaapn}
                 className=" bg-oscuro px-3 "
                 alt={altOrgs}
                 title={altOrgs}>
                 <div className="d-flex flex-column align-items-center justify-content-center px-1 ">    
                    <FaSitemap />
                    <p>Organigrama de la Administración Pública Nacional <br/>-VIGENTE-
                   <br/>
                   <small>Vigencia: {setearFecha(context.ufaCSV)}</small> </p>                
                 </div>
                 </a>
                 <a href={`${pathCsv}/${csvHIS}`}
                    className=" bg-oscuro  px-3"
                    target='_blank'         
                    alt={altEsYAut}
                    title={altEsYAut}
                    >  
                         <img alt="Ícono Descarga Archivo CSV"  width="45px" src={`${pathIconos}/icon_csv-2.svg`}
                    />
                    <p>Estructura y Autoridades de la Administración Pública Nacional<br/>-HISTÓRICO-<br/>
                    <small>Vigencia: {setearFecha(context.hisCSV)}</small></p>                
                 </a>
        </div> 
       </div>       
    </div>
)
}

export default InformacionUtil;