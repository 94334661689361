import { useEffect, useState, useContext } from "react";
import { Row, Col} from 'react-bootstrap'
import { pathIconos } from "../paths";
import {useHistory} from "react-router-dom"
import Context from '../context';
import { pathBack2, pathOrganigrama} from '../paths'
import {FaShare} from 'react-icons/fa'
import { altOrgPDF,altWeb, altCSV, altPDF, altXLS } from "../../contenido/alt";
import {db} from '../../../configuracion/environment'
import $ from 'jquery'

function Organismos_Panel(props) {
   
  
  const context=useContext(Context);
  const [selectOrg,setSelectOrg]=useState('')
  const history=useHistory()

  var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
  function accent_fold (s) {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
    ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }//Función que reemplaza letras con acento por letras sin acento.


    useEffect(()=>{
        var select = $('#selectOrganismos');
        select.val(select.children('option:first').val());
        context.getOrganismos('panel'); //Pide a la API el listado de todos los organismos. El array obtenido se itera en la lista desplegable de organismos del panel.

    },[props.idu])

    function changeorg(){
        history.push(`/organismos/${selectOrg}`)
    }

    function imprimir (){
        window.print("");
    }
  
  return (
           <>           
            <Row className="mx-2 py-3 panel_min justify-content-between align-items-center">
                <Col className="px-2 py-2 mr-2 orgmaBoton" lg={12} xl={3}>
                <a target="_blank" rel="noopener noreferrer" href={`${pathOrganigrama}${accent_fold(props.ncorto).replace(/\s+/g, '').toLowerCase()}.pdf`} title={altOrgPDF}>
                    <img src={`${pathIconos}/icon_organigrama.svg`} className="d-inline" width= "50px" alt={altOrgPDF}/>
                    <h3 className="d-inline ml-2">ORGANIGRAMA</h3>
                </a>    
                </Col>
                <Col lg={12} xl={8}> 
                <Row className="align-items-center rowOrgPanel">
                    <Col className="text-right iconsOrgPanel" md={7} lg={12} xl={5}> 
                        <a rel="noopener noreferrer" target="_blank" href={props.web}>                       
                            <img className="ml-3" width="50px" src={`${pathIconos}/icon_web.svg`} title={altWeb} alt={altWeb}/>
                        </a>
                        <a rel="noopener noreferrer"  href={`${pathBack2}db=${db}&id=14&fi=xls&idu=${props.idu}`}>
                            <img className="ml-3" width= "50px" src={`${pathIconos}/icon_xls.svg`} title={altXLS} alt={altXLS}/>
                        </a>
                        <a rel="noopener noreferrer"  href={`${pathBack2}db=${db}&id=14&fi=csv&idu=${props.idu}`}>
                            <img className="ml-3" width= "50px" src={`${pathIconos}/icon_csv.svg`} title={altCSV} alt={altCSV}/>
                        </a>
                        <span className="pdfPrint" onClick={imprimir}>
                            <img className="ml-3" width= "50px" src={`${pathIconos}/icon_pdf.svg`} title={altPDF} alt={altPDF}/>
                        </span>    
                    </Col>   
                    <Col className="d-flex selectOrgs" lg={12} sm={12} xl={7}> 
                        <select id="selectOrganismos" className="form-control" onChange={(e)=>{
                            setSelectOrg(e.target.value)
                        }}>
                            {/* <option value disabled selected>Ir a otro Organismo</option> */}
                            <option value=''>Ir a otro Organismo</option>
                            {
                                context.organismos?.map((org,i)=>{
                                    return(
                                    <>
                                    {org.oescalar=='S' && 
                                    <option 
                                    key={i}
                                    value={org.idunidad}>
                                        {org.nombre_corto}
                                    </option>}
                                    </>
                                    )                               
                                    
                                })
                            }
                        </select>
                        
                        <button disabled={selectOrg===''?true:false} onClick={changeorg} className="irButton mb-0 mx-2 px-2 buttonsClass ft-white bg-dark">
                                <FaShare/>
                        </button>
                    </Col>
                </Row>                     
                </Col>
            </Row>
            </>
            )
  }
  
  export default Organismos_Panel;