import {label_nn, label_detalle } from '../../contenido/normativaFiltros'
import {Container, Row, Col} from 'react-bootstrap'
import { useEffect, useContext } from "react";
import Fecha from '../fecha'
import {pathBack} from '../paths'
import Context from '../context';
import $ from 'jquery';
import SelectJurisdiccion from './selectJurisdiccion'
import SelectTipoNormativa from './selectTipoNormativa'
import SelectTemaNormativa from './selectTemaNormativa'
import BuscarButton from '../buscarButton'
import LimpiarBusquedaButton from '../limpiarBusquedaButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {db} from '../../../configuracion/environment'

function NormativaBA(props) {

let query2=`db=${db}&id=8&ob=nba`;
const context=useContext(Context);



    useEffect( ()=>{    
     if(context.newParam && context.newParam.length!==0){       
       context.concatParam(context.newParam)//Concatena al string de URL los elementos del array newParam
     } 
     if(context.newParam==[] || context.newParam==null || context.newParam.length==0){  
        context.showBuscadorHztal();
     }

     $(window).click(function() {
      context.setMsj3('dispnoneMsj')
    });
    
    $('#mensCaracteres').click(function(event){
      event.stopPropagation();
    });
    
    },[context.newParam]) //Cada vez que cambia el array newParam (que contiene los parametros de la busqueda a realizarse), se ejecuta la funcion que arma el string de URL con esos parámetros.
    


function handleSubmit(){
  //Envia solicitud a la API con los parámetros seleccionados en los filtros de busqueda
  if(context.URLbuild!==query2){
    
     context.setearParametros(context.newParam)
    //  context.fetchNormativas(context.URLbuild)
  }
  else{
   
   toast.error('Debe seleccionar al menos un filtro',{
    position: "bottom-center",
    autoClose: 5000,    
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    })
  } 

 }


const handleReset=()=>{
  context.setMsj3('dispnoneMsj')
  context.setMsj4('dispnoneMsj')
  //Vuelve a vacios todos los inputs de los filtros de busqueda.
  $('#busquedaTexto').val('')
  $('#NnormaInputBA').val('')
  $('#from').val('')
  $('#to').val('')
  var select = $('#n1');
  select.val(select.children('option:first').val());
  var select2 = $('#t');
  select2.val(select2.children('option:first').val());
  var select3 = $('#tn');
  select3.val(select3.children('option:first').val());
  context.resetValues()
}


   return (
   <Container className="py-5"> 
        <div id="tituloNormativas" className='d-flex py-2 my-3 pl-2 tituloNormativa align-items-center'>  
          <div id="tituloTexto" className="px-1">Filtros de búsqueda</div>
        </div>  
        <Row id="bloque-Fechas-texto" className="mx-0 py-3  align-items-end">
            <Col className="searcher  py-0" xl={4} xs={12} sm={12} lg={4}>
                <input 
                id="busquedaTexto"
                value={context.textBA}
                type="text" 
                class="form-control input-lg"
                name="detalle" autocomplete="off"
                width="125"
                placeholder={label_detalle}
                onChange={e=>context.setearTextBA(e.target.value)}/>
              <div id="mensajediv2" className={context.msj3}>No admite caracteres especiales</div>
            </Col>
            <div id="bloqueFechas" className="order "   >
                <Fecha/>
            </div>
      	</Row>
        <Row className="pb-3 mx-0 px-0 selectsBa">
            <div  className="py-1 colSelect">           
              <SelectTipoNormativa/>
            </div>
            <div className=" py-1 colSelect">
              <input id="NnormaInputBA" onChange={e=>context.setearNnorma(e.target.value)} type="text" class="input-lg form-control" name="nn" value={context.nnorma} autocomplete="off" placeholder={label_nn}/> 
            </div>            
            <div  className=" py-1 colSelect">
              <SelectJurisdiccion/>
            </div>
            <div className=" py-1 inputTema colSelect">         
              <SelectTemaNormativa/>
            </div>
         </Row>
         <Row className="py-3 botonesFiltrosBA col-md-10 col-sm-9 mx-0">           
              <BuscarButton customClickEvent={handleSubmit} />
              <div className="mx-2">
                 <LimpiarBusquedaButton customClickEvent={handleReset}/>
              </div>     
        </Row>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          toastStyle={{ backgroundColor: "#e7c5c5" }}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />   
   </Container>
   )
   
 }
 
 export default NormativaBA;
