export const headTitulo = 'Normativas';
export const sinResultados= 'No se encontraron normativas para la búsqueda solicitada: ';
export const headVolanta = 'Esta sección exhibe las normativas asociadas a Estructura, Designaciones, Objetivos, Competencias, Responsabilidades Primarias y Acciones en el ámbito de la Administración Pública Nacional (APN) que se publican en Boletín Oficial. ';
//Seccion 2
export const s02_titulo = 'Ley de Ministerios';
//Secion 2.1
export const s02_0101_at01 = 'Texto Ordenado Objetivos APN  - Secretarías y Subsecretarías';
export const s02_0101_h401 = 'Texto Ordenado APN';
export const s02_0101_p01 = 'Objetivos Secretarías y Subsecretarías';
//Secion 2.2
export const s02_0102_at01 = 'Organigrama de la Administración Pública Nacional';
export const s02_0102_h401 = 'Organigrama APN Administración Central';
export const s02_0102_p01 = 'Administración Central y<br> Organismos Desconcentrados';
//Secion 2.3

export const s02_0103_at01 = 'Organigrama Descentralizados y Entes del Sector Público Nacional';
export const s02_0103_h401 = 'Organigrama APN <br>Adm. Descentralizada';
export const s02_0103_p01 = 'Organismos Descentralizados y<br> Entes del Sector Público Nacional';
//Seccion 3
export const s03_titulo = 'Normativa de Estrucutra ';
export const s03_0101_p01 = 'En el Portal encontrarás información vinculada a la Administración Pública Nacional -centralizada, desconcentrada,  descentralizada- y Entes del Sector Público Nacional.';
export const s03_0101_p02 = 'La información disponible incluye hasta el Segundo Nivel Operativo de cada jurisdicción, desde el 10 de diciembre de 2015 a la fecha de actualización.';
export const s03_0101_p03 = 'A fin de acercar y transparentar la información accesible sobre la Administración Pública Nacional, se encuentra disponible para su descarga la estructura organizativa -organigrama escalar- en formatos PDF y CSV, integral y por jurisdicción, como así también los diferentes organigramas por ministerio y organismos descentralizados.';
export const s03_0101_p04 = 'Este sitio se encuentra en permanente proceso de actualización.';
//Seccion 4
export const s04_titulo = 'Textos Ordenados ';
//Sección 5
export const s05_resulLM = "Ley de Ministerios";
export const s05_resulNO = "Primer y Segundo Nivel Operativo";
export const s05_resulSS = "Secretarías y Subsecretarías Ministeriales";
export const s05_resulDE = "Designaciones";
export const alt1y2NO= ""
export const altSSS=""
export const altLM=""
export const altDesig=""
export const altBA=""