import { useEffect,  useContext, useState } from "react";
import { Row, Col} from 'react-bootstrap';
import Context from '../context';
import $ from "jquery"
import {pathImg} from '../paths'

// import arrows-angle-expand from '.'
function OrganigramaGrafico(props) {

const context=useContext(Context);
const result=context.jurisdiccion;

var listaGrafico='<ul class=tree>'
var listaGrafico=''

var nivel=[]

var contadorCierre=0
var g=0
var menorAultimo=true
var anterior=0

useEffect(() => { 
  if(context.radio=='rb-comp'){
    if(context.opcion!=='rb-comp' ){
        context.setOpcion('rb-comp')
  }
  }
  else if(context.radio=='as'){
    if(context.opcion!=='as' ){
        context.setOpcion('as')
  }
  }
},
[context.radio])


useEffect(()=>{ 
  window.scrollTo(0,0) 
  //  tree();
  // document.getElementById('primerID')
  for(var o=0; o<$('.box').length;o++){
     $('.box')[o].addEventListener("click", element, false)
  }
 
  var allUlsLi=document.querySelectorAll('#tree > li')  
  //Obtiene todos los hijos directos de #tree que sean li
  var idLista= allUlsLi && allUlsLi.length? '#'+allUlsLi[0].id : null  
  //Obtiene el id del primer elemento li hijo directo de #tree
  var allUls= idLista ? document.querySelectorAll(`${idLista} > ul`) : null
 //Obtiene todos los elementos ul hijos directos del primer elemento Li (idLista) 
  var ulPpal= allUls ? allUls[0] : null
  //Obtiene el primer elemento de la lista de elementos ul hijos directos de idLista
  var idUlPpal=ulPpal ? '#'+ulPpal.id : null
  //Obtiene el id de ese elemento ulPpal
  var allLi=idUlPpal ? document.querySelectorAll(`${idUlPpal} > li`) : null
  //Obtiene todos los elementos li hijos directos de ulPpal
  

  if(allLi!==null){
      for(var k=0; k<allLi?.length;k++){
        //Recorre todos los <li> dentro de la primer lista <ul> 
              var elementoUL=null
             
              if(allLi[k].getAttribute('value')){   
                
                  elementoUL=allLi[k]?.getElementsByTagName('ul')[0] 
                  //Toma los <li> que tienen lista desplegable dentro.
                  if(elementoUL!==undefined){
                  
                    var idPrimerUL=allLi[k].id
                   //Toma el id del <li> si tiene un <ul> dentro.

                    var primerUL=document.getElementById(idPrimerUL)?.querySelectorAll(`#${idPrimerUL} > ul`)[0]
                    //Toma la lista desplegable de más afuera, si tiene.
          
                  if(primerUL!==undefined){
                    if(primerUL.getAttribute('value')!=='1'){
                      //Si el atributo value de las listas de cada <li> es distinto de 1, es porque son listas que no son de las de más afuera, y deben estar ocultas. 

                      var valueUL=allLi[k].getElementsByTagName('ul')[0].getAttribute('value') 

                     
                        allLi[k].getElementsByTagName('ul')[0].style.display='none'
                        //Toma la lista desplegable de más afuera que tenga el <li> y la oculta, con display:none
                     
                    }
                  }       
                  }                    
              }


              var elementoULLI=allLi[k].getElementsByTagName('ul')[0]  
              //Toma nuevamente la lista <ul> de mas afuera. 

              if(elementoULLI!==undefined){
                //Si esa lista de mas afuera tiene display:none, le agrega la clase elementoLista-none
                  if(elementoULLI.style.display=='none'){
                    var elementoPadre=allLi[k]
                    var idPadre=elementoPadre.id
                    var htmlPadre=document.getElementById(idPadre)
                    htmlPadre.classList.add('elementoLista-none')
                    htmlPadre.classList.remove('elementoLista-block')
                  }
                  if(elementoULLI.style.display=='block'){
                     //Si esa lista de mas afuera tiene display:block, le agrega la clase elementoLista-block
                    var elementoPadre=allLi[k]
                    var idPadre=elementoPadre.id
                    var htmlPadre=document.getElementById(idPadre)
                    htmlPadre.classList.add('elementoLista-block')
                    htmlPadre.classList.remove('elementoLista-none')
                  }
              }

      }
  }


  $(document).ready(function(){    

  var elementoLista= document.getElementsByClassName('elementoLista-none')
  //Cuando se cargó el HTML, toma todos los elementos con clase elementoLista-none y les agrega un icono de expandir.

  for(var t=0; t<elementoLista?.length; t++){

    var elementoImg = document.createElement("img");
    elementoImg.src=pathImg+"/arrows-angle-expand.svg"
    elementoImg.className='iconLista'
    elementoImg.id='idImg'+t
    var aTag=document.querySelector(`#${elementoLista[t].id} .dfBox`)

    elementoImg.setAttribute('value',t)    
     
    aTag.appendChild(elementoImg);  
  }
  }) 
 

  },[]) 

 
  const compare=(cuo)=>{
    if(anterior==cuo){     
      
      return false;
    }
    else{      
          
      return true;
    }
  } //Verifica si el elemento actual del array pertenece a la misma unidad que el elemento anterior
  // y si pertenece, imprime un <p> en vez de <li>.


  function colapsarListas(event){
    var elementoPadre2= event.currentTarget.parentNode.parentNode
    // Se cliqueó un elemento <a> dentro de una lista abierta, se obtiene el elemento padre. Un <li>

    var idUlPpal2=elementoPadre2!==null ? elementoPadre2.id : null
    var listaUls2=document.getElementById(idUlPpal2).getElementsByTagName('ul')
  
    var menorUl2=null
    var menorUlelement2=null
    var numVal2=null
    var ulElement2=null
  
  
    for(var z=0; z<listaUls2?.length;z++){ 
      //Obtiene la primera de las listas o <ul> por medio de su atributo value. Que va asignado por orden de aparición en el DOM y se obtiene desordenado en el getElementsByTagName.  
        if(z==0){
          menorUl2=parseInt(listaUls2[z].getAttribute('value'))
          menorUlelement2=listaUls2[z]              
        }
        else{
           var numVal2=parseInt(listaUls2[z].getAttribute('value'))
            if(numVal2< menorUl2){
              menorUl2=numVal2
              menorUlelement2=listaUls2[z] 
            }
        }
      
    }
  
    ulElement2=menorUlelement2
    //Asigna a la variable ulElement el elemento HTML de la <ul> desplegada.
    
   if(ulElement2!==null){
    
    var idUlElement= '#'+ulElement2.id
    var allLi2=idUlElement!==null ? document.querySelectorAll(`${idUlElement} > li`) : null
    //Obtiene los elementos <li> hijos directos de la lista desplegada.

    if(allLi2!==null){  
  
      for(var x=0; x<allLi2?.length;x++){
         
          var idLiseleccionado= allLi2[x].id
        
          var ulDelista=document.getElementById(idLiseleccionado).querySelectorAll(`#${idLiseleccionado} > ul`)
          //De cada <li> hijo directo obtiene su propia lista desplegable y la cierra con el siguiente for.

          for(var n=0; n<ulDelista?.length; n++){        
            //Cambia a display:none a todas las listas 
            ulDelista[n].style.display='none'
          }
          var elementoULLI2=allLi2[x].getElementsByTagName('ul')[0]  
             // Toma cada lista desplegable y si está oculta, toma a su elemento padre (<li>) le agrega la clase elementoLista-none. Y si está visible, le agrega la clase elementoLista-block             
                if(elementoULLI2!==undefined){
                    if(elementoULLI2.style.display=='none'){
                      var elementoPadre3=allLi2[x]                      
                      var idPadre3=elementoPadre3.id
                      var htmlPadre2=document.getElementById(idPadre3)
                      htmlPadre2.classList.add('elementoLista-none')
                      htmlPadre2.classList.remove('elementoLista-block')
                    }
                    if(elementoULLI2.style.display=='block'){
                      var elementoPadre3=allLi2[x]
                      var idPadre3=elementoPadre3.id
                      var htmlPadre2=document.getElementById(idPadre3)
                      htmlPadre2.classList.add('elementoLista-block')
                      htmlPadre2.classList.remove('elementoLista-none')
                    }
                }             
      }
      

      var elementoListaSelec= document.getElementById(elementoPadre2.id)?.getElementsByClassName('elementoLista-none')   
      //Toma todos los elementos que tienen clase elementoLista-none, es decir, las listas ocultas.

      var valueUL= ulElement2.getAttribute('value') 
      //Toma el atributo value de la lista <ul> que se desplegó.

      
      for(var v=0; v<elementoListaSelec.length; v++){
        //Toma el icono de cada padre de lista desplegable cerrada y le agrega, con insertBefore, un icono de expandir.
        var idElementoLista= elementoListaSelec[v].id
        
        var cantIconos=document.getElementById(idElementoLista)?.getElementsByTagName('img').length
        if(cantIconos==0){
          var elementoImg2 = document.createElement("img");
          elementoImg2.src=pathImg+"/arrows-angle-expand.svg"
          elementoImg2.className='iconLista'
          elementoImg2.className=elementoPadre2.id+'-img'
          elementoImg2.className=' iconLista'
          elementoImg2.id=elementoPadre2.id+'-img-'+v
          elementoImg2.setAttribute('value', parseInt(valueUL)+v)
          // elementoListaSelec[v].insertBefore(elementoImg2, elementoListaSelec[v].children[1]);  
                    
          var aTag=document.querySelector(`#${elementoListaSelec[v].id} .dfBox`)
          // elementoLista[t].insertBefore(elementoImg, aTag.children[1]);  
          aTag.appendChild(elementoImg2);   
        } 
        else{
          //Si esta lista ya tenía íconos porque fue desplegada antes, toma todos los iconos(hayan cambiado al icono "expandir", o no, y los cambia a "contraer")
         var listaIconos= document.getElementById(idElementoLista).getElementsByTagName('img')
         for(var z=0; z<listaIconos.length;z++){
             listaIconos[z].src= listaIconos[z].src.replace(/contract/g, 'expand')
         }
        }
      }
    }
   }
  
  }


 function element(event){
      //Se cliqueó un <a> que abre lista.

      colapsarListas(event)//Función que colapsa las listas que están dentro de la lista que se abrió.
      var valorTarget= event.currentTarget.getAttribute('value')
      //Obtiene atributo value del elemento <a> que se cliqueó.

      if(valorTarget!==1 && valorTarget!=='1'){
          //Se cliqueó en una caja que debería abrir una lista. Excluye los que tienen atributo value=1 porque es el primer eleento del organigrama.         
          var elementoPadre= event.currentTarget.parentNode.parentNode         
          //elementoPadre es el elemento padre del elemento cliqueado. Es un <li>
          //Blanquea variables.
          var ulElement= null
          var menorUl=null
          var menorUlelement=null
          var listaUls=document.getElementById(elementoPadre.id)?.getElementsByTagName('ul')
          //Obtiene el id del <li> padre del <a> cliqueado, y de ese <li> padre, obtiene la lista <ul> que despliega
          if(listaUls?.length){
                       //Busca dentro de los <ul> que contiene el <li> padre (que incluyen los que están dentro de otros <ul>), la lista de mas afuera (lista desplegada), a partir de sus atributos value, que son asignados por orden de aparicion en el DOM.          
            for(var z=0; z<listaUls?.length;z++){                   
                if(z==0){                  
                      menorUl= listaUls[z].getAttribute('value')!==undefined ? parseInt(listaUls[z].getAttribute('value')) : null
                      menorUlelement=listaUls[z]              
                }
                else{
                    var numVal=parseInt(listaUls[z].getAttribute('value'))
                    if(numVal< menorUl){
                      menorUl=numVal
                      menorUlelement=listaUls[z] 
                    }
                }              
            }
            
            ulElement=menorUlelement  
            //Asigna a la variable ulElement el elemento HTML de la <ul> desplegada.

            //Obtiene el id de la lista <ul> desplegada.
            var selectoIdUL='#'+ulElement.id
            //Blanquea variable de los id de los <li> hijos directos de la <ul> desplegada.
            var idLiDirecto=null

            //Obtiene los <li> hijos directos de esa lista <ul> desplegada.
            var liDirectos=document.querySelectorAll(`${selectoIdUL} > li`)
                     
          }     

          var idLiPadre=null
          if(elementoPadre!==undefined && elementoPadre!==null){
            //Obtiene el id del <li> padre
             idLiPadre=elementoPadre.id
          }

          if(ulElement!==null && ulElement!== undefined){
            //Si la lista <ul> desplegada está en display: none, entra al if y lo cambia por display:block, y aparte al <li> padre le agrega la clase elementoLista-block 
            if(ulElement.style.display=='none'){
              $('#'+idLiPadre).removeClass('elementoLista-none')
              $('#'+idLiPadre).addClass('elementoLista-block')              
                     
              var imagenLi=document.getElementById(idLiPadre).getElementsByTagName('img')
              //Obtiene todos los elementos img del <li> padre.
              if(imagenLi!==undefined && imagenLi!==null){    
                //Blanquea la variable que indica el elemento img mas de afuera.    
                var menor=null                
                var iconMenor=null               
                for(var b=0; b<imagenLi?.length;b++){ 
                  //Busca entre los elementos img del <li> padre, la img que está mas afuera, que es la primera, osea la que está al lado del elemento que se cliqueó.        
                    if(b==0){
                          menor=imagenLi[b].getAttribute('value')                      
                          iconMenor=imagenLi[b]                                      
                    }
                    else{             
                        if(parseInt(imagenLi[b].getAttribute('value')) < parseInt(menor)){ 
                          menor=imagenLi[b].getAttribute('value')
                          iconMenor=imagenLi[b]
                        }
                    }          
                }          
                //Al icono de mas afuera le cambia el atributo src para que sea el que está contraido                
                if(iconMenor!==null && iconMenor!==undefined){
                  iconMenor.src=iconMenor.src.replace(/expand/g, 'contract')
                }         
              }              
               ulElement.style.display='block'
              
              
            }
            else{ 
              //Si la lista está abierta y se cliqueó en el <a> para cerrarla, se quita la clase elementoLista.block y se agrega elementoLista-none                   
              $('#'+idLiPadre).removeClass('elementoLista-block')
              $('#'+idLiPadre).addClass('elementoLista-none')
              var imagenLi=document.getElementById(idLiPadre).getElementsByTagName('img')[0] 
              if(imagenLi!==undefined && imagenLi!==null){
                imagenLi.src=imagenLi.src.replace(/contract/g, 'expand')
              }      
              
              ulElement.style.display='none'
            }
          }
      }

 }




function posicion(str) {
    if(context.radio=='rb-ee' || context.radio=='rb-oe' ){
      return 0; 
    }//Si la opción marcada en Filtro es CFN o Orgs/Entes asigna margen=0 
    for (let i = str.length - 1; i >= 0; i--) {
      if (str[i] != 0) {
        
        return Math.ceil(i / 3);
      }
    } 

  }//Asigna margen a cada unidad a mostrar por pantalla, a partir de su cuuo.


   var ulValue=0
      
    result?.forEach(function(e, i){
        
        var numBg= e?.organigramacss? 'bg-orgma'+ e?.organigramacss : ''
        var nroUl= e.idunidadjerarquia ? 'ulNro'+ e?.idunidadjerarquia+'-'+i : ''
        var cargo=e?.idcarnivel!==3? e?.cargo ? e?.cargo: '':''
        var aut= e?.idcarnivel!==3? e?.autoridad ? e?.autoridad:'':''

        if(compare(e.cuuo)){
            if(i<1){
              //Primer elemento del array
                listaGrafico=listaGrafico+"<li id='idLi"+i+"' value='"+ulValue+"' class='resaltar' id='primero'><div class='d-flex dfBox'><a class='"+numBg+" box ' value=1 >"+e?.unidad;
                listaGrafico+="<div><small>"+cargo+' '+aut+"</small></div>"
                nivel.push(posicion(e?.cuuo))
            }
            else{
              // A partir del segundo elemento de array en adelante
              switch(true){
                  case(posicion(e.cuuo) < posicion(anterior)):
                              //Blanquea variables y cierra elemento <li> anterior
                               g=0
                               contadorCierre=0
                               listaGrafico=listaGrafico+'</a></div></li>'
                               menorAultimo=true  
                                       do{
                                        //Mientras el elemento actual sea de menor jerarquia que el numero comparado (ultimo, anteultimo, antepenultimo, etc. del array nivel), aumenta el contador de listas <ul> que se van a cerrar.
                                      
                                        if(g<nivel.length){
                                          if(posicion(e.cuuo) < nivel[(nivel.length-1)-g]){
                                              //Compara con cada elemento del array nivel desde el final hacia el principio.
                                              contadorCierre=contadorCierre+1                                    
        
                                          }
                                        }
                                          
                                        else{
                                            
                                            menorAultimo=false
                                        }
                                        
                                        g++
                                        //Aumenta la variable que se usa para ir comparando con el ultimo, anteultimo, antepenultimo, etc, elemento del array nivel con el nivel del elemento actual.
                                    }
                              while(menorAultimo==true)
        
                              for(var h=1; h<=contadorCierre; h++){
                                //Quita la cantidad de elementos del array nivel que indique la variable contadorCierre y cierra listas <ul> de acuerdo a la misma                     
                                nivel.pop()   
                                if(h<contadorCierre){
                                   listaGrafico=listaGrafico+'</ul></li>'  
                                 }                       
                              
                              }
        
                              if(posicion(e.cuuo)==nivel[nivel.length-1]){
                                  //Ahora que se quitaron los de mayor nivel del array nivel, se compara si el ultimo elemento es igual al actual y si lo es, cierra su lista y abre un nuevo elemento <li>
                                  listaGrafico=listaGrafico+'</ul></li><li id="idLi'+i+'" value="'+ulValue+'" class="resaltar M'+posicion(e.cuuo)+'"><div class="d-flex dfBox"><a value="4" class="'+numBg+' box tercero">'+e.unidad;
                                  listaGrafico+='<br/><small>'+cargo+' '+aut+'</small>'
                              }
                              else if(posicion(e.cuuo) > nivel[nivel.length-1]){
                                 //Ahora que se quitaron los de mayor nivel del array nivel, se compara si el ultimo elemento es mayor al actual y si lo es,  abre un nuevo elemento <li> y pone al final del array nivel el nivel actual.
                                  listaGrafico=listaGrafico+'<li id="idLi'+i+'" value="'+ulValue+'" class="resaltar M'+posicion(e.cuuo)+'"><div class="d-flex dfBox"><a value="4" class="'+numBg+' box tercero">'+e.unidad;
                                  listaGrafico+='<br/><small>'+cargo+' '+aut+'</small>'
                                  nivel.push(posicion(e.cuuo))
                                  
                              }
                              else if(nivel.length==0){
                                //Si se quitaron todos lo elementos del array nivel, se agrega el actual.
                                nivel.push(posicion(e.cuuo))
                                
                              }
                
        
                  break;
                  case(posicion(e.cuuo) == posicion(anterior)): 
                     //El elemento actual es de igual nivel que el anterior, agrega una nuevo <li> 
        
                    listaGrafico=listaGrafico+'</a></div></li><li id="idLi'+i+'" value="'+ulValue+'" class="resaltar M'+posicion(e.cuuo)+'"><div class="d-flex dfBox"><a value="3" class="'+numBg+' box segundo">'+e.unidad;
                    listaGrafico+='<br/><small>'+cargo+' '+aut+'</small>';

                    break;
        
                  case(posicion(e.cuuo) > posicion(anterior)):
                     //El elemento actual es mayor nivel que el anterior, lo agrega al array nivel y abre una nueva lista y agrega el elemento <li>.
        
                      listaGrafico=listaGrafico+'</a></div><ul class="ulUnidades" value="'+i+'" id="'+nroUl+'"><li id="idLi'+i+'" value="'+ulValue+'" class="resaltar M'+posicion(e.cuuo)+'"><div class="d-flex dfBox"><a value="2" class="'+numBg+' box primero">'+e.unidad+'<br/><small>'+cargo+' '+aut+'</small>'
                      nivel.push(posicion(e.cuuo))              
                      break;
              }
        
            }      
              anterior= e.cuuo
            }
          else{
            listaGrafico+= e.idcarnivel!==3?'<br/><small>'+cargo+' '+aut+'</small>' : '';  
          } 
         
        })

 for(var f=1; f< nivel.length; f++){
    //Cierra todas las listas que quedaron abiertas segun el array nivel al finalizar el ciclo.
    listaGrafico=listaGrafico+'</li></ul>'
  } 
    
  listaGrafico=listaGrafico+'</li>'
  


  
  

function contenido(){  
  return {__html: listaGrafico};
}


    return (
      <>
        <Row className="my-1 mx-1">        
            <Col lg={12}>
                <ul className="pl-0 tree" id="tree" dangerouslySetInnerHTML={contenido()}>
                </ul>              
            </Col>    
        </Row>
      </>
  );   
  //imprime en pantalla cada unidad de la jurisdiccion consultada.

}

export default OrganigramaGrafico;