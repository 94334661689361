import {useState, useContext, useEffect } from "react";
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import Context from '../context';
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import NormativaBox from './normativaBox'
import BusquedaLateral from './busquedaLateral'
import {FaSortDown} from 'react-icons/fa'
import {FiDelete} from 'react-icons/fi'
import {HiAdjustments} from 'react-icons/hi'
import {filtroPH} from '../../contenido/normativaFiltros'


function NormativaDatos(props) {
  

const [searchValue, setSearchValue]=useState('')
const [arrayBusqueda,setArrayBusqueda]=useState([])
const [resultado, setResultado]=useState(false);
const context=useContext(Context);
const [showMore, setShowMore]=useState(20)
const history=useHistory()

useEffect(()=>{ 
  context.setMsj3('dispnoneMsj')
  context.setMsj4('dispnoneMsj')
},[])

function createDelete(){
  
  var filtro= document.querySelector('#inputbuscarNorma');  
    if(filtro.value!==undefined){
      if(filtro.value!==''){
        document.querySelector('#delete').style.display="inline";
      }
      else{
        document.querySelector('#delete').style.display="none";
      }      
  }
  else{
    document.querySelector('#delete').style.display="none";
  }
  return false;
}

function remove(){ 
  //Vuelve a vacío el input de busqueda de texto.
  var filtro= document.querySelector('#inputbuscarNorma');
  filtro.value="";
  var remove=document.querySelector('#delete');
  remove.style.display="none";
  setSearchValue('')
  setArrayBusqueda([])
  setResultado(false)
}


var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','ü':'u', 'Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};

function accent_fold (s) {
//Función que reemplaza letras con acento por letras sin acento.
  if (!s) { return ''; }
  var ret = '';
  for (var i = 0; i < s.length; i++) {
	ret += accent_map[s.charAt(i)] || s.charAt(i);
  }
  return ret;
}

const changeBusqueda=(e)=>{
  //Cambia los resultados de acuerdo al texto buscado.
  createDelete()
  const valor=e.target.value; 
  setSearchValue(valor);
  if(valor===''){
      setResultado(false)
  }
  else{ 
    //Si el input de busqueda de texto es distinto a vacío 'resultado' es true y se buscan coincidencias con context.normDartos usando un ciclo for. 
      const arraySearch=[] 
      setResultado(true)  

      for(let norma of context.normDatos){
        const normTexto=()=>{
          if(norma?.abstract){
            return norma.detalle
          }
          else{
            return norma.abstract
          }
        }
          // let normaTexto=norma.abstract!==undefined || norma.abstract!==null ? norma.abstract:norma.detalle
          const texto=normTexto()+' '+norma?.jurisdiccion +' '+norma?.norma_tipo+' '+' '+norma?.numero+' '+norma?.fechapub+' '+' '+norma?.tags    
        
          if(accent_fold(texto).toLowerCase().indexOf(accent_fold(valor.toLowerCase()))!== -1){
              const result=norma;             
              arraySearch.push(result)
          }
      }
      setArrayBusqueda(arraySearch)
      //Agrega al array arrayBusqueda los elementos de context.normDatos(resultado de la API) que coinciden con la búsqueda. jgm
  }
}



function toggleOculto(){ 
  //Muestra u oculta los filtros de búsqueda del lateral(Para mobile) 
   $('#bLateral').toggleClass("oculto");   
}



  return (
  <>  
  <Container className="normativaWrap  px-0">
          {
          context.normDatos?.length!==0 ?
          <>
          {context.titulo!==null?
           <div id="tituloNormativas" className={`${props.des==='s'&& 'bg-claro02'} d-flex py-2 my-3 pl-2 tituloNormativa align-items-center`}>  
           <div id="tituloTexto" className="px-1">{context.titulo}</div>
          {
          context.normDatos &&
                <div className="vermenu py-2 mx-2 d-flex ft-white">
                <HiAdjustments alt="Ajustar filtros de búsqueda" style={{color: "#FFF"}} onClick={toggleOculto} className="ft-white" size={20}/>
              </div>
          }
         </div>
         :
         <> 
          <div  id="tituloNormativas" className="d-flex py-2 my-3 pl-2 tituloNormativa align-items-center">  
          <div id="tituloTexto" className="px-1">Normativas por Jurisdicción</div>
          </div>
         </>
          }
          </>
          :
          <></>
        }

    <Row className="justify-content-between rowNormDatos">         
       <Col xl={3}>   
       <div  id="bLateral" className="filtrosNormativa pt-0 px-0">         
         <BusquedaLateral jur={props.jur}/>
       </div>
      </Col>
      <Col xl={9} id="resultadosCol" className="pl-4">
        {context.loading==true? 
        <div className="text-center py-5">
          <Spinner  animation="border" size="md" style={{color: "#9fb7cf"}}/>          
          </div>
          :
           <></>}
         {context.normDatos!==undefined && context.normDatos.length!==0 ?
          <>
            {context.resultados !== '' &&
              <>
                <div className={`${props.des==='s'&& 'bg-claro03'} resNormativas`}>
                  <strong>Resultados: {context.resultados}</strong><br/>
                  <strong id="titles">{context.titleSearch?.slice(0,context.titleSearch?.length-2)}</strong>                  
                  <Row className="my-3 mx-0">
                    <Col className="pl-0" xl={12}>
                      <div id="buscarNorma">
                      <input id="inputbuscarNorma" onChange={changeBusqueda} name="buscar" type="text" placeholder={filtroPH} />
                      <div id="delete">
                        <FiDelete onClick={remove}/>
                      </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            }
            {resultado==true? 
            <>    
                {
                arrayBusqueda?
                <>
                  <h4 className="resSearch my-3">
                     Resultados para <strong>{searchValue}</strong>: {arrayBusqueda.length}
                  </h4> 
                   {arrayBusqueda?.slice(0,showMore).map((normativa,indice)=>{
                    if (indice==arrayBusqueda.slice(0,showMore).length-1){
                      context.hideLoader()
                    }
                      return(
                        <NormativaBox key={indice} normativa={normativa} indice={indice} arrayTag={normativa.tags.split('#')}/>    
                      )
                      }
                  )}
                </>
                :
                <>
                  <h4 className="resSearch my-3">Sin resultados para la búsqueda <strong>{searchValue}</strong></h4>
                </>
                }
            </>
            :
            <>
                {resultado==false &&
                  <>
                    {context.normDatos.slice(0,showMore).map((normativa,indice)=>{
                        if (indice==context.normDatos.slice(0,showMore).length-1){
                          context.hideLoader()
                        }
                        return(
                           <NormativaBox key={indice}
                            normativa={normativa} indice={indice} 
                            arrayTag={normativa.tags.split('#')}/>    
                        )
                        }
                    )}
                  </>
                }
            </>
            }
          </>
          :
          <div className="text-center py-5">
            <Spinner animation="border" size="md" style={{color: "#9fb7cf"}}/>            
          </div>
          }
          {resultado==true ?
          <>
              {(arrayBusqueda?.length>0) && (showMore<arrayBusqueda?.length)&&
                <Row className="showmore justify-content-center"
                 onClick={()=>setShowMore(showMore+20)}>
                  <p>
                    <FaSortDown/> Mostrar más
                  </p>                                
                </Row>
              }
          </>
            :
            <>
             {showMore < context.normDatos?.length ? 
                  <Row className="showmore justify-content-center" onClick={()=>setShowMore(showMore+20)}>
                      <p><FaSortDown/> Mostrar más</p>                                
                  </Row>
              : 
              null}
           </>                        
          }
      </Col>    
    </Row>
  </Container>
  </>
  )  
}


export default NormativaDatos;