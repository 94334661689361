
function InfoSerie(props){

    const galery=props.galery
    const serie=props.serie
    
    return(
        <div className="d-flex bg-white px-2 py-3 justify-content-start">
            <div className="px-3 iconoSerie">
                    <i className={galery.icono}/>
            </div>
            <div lg={8} sm={7} className="px-0 infoSerie">
                <p><strong>Período:</strong> {serie.periodo}</p>
                <p><strong>Frecuencia: </strong>{serie.frecuencia}</p>
                <p><strong>Fecha de publicación: </strong>{serie.fpublicacion}</p>
                <p><strong>ID:</strong>{serie.idInforme}</p>          
            </div>                                    
        </div>
    )
}

export default InfoSerie;