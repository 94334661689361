
import {FaRegFilePdf, FaDownload} from 'react-icons/fa'
import {Row} from 'react-bootstrap'

function DescargaArchivos(props){


    const metaGalery=props.metaGalery
    const pathInformes=props.pathInformes

    
    return(

        <Row className="mt-5 mx-0">
                    {metaGalery?.informe !== null &&
                            <button className="mr-3 botonGaleria buttonsClass bg-dark">
                                <a rel="noopener noreferrer" href={`${pathInformes}/${metaGalery?.informe}`} target='_blank'>
                                <div className="d-flex align-items-center">
                                <FaRegFilePdf className="pr-1"  /> Descargar Informe Completo
                                </div>
                                </a>
                            </button>
                    }
                    {metaGalery?.datos !== null &&
                        <button className="botonGaleria buttonsClass bg-dark">
                            <a rel="noopener noreferrer" href={`${pathInformes}/${metaGalery?.datos}`} target='_blank'>
                            <div className="d-flex align-items-center">
                                <FaDownload className="pr-1"/>  Descargar Archivos de Datos
                            </div>    
                            </a>
                        </button>}
        </Row>
    )
}

export default DescargaArchivos;