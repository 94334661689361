import { useEffect } from 'react'
import {Col} from 'react-bootstrap'


function MasAutoridades(props){

    const formatearFecha=(fecha)=>(`${fecha?.slice(8,10)}-${fecha?.slice(5,7)}-${fecha?.slice(0,4)}`)

  

    return(
        <div id="masAutoridades" >
            {props.aut2?.map((a2,i)=>{

             let arrayDesigs=a2?.ndesignacion!==null? a2?.ndesignacion.split(";"):null

             return(
                 <div className={`${i==0?'borderTop':''} py-2   d-flex aut2p my-2 ${i<props.aut2.length-1?'aut2Border':''} justify-content-between`} key={i}>
                    <Col className="px-2" xl={6} lg={5} md={5} sm={5} xs={5}>
                    <p className="mb-0"><strong>{a2?.cargo} {a2?.autoridad!==null? a2?.autoridad?.toUpperCase():''}</strong></p>
                    </Col>
                   
                    <Col className="px-2" xl={3} lg={3} md={3} sm={3} xs={3}>
                     {arrayDesigs!==undefined && arrayDesigs!==null && arrayDesigs.length!==0 ?
                        
                         <div >
                                {arrayDesigs?.map((ndesig2,i)=>{

                                let linkDesig2= ndesig2.slice(ndesig2.indexOf('('),ndesig2.indexOf(')')).replace(/\(/g,'')
                                
                                return(
                                <div id="divNormas ">
                                    {ndesig2?.indexOf('(')!==-1&& linkDesig2!==''&&linkDesig2.slice(0,4).toUpperCase()==="HTTP"?
                                    <a rel="noopener noreferrer" className="aDesig" target="_blank" href={linkDesig2}>
                                    <p className="mb-0">
                                        {ndesig2.slice(0, ndesig2?.indexOf('(')).replace(/\[/g,'').replace(/\]/g,'')}
                                    </p>
                                    </a>
                                    :
                                    <p className="mb-0  sinLink">
                                    {ndesig2.slice(0, ndesig2?.indexOf('(')).replace(/\[/g,'').replace(/\]/g,'')}
                                    </p>
                                    }
                                </div>
                                )
                                }
                                )}
                            
                        </div>
                        :
                        <p>Sin información</p>
                        }
                     </Col>
                     <Col className="px-2" xl={2} lg={2} md={2} sm={3} xs={3}>
                      <p>{a2?.aut_falta!==null?formatearFecha(a2?.aut_falta):''}</p>
                     </Col>
                </div>  
              )
             })
            }
        </div>
    )
}

export default MasAutoridades;