import { useEffect, useContext } from "react";
import {useHistory} from "react-router-dom";
import {headTitulo, headVolanta} from '../contenido/normativas'
import {Container, Row, Spinner} from 'react-bootstrap'
import Context from '../components/context';
import {pathBack} from '../components/paths'
import '../css/styles.css'
import Header from '../common/header'
import NormativaBA from "../components/normativas/normativaBA";
import NormativaPanel from '../components/normativas/normativaPanel'
import NormativaDatos from '../components/normativas/normativaDatos'
import DesignacionesFiltros from "../components/normativas/designacionesFiltros";
import {sinResultados} from "../contenido/normativas"


function Normativas(props) { 


  function volver(){
    context.setMsj3('dispnoneMsj')
    context.setMsj4('dispnoneMsj')
    //Vuelve a la pantalla anterior y vacía los valores de los filtros de búsqueda.
    sessionStorage.removeItem('jurOrganismos')
    sessionStorage.removeItem('tadmOrg')  
    sessionStorage.removeItem('jurNor')  
    sessionStorage.removeItem('minNorm')
    context.resetValues();
    context.tipoNormativa(context.TN)  
  } //Vuelve a la página de normativas después de una búsqueda sin resultados.
  


const history=useHistory()

 useEffect(()=>{
 
  context.setResBusqueda(null)
  context.setearRadio('rb-comp') 
  sessionStorage.setItem('radio', 'rb-comp')
  context.setearTitleDoc('Mapa - Normativas')
  context.setearE404(false)
   //Cuando se abre Normativas se borran los valores seleccionados para Designaciones por jurisdicción y Normativas por jurisdicción.
  sessionStorage.removeItem('nombreJur')
  sessionStorage.removeItem('jurNor')

  if(context.TN!==undefined&&context.TN!=='JurDes'&&context.TN!=='Jur'){
    if(sessionStorage.getItem('pathNormativa')!==undefined&&sessionStorage.getItem('pathNormativa')!==null){
     if(!context.normDatos.length){
      context.fetchNormativas(pathBack,sessionStorage.getItem('pathNormativa'))
      if(context.dataError==0){
        history.push('/database_error')
       }
     } 
    }
    else{     
      context.tipoNormativa(context.TN)
      if(context.dataError==0){
        history.push('/database_error')
       }
    }
   
  }
  else{    
    sessionStorage.removeItem('pathNormativa')
    context.tipoNormativa('ba')
  }//Si context.TN (tipo de normativa) es distinto de undefined, pide datos a la api correspondientes a ese tipo de normativas. Si, además, pathNormativa (url que contiene los parámetros de la consulta) es distinto de undefined, pide los datos usando esa url. Si context.TN es undefined, pide datos de Ley de Ministerios. 
   if(context.dataError==0){
      history.push('/database_error')
    }
  //  context.pushBread('Normativas', '/normativas', 1)   
   context.setearBreadCrumb([{label:'Home',url:"/", nivel:0},
  {label:'Normativas' , url:'/normativas', nivel:1}])

 },[])
 


  const context=useContext(Context);

  return (
  <>
  <Header h1={context.TN==='df' ? 'Designaciones': headTitulo} num={context.TN==='df'?20:20} txt={headVolanta}/>
  <Container fluid id="container-normativas">
    <NormativaPanel />
    {context.TN=='ba' &&
        <>
          {!context.Datos?
            <>      
              {context.buscadorHztal==true&& <NormativaBA />}
            </>
          :
            <></>
          }
          {/* Pregunta si no hay datos para mostrar. Si no hay, muestra el buscador de arriba */}
       </>
    }
    {context.TN=='df' || context.TN=='de' ?
    <>
      {!context.Datos && context.filtrosDesig==true &&
        <DesignacionesFiltros/>
      }
    </>
     : 
     <>
     
     </>
     }
    {/* Pregunta si está en la vista 'Designaciones'('de' y 'df' pertenecen a Designaciones). Si es cierto, muestra el buscador de Consultas de Designaciones de arriba */}

    {context.Datos ?
      <>
      {context.sinRes ?
         <>
          <div className="my-4 alert alert-warning">
            {sinResultados}<br/>
            {context.titleSearch}
            <div className="py-2 volver" onClick={volver}>
               Volver
            </div>
          </div>        
         </>
       :
        <>
         {context.normDatos && context.normDatos?.length!==0 ?
         <NormativaDatos des={context.TN==='df' ? 's': 'n'}/>
         :
          <Row className="justify-content-center py-5">
            <Spinner animation="border" size="md" style={{color: "#9fb7cf"}}/>
          </Row>
           }
         </>
      }
      
      </> 
      : 
      <>
      {sessionStorage.getItem('pathNormativa')&&
      <>
      {  context.TN!=='ba' || context.TN!=='df'||context.TN!=='de'?
      <Row className="justify-content-center py-5">
        <Spinner animation="border" size="md" style={{color: "#9fb7cf"}}/>
      </Row>   
       
       :
       <></>
       }
      </>
      }
       </>
      }
      
         {/* Pregunta si hay datos para mostrar. Si hay, si los resultados de la busqueda son =0, muestra el cartel de Sin resultados, si son mas de 0, pregunta si el array que guarda los datos (normDatos) 
      es =undefined, si no es, renderiza <NormativaDatos/>, si es =undefined muestra "Sin resultados" */}
   
  </Container>

  </>
  )
}

export default Normativas;