import {label_desde, label_hasta} from '../contenido/normativaFiltros'
import {Row, Col} from 'react-bootstrap'
import { useEffect, useState, useContext } from "react";
import $ from 'jquery';
import Context from '../components/context';
import { CgClose } from "react-icons/cg";


function Fecha(props) {

const [fechaHoy, setFechaHoy]=useState(null)
const context=useContext(Context); 

function fechaActual(){
    
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth()+1;
    let year = date.getFullYear()
    
    if(month < 10){
      setFechaHoy(`${year}-0${month}-${day}`)
    }else{
      setFechaHoy(`${year}-${month}-${day}`)
      
}
}


function tagsFechas(){
  
  //Etiqueta de Fecha Desde
  if(context.desde!==null && context.desde!=='' && context.desde!==undefined){

    $('#idFrom').hide();
    $('#tagFrom').show();
    $('#cerrarTag5').click(function() {
      context.pushParam([['','fdesde'],'fdesde'], true)
    $('#idFrom').show();
    $('#tagFrom').hide();
    context.resetDesde();
    
  });
  }
  else{
      $('#tagFrom').hide();
  }
    if (context.desde==null){
      $('#tagFrom').hide();
    }

  //Etiqueta de Fecha Hasta
  if(context.hasta!==null && context.hasta!=='' && context.hasta!==undefined){
    $('#idTo').hide();
    $('#tagTo').show();
    $('#cerrarTag6').click(function() {
      context.pushParam([['','fhasta'],'fhasta'], true)
    $('#idTo').show();
    $('#tagTo').hide();
    context.resetHasta()
    
  });
  }
  else{
      $('#tagTo').hide();
  }


}
 
      useEffect(()=>{
        
            fechaActual();
            tagsFechas();
      },[])
   


   return (
 
    <div className="fechasRow  fechaJustf " >
    <div className={`${props.Lateral==='s'? 'marginBottomLateral':''} fechas ui-widget px-0 `} id="fechaDesde"  >
          <div id="tagFrom" className="tagSelect bg-shadow">            
            Desde: {context.desde!==null&&context.desde!==undefined&&context.desde}<span id="cerrarTag5"><CgClose/></span>          
          </div>
        <div id="idFrom">
          <label for="from" className="mb-1 ft-grey">{label_desde}</label><> </>
          <input name="from" max={fechaHoy} type="date" id="from" className="form-control" value={context.desde} onChange={fecha=>context.setearDesde(fecha.target.value)}/>
        </div> 
    </div>	
    <div className="fechas ui-widget px-0 " id="fechaHasta"  >
           <div id="tagTo" className="tagSelect bg-shadow">            
              Hasta: {context.hasta && context.hasta}<span id="cerrarTag6"><CgClose/></span>           
           </div>	
        <div id="idTo">
        <label for="to" className="mb-1 ft-grey">{label_hasta}</label><> </>
        <input className="form-control" name="to" min={context.desde} max={fechaHoy} type="date" id="to" value={context.hasta} onChange={fecha=>context.setearHasta(fecha.target.value)}/>
        </div>        
    </div>	
    </div>
 
   )
}

export default Fecha;    