import {label_n1} from '../../contenido/normativaFiltros'
import { useEffect,  useContext, useState } from "react";
import {pathBack} from '../paths'
import Context from '../context';
import {db} from '../../../configuracion/environment'
import axios from 'axios'

function SelectJurisdiccion(props) {

    const context=useContext(Context);
    const pathJur=`db=${db}&id=4&tl=vi`
    const [listajuris, setListaJuris]=useState([])

      useEffect(()=>{
        if(listajuris.length==0){
          axios.post(pathBack, pathJur)
          .then((res)=>{            
            setListaJuris(res.data?.datos)
          })
          .catch(e=>{console.log(e)})         
        } 
      },[])
   

   return (
   <>
     <select onChange={e=>context.pushParam([e.target.value.split('/'),'n1'],false)} id="n1" name="n1" className='form-control input-lg '>
          <option value={`${''}/${'nUno'}`}>{label_n1}</option>
          {listajuris?.map((jurisdiccion,index)=>{
          return(
            <option key={index} value={`${jurisdiccion?.n1}/${jurisdiccion?.nombre_corto}`}>{jurisdiccion.unidad}</option>
          )
          }            
          )}
      </select>
  </>
   )
}

export default SelectJurisdiccion;  