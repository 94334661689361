import {fs1_p01, fs1_p01a, fs1_p03, fs1_a01, fs1_p02, pathJgm} from '../contenido/footer'
import {Container, Row, Col} from 'react-bootstrap'
import {pathImg, pathBack} from '../components/paths' 
import { db } from '../../configuracion/environment'
import { useEffect,useState, useContext} from 'react'
import Context from '../components/context';
import axios from 'axios'
import { useHistory } from "react-router-dom";

function Footer(props){

    const context=useContext(Context);   
    const [paramsFooter,setParamsFooter]=useState(null)
    const history= useHistory();
    
    function fetchFooterData(){
        axios.post(context.footerPath,`db=${db}&id=4`)
        .then(res=>{ 
            if(res.data?.metadatos && res.data?.metadatos[0]?.connect==0){
                history.push('/database_error')
            }
            else{     
                setParamsFooter(res?.data?.parameters && res.data?.parameters[0])
            }
        }) 
        .catch(error=>{
            console.log(error)
        }) 
    }


    useEffect(()=>{        
        if(!paramsFooter){
           fetchFooterData()
        }
    },[context.footerPath])


    return(    
        <footer>        
            <div className="cntFooter">                
                <div className="mb-3 mx-0 d-flex">
                    <div className=" col-xs-offset-1 versionMapa">
                        <br/>                    
                        <p>
                            {paramsFooter?.footer_line_1 ? paramsFooter?.footer_line_1 : ''}
                        </p>
                        <p>
                            {paramsFooter?.footer_line_2 ? paramsFooter?.footer_line_2 : ''}
                        </p>
                        <p>
                            {paramsFooter?.footer_line_3}
                            <a href={`mailto: ${paramsFooter?.mail? paramsFooter?.mail : ''}`}>    {paramsFooter?.mail_label}
                            </a>
                        </p>
                        <p><small>{`${fs1_p02}${paramsFooter?.version ? paramsFooter?.version : ''}`}</small></p>
                    </div>	
                </div>
                <div class="py-4">
                    <div className="align-items-center footerDisplay mx-0 justify-content-between cont_firmas">
                        <div className=" text-left py-4 firmaMapa">
                            <a rel="noreferrer" href={paramsFooter?.firma1_path} target="_blank">                     
                                <p className="firma">{paramsFooter?.firma1}</p>
                            </a>
                            <a rel="noreferrer" href={paramsFooter?.firma2_path} target="_blank">
                                <p className="firma">{paramsFooter?.firma2}</p>
                            </a>              
                        </div>
                        <div id="logoJgm" >
                            <a rel="noreferrer" href={paramsFooter?.firmaj_path} target="_blank">
                                <img src={`${pathImg}/${paramsFooter?.firmaj_img}`} alt={paramsFooter?.firmaj_alt} />
                            </a>  
                        </div>
                    </div>
                </div>             
            </div>
            
            <div class="py-3 subfooter">
                <Container className="mb-4">
                    <Col id="claim" className="col-xs-10 col-md-6 col-lg-4 pl-0">
                        {/* <img src={`${pathImg}/claim.png`} alt="Primero la gente" className="img-fluid mt-3" /> */}
                    </Col>
                </Container>
            </div>
        </footer>
    )
}

export default Footer;