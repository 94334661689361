import {Link} from 'react-router-dom';
import { useEffect, useState, useContext} from "react";
import {Container, Row} from 'react-bootstrap'
import {pathBack} from '../paths'
import axios from 'axios'
import {db} from '../../../configuracion/environment'
import Context from '../context';
import {useHistory} from 'react-router-dom'
import { Redirect } from 'react-router-dom';

function Composicionpen(props){


const context=useContext(Context);
const history=useHistory();


function redirectOrg(idVal){
   switch(idVal){
       case 2: context.setTadmOrg(`${context.arrayNumeros[1].descripcion}/2`.split('/'))
       sessionStorage.setItem('tadmOrg', JSON.stringify(`${context.arrayNumeros[1].descripcion}/2`.split('/')))
       break;
       case 3: context.setTadmOrg(`${context.arrayNumeros[2].descripcion}/3`.split('/'))
       sessionStorage.setItem('tadmOrg', JSON.stringify(`${context.arrayNumeros[2].descripcion}/3`.split('/')))
       break;    
       case 4 :context.setTadmOrg(`${context.arrayNumeros[3].descripcion}/espn`.split('/'))
       sessionStorage.setItem('tadmOrg', JSON.stringify(`${context.arrayNumeros[3].descripcion}/espn`.split('/')))
   }
}


    useEffect(() => {        
        if(context.arrayNumeros?.length===0){        
          context.getNumeros();         
        }
    }, [])

    
return(
    <>
        <div  className="py-5 bg-secondary cntFlexHome">
            <div className="contenedor_items">
                <h3 className="tituloseccion mx-1 ">Composición del Sector Público Nacional</h3>
                <Row className="justify-content-center mx-0">            
                    {               
                        context.arrayNumeros?.length!==0?
                    <>   
                { context.arrayNumeros?.slice(0,4).map((item, i)=>{        
                        return(  
                            <Link key={i} className="px-0 col-12 col-md-6 col-lg-3 align-self-center d-inline" to={item?.id ===1 ? "/ministerios" : "/organismos"}>
                                <div key={i} onClick={()=>redirectOrg(item?.id)} className={`px-3 mx-1 mb-3 boxPortalNumeros3 ${i%2 === 0? "bg-09" : "bg-10"}`}>
                                <div id={"id"+item?.id} className="textocaja py-4">
                                    <h3>{item?.cantidad}</h3>                                
                                    <p>{`${item?.descripcion}${i!==context.arrayNumeros.length-1? ' *':' **'}`}</p>
                                </div>
                                </div>
                            </Link>
                        )
                    }
                    )}           
                    </>
                    :
                    null
                    }            
                </Row> 
                <div className="bajadaCPEN">
                    {context.bajada?.length?
                        <>
                        {context.bajada?.map((parrafo,i)=>{
                            return(
                            <p className=" font-Encode ">
                                {parrafo}
                            </p>)
                            } )
                        } 
                        </>
                        :
                        <></>
                    }        
                </div>   
            </div>
        </div>
    </>
)
}

export default Composicionpen;