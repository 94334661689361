import {FaRegFilePdf, FaDownload} from 'react-icons/fa'
import {Container, Row,  Col, Spinner} from 'react-bootstrap'
import {pathDatos } from '../paths'
import InfoSerie from './infoSerie'
import DescargaSerie from './descargaSerie'

function UnidadSerieDatos(props){

    
    const serie= props.serie
    const galery=props.galery

    const fPubInforme=serie?.fpublicacion.replace('-','').slice(0,6)

    const hrefInforme= `${pathDatos}/${serie?.codigo}/${fPubInforme}/${serie?.informe}`   
    const hrefArchivo= `${pathDatos}/${serie?.codigo}/${fPubInforme}/${serie?.datos}`



    return(
        <Col  lg={4} sm={12}  className="sCard  my-2 px-2 ">
                <div className="bg-white pb-2">

                    <InfoSerie serie={serie} galery={galery}/>

                    <DescargaSerie hrefArchivo={hrefArchivo} hrefInforme={hrefInforme} serie={serie}/>

                </div>
        </Col>
    )
}
             
export default UnidadSerieDatos;