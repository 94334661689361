import { useEffect, useContext } from "react";
import '../../css/styles.css'
import {Link} from 'react-router-dom'
import Context from '../context';
import {IoInformationCircleOutline} from 'react-icons/io5'
import { useParams} from "react-router-dom";

function Unidad_Escalar(props) {
 // Devuelve una unidad del organigrama escalar, compuesta por: el item con su color correspondiente según el tipo de administración; nombre de la unidad; nombre del cargo seguido del nombre de la autoridad. Si el cuuo del elemento actual es igual al cuuo del elemento anterior, no muestra el nombre de unidad, solo el cargo y la autoridad.
 const param=useParams()
    const context=useContext(Context);
    const item=calcReferencia(props.item-1)

useEffect(()=>{
 
      context.setearUsperior(props.idu? props.jur?.replace(/\s+/g, '-'):props.dato.jurisdiccion?.replace(/\s+/g, '-'))
    },[props.jur])

function calcReferencia(num){

    if(num>=4){ return 3}
    else return num
}


    function posicion(str) {
      
        if(context.radio=='rb-ee' || context.radio=='rb-oe' ){
          return 0;
        }//Si la opción marcada en Filtro es CFN o Orgs/Entes asigna margen=0 
        for (let i = str.length - 1; i >= 0; i--) {
          if (str[i] != 0) {
            return (Math.ceil(i / 3));
          }
        }        
      }//Asigna margen a cada unidad a mostrar por pantalla, a partir de su cuuo.

       if (props.compare==true){
         return(
          
            <>{ posicion(props.dato.cuuo)==1 || posicion(props.dato.cuuo)==0 ?
              <>
    
            <Link className="aEscalar" to={`../${props.tipo==='org'?'organismos':'ministerios'}/${context.paramUsuperior}/${props.dato.idunidad}/detalle`}> 

            <li className={`posicion-${posicion(props.dato.cuuo)} mt-2 liEscalar d-flex`}> 
             <div className="d-block">              
            {context.radio!=='rb-ee' && context.radio!=='rb-oe' ?
                 
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center"> 
                    <div className={`item${item} mr-2 escalarItem`}></div>
                  </div>
                  <p className="aut1 titulo_unidad my-0">{props.dato.unidad}</p>
                </div>
               :
               <div className="d-flex align-items-center">
                  <div className={`item${item} mr-2 escalarItem`}></div>
                  <p className="titulo_unidad my-0">{props.dato.unidad}</p>
               </div>
            }
            
             <p className="my-0 aut autEscalar"><strong>{props.dato.cargo}</strong> {props.dato.autoridad}</p>
             <div className="masInfo ">[+Leer más]</div>
            </div>
             <div className="d-flex align-items-center">
                 <IoInformationCircleOutline className="iconEsc" size={'1.5em'}/>
                 </div>
                
            </li>
            </Link>
            </>
            :  
            <Link className="aEscalar" to={`../${props.tipo==='org'?'../organismos':'../ministerios'}/${context.paramUsuperior}/${props.dato.idunidad}/detalle`}>
              
            <li className={`posicion-${posicion(props.dato.cuuo)} liEscalar mt-2 d-flex`}>  
                 <div className="d-block">
                    <div className="d-flex align-items-center ">
                      <div className="d-flex align-items-center"> 
                        <div className={`item${item} mr-2 escalarItem`}></div>          
                      </div>
                      <p className="titulo_unidad my-0">{props.dato.unidad}</p>
                    </div>  
                    <p className="my-0 aut autEscalar"><strong>{props.dato.cargo}</strong> {props.dato.autoridad}</p>
                    <div className="masInfo">[+Leer más]</div>
                 </div>
                 <div className="d-flex align-items-center">
                 < IoInformationCircleOutline className="iconEsc" size={'1.5em'}/>
                 </div>
                
             </li>
             </Link>
             }
             </>
              )
       }
       else {
         return(
           <div className="autEscalar2 d-flex">
             <p className={`posicion-${posicion(props.dato.cuuo)} aut my-1`} ><strong>{props.dato.cargo}</strong> {props.dato.autoridad}</p>
           </div>
         )
       }
      }
    
export default Unidad_Escalar;