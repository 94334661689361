import './App.css';
import { ReactDOM } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useEffect, useContext, useState} from "react";
import {BrowserRouter,Route, Switch, Link, useHistory} from "react-router-dom";
import Home from "./front/pages/home"
import Ministerio from "./front/pages/ministerio"
import Organismo from "./front/pages/organismo"
import GlobalState from './GlobalState'
import {db} from './configuracion/environment'
import Ministerios from './front/pages/ministerios'
import Menu from './front/common/menu'
import Footer from './front/common/footer'
import './front/css/styles.css';
import $ from 'jquery';
import {FaAngleUp} from 'react-icons/fa'
import {pathImg,pathBack} from './front/components/paths'
import {FiMenu} from 'react-icons/fi'
import NormativasDesig from './front/components/normativas/normativasDesig'
import NormativasEstructura from './front/components/normativas/normativasEstructura'
import Organismos from './front/pages/organismos'
import Normativas from './front/pages/normativas'
import Breadcrumb from './front/common/breadcrumb'
import Busqueda from './front/pages/busqueda'
import Datos from './front/pages/datos'
import DatosGaleria from './front/pages/datosGaleria'
import FichaUnidad from './front/pages/fichaUnidad'
import PaginaNoDisponible from './front/components/paginaNoDisponible';
import Context from './front/components/context';
import Doc from './doc.js'
import DatabaseError from './front/components/database_error';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios'
import Grafico from './front/grafico/grafico'




function App() {

  const context=useContext(Context);
  const history=useHistory
  const c='VISTO'
 
  useEffect(()=>{

    
    $(window).on("scroll", function() {   
      if($(window).scrollTop() > 100){
        $("#franja1").css('top','0px')
        $("#franja1").css('position', 'sticky')
        $("#franja1").css('z-index', '100')
        $("#franja1").css('height', 'auto')
        $("#franja1").css('padding', '10px 0px')
        $("#franja1").css('padding', '10px 0px')
        $("#isologo").css('display','inline-block')
        $("#fimenu2").css('display','inline-block')
        $('#breadcrumbNav').css('display','none') 
        $("#franja1").addClass('bg-shadow2')

      }
      else if ($(window).scrollTop() < 100){
        $("#franja1").css('height', 'auto')
        $("#franja1").css('position', 'static')
        $("#franja1").removeClass('bg-shadow2')
        $("#isologo").css('display','none')
        $("#fimenu2").css('display','none')
        $('#breadcrumbNav').css('display','inline-block')
        $('#commonMenu2').removeClass('menu-common-fixed');
      }
     
      
    }
    );
    $(window).on("scroll", function() {
      $('#commonMenu2').removeClass('menu-common-fixed');
    })



       $(document).ready(function(){

        $('.ir-arriba').click(function(){
          $('body, html').animate({
            scrollTop: '0px'
          }, 300);
        });

        $(window).scroll(function(){
          if( $(this).scrollTop() > 0 ){
            $('.ir-arriba').slideDown(300);
          } else {
            $('.ir-arriba').slideUp(300);
          }
        });

});
 }, [])

 const toggleMenu3=()=>{
   
  $('#commonMenu2').toggleClass("menu-common-fixed");
 
}

function handleVerify(){
  console.log("Verify")
}

  return (
    <GoogleReCaptchaProvider
    reCaptchaKey="6LeTtIAdAAAAAJEE2vJ_M1rftWdzp4aXwlnQqgbP"
    >
      <BrowserRouter basename={process.env.PUBLIC_URL}>  
      <GlobalState>      
          <div id="franja1" className="lang bg-oscuro py-0 my-0"> 
          {!context.e404 && <Breadcrumb/>}
          <div id="fimenu2">
              <FiMenu onClick={toggleMenu3} size={30}/>  
          </div> 
          <Link onClick={()=>{window.scrollTo(0,0)}} id="isologo" to="/"><img alt='Logo Mapa del Estado' height="50px" src={`${pathImg}/logo_mapa_invertido.png`}/></Link>
          </div>        
          <Menu/>
          <GoogleReCaptcha onVerify={handleVerify} />
          <Doc/>
          <Switch>      
            <Route exact path="/" component={Home} />
            <Route exact path="/grafico" component={Grafico} /> 
            <Route exact path="/organismos" component={Organismos} />          
            <Route exact path="/ministerios" component={Ministerios} />
            <Route exact path="/ministerios/:id" component={Ministerio}/>  
            <Route exact path="/organismos/:idOrg"  component={Organismo}/> 
            <Route exact path="/busqueda/:search" component={Busqueda}/>         
            <Route exact path="/resultado/:search/:idu" component={FichaUnidad}/>
            <Route exact path="/:minorg/:jur/:idu/detalle" component={FichaUnidad}/>
            <Route exact path="/normativas"  component={Normativas}/>
            <Route exact path="/datos" component={Datos}/>
            <Route exact path="/datos/:id" component={DatosGaleria}/>    
            <Route exact path="/database_error" component={DatabaseError}/> 
            <Route exact path="/404" component={PaginaNoDisponible}/> 
            <Route exact path="/normativas/designaciones/:nUno"  component={NormativasDesig}/>
            <Route exact path="/normativas/estructura/:nUno"  component={NormativasEstructura}/>
            <Route path='*' component={PaginaNoDisponible}/>          
            <Route path='/404' component={PaginaNoDisponible}/>
          </Switch> 
          <span className="ir-arriba icon-arrow-up2">
          <FaAngleUp size={30}/>
          </span>
           <Footer />  
        </GlobalState>   
      </BrowserRouter>
    
    </GoogleReCaptchaProvider>
  );
}

export default App;
