import {Col} from 'react-bootstrap'
import $ from 'jquery'
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md'
import { useEffect, useState } from 'react'
import InfoUnidadOrg from './infoUnidad'
import ListadoNormas from './listadoNormas'


    function UnidadOrganizativa(props){

    const estructura=props.unidad!==null? props.unidad?.nestructura!==null?props.unidad.nestructura.replace(/;\s+/g,';').split(";[").reverse() : "":""


    const [cantCol, setCantCol]=useState(2)


    function setearMasNormas(){
        
        $('#masN').css('display','none')
        $('#menosN').css('display','flex')
        $('.noneNormas').css('display','block')
        $('.normasList').css('column-count', cantCol)        
        $('.normasList').css('column-fill', 'auto')       
        $('.normasList').css('height', '100%')
        $('.blockNormas').css('width','100%')
        $('.noneNormas').css('width','100%')
        $('#RowCols').css('align-items','stretch')
    }

    function resetMasNormas(){
        $('.normasList').css('column-count', 'auto')
        $('.normasList').css('height', 'auto')
        $('.blockNormas').css('width','50%')
        $('.noneNormas').css('width','50%')
        $('.noneNormas').css('display','none')        
        $('#masN').css('display','flex')
        $('#menosN').css('display','none')      
    }


    useEffect(()=>{
       
        if(estructura.length>15){
            setCantCol(3)
        }

    },[props.unidad])


    return(
    <div className="colenRow" >
        <h2 className={`${props.colorFont} bgFicha_${props.bgColor} text-center mx-3 ft-bold p-3 rounded`}>UNIDAD ORGANIZATIVA</h2>
        <div id="RowCols" className={`columnaFicha mx-3  rounded `}>

            <InfoUnidadOrg bgColor={props.bgColor}  reporta_a={props.unidad.reporta_a} carnivel={props.unidad.car_nivel} car_escalafon={props.unidad.car_escalafon} unidad_ufa={props.unidad.unidad_ufa} />
        
            <div id="normasEstructuraBox" className={`py-3 rounded normasUnidad pb-1 borderFicha_${props.bgColor}`}>
                <div className="normasEst">
                    <p id="pNormEst" className="mb-3">Normas de Estructura </p>       

                    <ListadoNormas nestructura={props.unidad!==null? props.unidad.nestructura : ''}/>
                </div>
                <div id="menosN" className=" justify-content-end  mt-2">
                    <div onClick={resetMasNormas} className="ft-dark buttonMasNormas">
                        Ver menos
                        <MdKeyboardArrowUp title="Ver menos" size={25}/>
                    </div>
                </div>
                     {estructura.length>2 &&
                        <div id="masN" className=" justify-content-end mt-2">
                            <div  onClick={setearMasNormas} className="ft-dark buttonMasNormas" >
                                Ver más
                                <MdKeyboardArrowDown id="masN" title="Ver todas las normas" size={25}/>
                            </div>
                        </div>
                    }
             </div>
        </div>        
    </div>
    )
    }


    export default UnidadOrganizativa;
