
import { useEffect } from 'react';
import {Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom';




function Header_Jurisdiccion(props) {

   
    return (
      <>
        <div className={`${props.tipo==='org'? 'bg-org-'+props.ta :'bg-dark'} px-0`}>
        <Container className="px-0">                           
                {props.tipo==='org' &&
                    <div className="header-org">
                        <div className="px-0 h2div">
                          <h2 className={`py-2 mb-0 ml-0 ft-white h2Juri px-0`}>{props.unidad.unidad}{props.unidad.ncorto!==null? <>({props.unidad.ncorto})</>: null}</h2>                         
                        </div>                  
                    </div>
                }
                {props.tipo==='min' && 
              
                <div className="px-0 h2div">          
                  <h2 className="py-2 bg-dark ml-0 h2Juri ft-white  px-0 ">{props.unidad.jurisdiccion}</h2>  
            
                </div>  
                      
                }
        </Container>
         
        <Container fluid className="mx-0 px-0 bg-primary">
                       <Container className="px-0 py-2 ">
                          <div className="py-2 bajadaOrgEscalar">
                              {props.tipo==='org'&&<Link to={`../ministerios/${props.unidad.n1}`}>
                              <h3 className="px-0 ft-dark">{props.unidad.jurisdiccion}</h3>
                              </Link>}                    
                              <h4 className="px-0 ft-lt-black">
                                {props.unidad.tipoadministracion}
                              </h4>
                          </div>
                        </Container>
        </Container>

        </div>
        
    </>
  );
}

export default Header_Jurisdiccion;
