
import {Link} from "react-router-dom";
import {IoInformationCircleOutline} from 'react-icons/io5'
import {Row, Col} from 'react-bootstrap'


function UnidadResultadoBusqueda(props){

   
    const search=props.search      
    const dato=props.dato
    const i=props.i

    function ftColor(idta){
        let ft=0
        if (idta>3){
            ft=4
        }
        else{
            ft=idta
        }
        switch(ft){
            case 1: return 'bg-dark'
            
            case 2 : return 'bg-dark'
            
            case 3: return 'bg-aero'
            
            case 4: return 'bg-gris'
            }
      }



    return(
    <>
        <div key={i} className="tarjetaBusqueda bg-shadow3">
           <div className="tarjeta__home--content p-2">    
             <Link className="linkBusqueda" to={`/resultado/${search?.replace(/\s+/g, '-')}/${dato?.idunidad}`}>      
                <div className={`text-center p-2 headerFichamin ${ftColor(dato?.idtipoadministracion)}`}> 
                    <h1 >
                        {dato?.unidad.toUpperCase()}
                    </h1>
                    {dato?.idreporta!==dato?.idunidadsuperior && dato?.idreporta!==dato?.idjurisdiccion &&
                    <p className="ft-white">                        
                        {dato?.reporta_a.toUpperCase()}
                    </p>}
                    {dato?.idunidadsuperior!==dato?.idjurisdiccion &&
                    <p className="ft-white">                        
                        {dato?.unidadsuperior.toUpperCase()}
                    </p>}
                    <p className="ft-white">                    
                        {dato?.jurisdiccion.toUpperCase()}
                    </p>
                </div>
                <Row className="mx-0  align-items-center py-0 px-3">                
                    <Col xl={9} lg={9} md={8} sm={8} xs={8} className="p-0">
                        <div className="mx-0 my-3  ft-21  ">
                            <p className="ft-bold">{dato?.cargo} {dato?.autoridad?.toUpperCase()}</p>                       
                            <p className="ft-bold">{dato?.tipoadministracion}</p>       
                        </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={3} xs={3} className="text-center">
                        <IoInformationCircleOutline  className="iconResultados" size={'2.5em'}/>
                    </Col>
                </Row>
                </Link>
            </div>
        </div>             
    </>
    )
}

export default UnidadResultadoBusqueda;