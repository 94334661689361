import { useEffect, useState, useContext, Suspense } from "react";
import Context from '../../components/context';
import Vicepresidencia from './vicepresidencia';
import Ministerios from './ministerios'
import Presidencia from './presidencia/index'
import Jgm from './jgm'
import $ from 'jquery'

const Tarjetas = () => {

  const context=useContext(Context);

  return (
        <div className="tarjetas">
            {
                context.listaMinHome && context.listaMinHome?.length!==0 ?
                <div id="homeImagenes"  className="py-5 bg-primary mx-0">                         
                        <Presidencia /> 
                        <Jgm />
                        <Ministerios />        
                </div>
                :
                <></>        
            }  
        </div>
  )
}

export default Tarjetas