import { useState, useContext, useEffect } from "react";
import {Row, Col} from 'react-bootstrap'
import {pathIconos} from '../../components/paths'


import Context from '../context';
import {Link} from 'react-router-dom';
import {pathPDF,pathBack2, pathOrganigrama, pathBack} from '../paths'
import BuscarButton from '../buscarButton'
import {altNormativa,altDesig,altWeb,altCSV,altPDF,altOrgPDF, altXLS} from '../../contenido/alt'
import {db} from '../../../configuracion/environment'

function Jurisdicciones_Panel(props) {

  //Componente que devuelve el panel en la pagina del escalar de un ministerio.

  const context=useContext(Context);  
 

//   const submitRadio=(e)=>{
//     //Cambia el tipo de vista dependiendo del input radio seleccionado.
//     e.preventDefault();    
//     context.changeList(props.nUno,context.opcion)
//   } //Cambia la consulta por la seleccionada en el filtro.

  const submitRadio=(opcion)=>{
    //Cambia el tipo de vista dependiendo del input radio seleccionado.       
    context.changeList(props.nUno, opcion)
  } //Cambia la consulta por la seleccionada en el filtro.


  function imprimir (){
    window.print("");
  }

  useEffect(()=>{
    
    submitRadio(context.opcion)
  }, [context.opcion])

  return (
           <>
            <Row className="pt-2 pb-1 panel_min align-items-center justify-content-between mx-0">
                <Col className="px-0 pr-2 py-2" xl={4} lg={12}>
                <div className='orgmaBoton py-2 pl-2' > 
                 <a   target="_blank" href={`${pathOrganigrama}${props.nUno}.pdf`} title={altOrgPDF}>
                    <img src={`${pathIconos}/icon_organigrama.svg`} className="d-inline" width= "50px" alt={altOrgPDF}/>
                    <h3 className="d-inline ml-2">ORGANIGRAMA</h3>
                 </a>
                 </div>
                </Col> 
                <Col id="inf-com_buttons" xl={8} lg={12}> 
                <Row id="infcom" className="align-items-center py-2">                  
                    <Col lg={5} md={5} className="pr-0 tituloinfcom">   
                        <h3>Información Complementaria</h3>
                    </Col>
                     <Col className="text-right iconosMin pl-0" lg={7} md={7}>
                     <Link to={`/normativas/estructura/${props.nUno}`} title="Normativas" >                    
                        <img className="ml-3" width= "50px" src={`${pathIconos}/icon_normativa.svg`} alt={altNormativa}/>
                     </Link>  
                     <Link to={`/normativas/designaciones/${props.nUno}`} title="Designaciones" >                    
                         <img className="ml-3" width= "50px" src={`${pathIconos}/icon-designaciones_fondo.svg`} alt={altDesig}/>
                    </Link> 
                        <a rel="noopener noreferrer" href={props.web} target="_blank" title={altWeb}>
                         <img className="ml-3" width="50px" src={`${pathIconos}/icon_web.svg`} alt={altWeb}/>
                        </a>
                        <a  rel="noopener noreferrer" href={`${pathBack2}db=${db}&id=9&fi=xls&n1=${props.nUno}` } title={altXLS}>
                           <img className="ml-3" width="50px" src={`${pathIconos}/icon_xls.svg`} alt={altXLS}/>
                        </a>
                        <a  rel="noopener noreferrer" href={`${pathBack2}db=${db}&id=9&fi=csv&n1=${props.nUno}` } title={altCSV}>
                           <img className="ml-3" width="50px" src={`${pathIconos}/icon_csv.svg`} alt={altCSV}/>
                        </a>
                        <span className="pdfPrintJ" onClick={imprimir} title={altPDF}>
                            <img className="ml-3" width="50px" src={`${pathIconos}/icon_pdf.svg`} alt={altPDF}/>
                        </span>
                    </Col>
                </Row>                     
                </Col>
            </Row>           
           <form id="estruTools" className="row bg-primary my-1 py-2 px-2 mx-0">
               {/* <Row id="containerOpciones" className="align-items-center ft-lt-black mx-0 px-0"> */}
                   {/* <span className="px-0 mr-2">
                       <h4 >Filtros de búsqueda</h4>
                   </span> */}
                   {/* <div id="filtrosOpciones" className=" d-inline px-0 mx-0" > */}
                        <div className="filtrosRow align-items-center mx-1">
                            <div className="px-0" id="buttonsOpciones" >
                                {/* <div className="ml-3 d-inline radioInput">
                                    <input type="radio" id="as" name="as" checked={context.opcion==='as'} value="as" onChange={()=>{context.setOpcion('as')}}/>
                                    <> </>
                                    <label for="as">Autoridades Superiores APN</label>
                                </div> */}
                                <div className="radioInput">
                                    <input type="button" id="as" name="as" className={` ${context.opcion== 'as' ? 'opActivaEscalar' : '' } opEscalar`} checked={context.opcion==='as'} value="Autoridades Superiores" onClick={()=>{context.setOpcion('as')}}/>
                                    <> </>
                                    {/* <label for="as">Autoridades Superiores APN</label> */}
                                </div>
                                <div className="radioInput">
                                    <input className={` ${context.opcion== 'rb-comp' ? 'opActivaEscalar' : '' } opEscalar`} type="button" id="ac" name="ac" checked={context.opcion==='rb-comp'} value="Administración Central" onClick={()=>{context.setOpcion('rb-comp')}}/>
                                    <> </>
                                    {/* <label for="ac">Administración Central</label> */}
                                </div>
                                <div className="radioInput">
                                    <input className={` ${context.opcion== 'rb-ee' ? 'opActivaEscalar' : '' } opEscalar`} type="button" id="cfn" name="cfn" checked={context.opcion==='rb-ee'} value="Cargos Fuera de Nivel - UEET" onClick={()=>{context.setOpcion('rb-ee')}}/>
                                    <> </>
                                   {/* <label for="cfn"> Cargos Fuera de Nivel</label> */}
                                </div>
                                {/* <div className="ml-3 d-inline radioInput "> */}
                                <div className='radioInput'>

                                    <input className={` ${context.opcion== 'rb-oe' ? 'opActivaEscalar' : '' } opEscalar`} type="button" id="oe" name="oe" checked={context.opcion==='rb-oe'} value="Organismos/ Entes Públicos" onClick={()=>{context.setOpcion('rb-oe')}}/><> </>
                                     {/* <label for="oe"> Organismos/Entes Públicos</label> */}
                                </div>
                                {/*
                                (230118 - KEC) deshabilitado este botón por revisión LM/AP
                                <div className="radioInput">
                                    <input className={` ${context.opcion== 'ueet' ? 'opActivaEscalar' : '' } opEscalar`} type="button" id="opcionUeet" name="opcionUeet" checked={context.opcion==='ueet'} value="UEET" onClick={()=>{context.setOpcion('ueet')}}/>
                                    <> </>
                                   /* <label for="ueet"> Unidades Especiales Ejecutoras Transitorias</label> */
                                /*</div>*/}
                            </div>
                            {/* <Col className="px-0 buscarButtonFiltros" lg={1} xl={1}> */}
                            {/* <BuscarButton customClickEvent={submitRadio} /> */}
                                 {/* <input type="button" onClick={submitRadio} className="bg-dark searchButton" value="Buscar"/> */}
                            {/* </Col> */}
                        </div>
                   {/* </div> */}
               {/* </Row> */}
           </form>
           
       </> 
  )
}

export default Jurisdicciones_Panel;