import {title} from '../contenido/paginaNoDisponible'
import {Container, Row, Col} from 'react-bootstrap'
import { useEffect, useContext} from "react";
import Context from './context';
import {useHistory} from 'react-router-dom'


function PaginaNoDisponible(props){
 
    const context=useContext(Context);
    const history=useHistory()


    useEffect(()=>{
        context.setearE404(true)    
        setTimeout(()=>{
            context.setearE404(false) 
            history.push('/')           
        }, 5000)    
    },[])



return(
<Container className="pagNoDisp">
    <Row className="mx-0 pndRow py-3">
        <Col className="logoPagND px-5" xl={5} lg={5} md={5} sm={12} xs={12}>
            <div>
                <h2 className="ft-37bb my-4">{title}</h2>
                <p>Será redirigido a la página de inicio en 5 segundos.</p>
            </div>
        </Col>
        <Col className="align-items-center d-flex px-5 firmaJgm" xl={7} lg={7} md={7} sm={12} xs={12}>
           <div className='ft-gris03'>
               <p>
                   Jefatura de<br/>
                   Gabinete de Ministros<br/>
                   <strong>Argentina</strong>
               </p>
           </div>
        </Col>
    </Row>
</Container>
 )   

}

export default PaginaNoDisponible;