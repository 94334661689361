import { useEffect, useState,useContext } from "react";
import axios from "axios";
import {Container, Row,  Spinner} from 'react-bootstrap'
import Jurisdicciones_Escalar from '../components/escalar/jurisdiccionesEscalar'
import Header_Jurisdiccion from '../components/escalar/headerJurisdiccion'
import Organismos_Panel from "../components/organismos/organismoPanel";
import Tipo_Vista from "../components/escalar/tipoVista"
import Context from '../components/context';
import {pathBack, pathBack2} from '../components/paths'
import OrganigramaGrafico from '../components/escalar/organigramaGrafico'
import Referencias from '../components/referencias'
import { useParams, useHistory} from "react-router-dom";
import Errores from  '../components/errores';
import $ from 'jquery'
import {db} from '../../configuracion/environment'

function Organismo(props) {
    const history=useHistory();
    const param=useParams()
    const nUno=param.idOrg
    const [unidad,setUnidad]=useState([])
    const [leyendaEscalar, setLeyendaEscalar]=useState(null)
    const context=useContext(Context);
    const [colorTa, setColorTa]=useState(null) 

  function Color(color){
    if(color<=3){
      setColorTa(color)
    }else{
        setColorTa(4);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
        if(unidad?.length==0){
          context.verError() 
        }          
    }, 8000);
    return () => clearTimeout(timer);
  }, []);



  const fetchTitulo = async (nUno) => {
      try{
        return(
            await axios.post(pathBack2,`db=${db}&id=6&idu=${nUno}`)   
            .then(res =>{
              if(res.data?.datos){
                if(res.data?.metadatos[0]?.connect==0){
                  history.push('/database_error')
                }
                else{
                  setUnidad(res.data?.datos[0])         
                  if(res.data?.content?.leyenda_escalar){
                    setLeyendaEscalar(res.data?.content?.leyenda_escalar)
                  }
                  context.setearTitleDoc(`Mapa del Estado - ${res.data?.datos[0]?.unidad}`)
                    Color(res.data?.datos[0]?.idtipoadministracion)
                    // context.pushBread(res.data.datos[0]?.unidad, `/organismos/${nUno}`, 2, 'org')
                    context.setearBreadCrumb(
                      [{label:'Home',url:"/", nivel:0},
                      {label:'Organismos' , url:'/organismos', nivel:1},            
                      {label:res.data?.datos[0]?.unidad,url:`/organismos/${nUno}`, nivel:2},
                      ])           
                }
              }
              else{                
                history.push('/404')
              }
          }
          )
          )
      } 
      catch(error){
        history.push('/404')
        console.log('Error: ', error)
      }
  };//Trae solo data de la unidad en posicion 0 del pedido en la URL.

        
   useEffect( ()=>{
    context.setearE404(false)
    context.resetMinJurNor();   
    context.setFooterPath(pathBack2)
    window.scrollTo(0,0) 
    localStorage.removeItem("radio")
    if(nUno){
      context.fetchJurisdiccionData(pathBack2,`db=${db}&id=6&idu=${nUno}`);//Pide a la api los datos de un organismo.   
    }
    context.setTipoOrganigrama('Escalar') 
    if(nUno){   
       fetchTitulo(nUno)//Pide a la api el nombre de la unidad. Agrega el nombre al breadcrumb.
    }
    },[nUno]) 

  return (
    <>
  {unidad?.length!==0 ?
  <> 
  <Header_Jurisdiccion tipo={'org'} ta={colorTa} unidad={unidad}/>
  <Container className="px-0 escalar">      
    <Organismos_Panel web={unidad.web} ncorto={unidad.ncorto} idu={nUno}/>
    {leyendaEscalar &&
      <div className='alert alert-warning infCons'>{leyendaEscalar}</div>
    }
    <Tipo_Vista filtro={context.tipoVista} ta={context.tipoAdmOrg} org={true}/>
    {context.jurisdiccion==undefined &&
      <Errores desc={context.errores}/>
    }
    {context.tipoOrganigrama=='Escalar'?
    <>
     {context.jurisdiccion!==undefined&&context.jurisdiccion.length!==0?
        <Jurisdicciones_Escalar jur={unidad.unidad} tipo={'org'} 
        result={context.jurisdiccion} nUno={nUno} idu={nUno} unidad={unidad.unidad}/>
        :
        <Row className="justify-content-center p-3">
          <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}} />
          <p className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
        </Row>
      }
    </>
    :
    <OrganigramaGrafico result={context.jurisdiccion} nUno={nUno}/>}
    <Referencias/>
  </Container>
  </>
  :
  <Row className="loader justify-content-center p-3">
   <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}} />
   <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>  
  </Row>
}
  </>
  )
}

export default Organismo;