import { Row} from 'react-bootstrap'
import {  useContext } from "react";
import {pathIconos} from '../../components/paths'
import {Link} from 'react-router-dom';
import Context from '../context';
import {pathBack} from '../paths'
import {alt1y2NO, altSSS, altLM, altDesig, altBA} from '../../contenido/normativas'
import $ from 'jquery'

function NormativaPanel(props) {
//Retorna el panel con los íconos de tipo de normativas, cada uno con vínculo a la sección que indica su titulo.

const context=useContext(Context);


const handleReset=(t)=>{
      
    
        context.setMsj3('dispnoneMsj')
        context.setMsj4('dispnoneMsj')
        //Al cliquear un ícono de tipo de normativa, cambia la sección.
        if(context.TN=='ba'){
        context.showBuscadorHztal()
        } 
        switch(t){
                case 'ba': context.setearURLbuild(`${pathBack}id=8&ob=nba`)
                break;
                case 'lm': context.setearURLbuild(`${pathBack}id=8&tn=lm`)
                break;
                case 'sss': context.setearURLbuild(`${pathBack}id=8&tn=sss`)
                break;
                case '12no': context.setearURLbuild(`${pathBack}id=8&tn=12no`)
                break;
                case 'df': context.setearURLbuild(`${pathBack}id=8&ob=nd`)
        }    
        context.resetValues();
        context.tipoNormativa(t)    
        window.scrollTo(0,300)
}



return(
        <Row className='justify-content-center bg-primary rowPanelNorm'>
           <ul id="ulPanelNormativas" className='nav nav-icons justify-content-center'>
                <li  className='mx-2 px-3 py-3 text-center'>
                    <Link to="../../normativas">
                        <div onClick={()=>handleReset('lm')} className="botonPanelNorm">
                           <div className="normIcon ft-white bg-20">
                                <img alt={altLM} src={`${pathIconos}/icon-lministerios.svg`}/>
                           </div>
                           <span>Ley de <br/>Ministerios</span>                
                        </div>
                    </Link>
                </li>
                <li  className='mx-2 px-3 py-3 text-center'>
                    <Link to="../../normativas">
                        <div onClick={()=>handleReset('sss')} className="botonPanelNorm">
                           <div className="normIcon ft-white bg-20" >     
                                 <img alt={altSSS} src={`${pathIconos}/icon-syss.svg`}/>                             
                           </div>
                        <span>Secretarías y <br/>Subsecretarías</span>
                        </div>
                    </Link>
                </li>
                <li  class='mx-2 px-3 py-3 text-center'>
                    <Link to="../../normativas">
                        <div onClick={()=>handleReset('12no')} className="botonPanelNorm">
                           <div className="normIcon ft-white bg-20">   
                                <img alt={alt1y2NO} src={`${pathIconos}/icon-1y2no.svg`}/>                                
                           </div> 
                           <span>1° y 2° Nivel<br/>Operativo</span>
                        </div>
                    </Link>
                </li>
                <li  class='mx-2 px-3 py-3 text-center'>
                    <Link to="../../normativas">
                        <div onClick={()=>handleReset('df')} className="botonPanelNorm" >
                            <div className="normIcon ft-white bg-23">
                               <img alt={altDesig} src={`${pathIconos}/icon-designaciones_fondo.svg`}/>                               
                            </div>
                            <span>Designaciones</span>
                        </div>
                    </Link>
                </li>
                <li className='mx-2 px-3 py-3 text-center'>
                    <Link to="../../normativas">
                        <div onClick={()=>handleReset('ba')} className="botonPanelNorm">
                           <div className="normIcon bg-20">
                                <img alt={altBA} src={`${pathIconos}/icon-search.svg`}/>
                           </div>
                           <span>Búsqueda <br/>Avanzada</span>
                        </div>
                    </Link>       
                </li>
           </ul>
        </Row>
)
}

export default NormativaPanel;