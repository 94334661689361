import $ from 'jquery'
import { useEffect } from 'react'
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md'
import MasAutoridades from './masAutoridades'

function DesplegarMasAut(props){

 const aut2=props.aut2

    function setearMasCargos(){
  
        $('#masAutoridades').css('display','block')
        $('#masC').css('display','none')
        $('#menosC').css('display','flex')
        // $('#RowCols').css('align-items','flex-start')
    }
    function resetMasCargos(){
 
        $('#masAutoridades').css('display','none')
        $('#masC').css('display','flex')
        $('#menosC').css('display','none')
        // $('#RowCols').css('align-items','stretch')
    }
    
    useEffect(()=>{
     
    }, [aut2])

    return(
        <>
          
            <div >
            <div id="divaut2" className="mt-1" >
              <div className="m-0">
                <div >  
                  <MasAutoridades aut2={aut2}/>                           
                <div className="px-3  mt-3 justify-content-end py-2 d-flex" >
                  <div id="masC" onClick={setearMasCargos} className="buttonMasNormas ft-dark mb-0 ">                     
                        Ver más
                        <MdKeyboardArrowDown title="Ver todos los cargos"   className="ft-dark " size={25}/>                    
                  </div>                 
                </div>     

                
                
                <div id="menosC"  className="justify-content-end py-2">
                  <div onClick={resetMasCargos} className="buttonMasNormas  ft-dark mb-0 ">
                    Ver menos
                     <MdKeyboardArrowUp className="ft-dark"  title="Ver menos" size={25}/>
                   </div>
                </div>
                </div>
              </div>
            </div>
           </div>  
          
          </>
    )
}

export default DesplegarMasAut;