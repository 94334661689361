import axios from "axios";
import { useEffect, useState, useContext } from "react";
import {pathBack} from '../paths'
import {Container, Row} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom';
import {db} from '../../../configuracion/environment'
import Context from '../context';


function DatosHome(props){

    const history=useHistory()
    const context=useContext(Context);

    useEffect(()=>{
        if(context.datosArray.length===0){
          context.getDatos()  
        }
    },[])


    useEffect(()=>{
        
    }, [context.datosArray])



    return(
        <div className="py-5 bg-secondary cntFlexHome">
            <div className="contenedor_items">
            <h3 className="tituloseccion mb-2 ">Datos</h3>   
            <div>
                
                <Row className="justify-content-center mx-1"> 
                    {
                        context.datosArray?.slice(0,3).map((item,i)=>{
                            //Itera el elemento tarjeta con los datos de cada elemento del array que se cargó con el resultado del pedido a la API.
                            return(
                               <Link className="py-4 linkDatos col-lg-4 col-md-5" key={i} to={`datos/${item?.id}`}>   
                                    <div className="bg-white bg-shadow4 datosHome p-0">
                                        <div className="iconoDatos text-center">
                                            <i className={item?.icono}></i>
                                        </div>
                                        <div className="p-3 infoDatosHome">
                                            <div className="mb-5 tituloDatos ">
                                                <h3>{item?.titulo}</h3>
                                            </div>
                                            <div className="mb-2">
                                                <h3>{item?.alcance}</h3>
                                            </div>
                                            <div className="periodoDatos">
                                                <p>Período: {item?.periodo}</p>
                                            </div>
                                            <div>
                                                <div className="text-right fpubli">
                                               <div>Fecha de Publicación:</div><div>{item?.fpublicacion}</div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </Row>
            </div>
            </div>
        </div>
    )
}

export default DatosHome;