import React from 'react'
import {FaSearch} from 'react-icons/fa';
import Context from '../../components/context';
import {useContext, useState} from 'react'
import {textbuscador,placeholder} from '../../contenido/index'
import {Container, Row, Col,  Button} from 'react-bootstrap'


const Busqueda = () => {

  const context=useContext(Context);
  const [borderClass, setBorderClass]=useState('')


  return (
    <div>
        <div className="d-flex justify-content-center">
                        <div id="inputHome">
                            <h5>{textbuscador}</h5> 
                            <div className="d-flex"> 
                                <input 
                                name="busquedaHome"
                                placeholder={placeholder}
                                className={`form-control ${borderClass}`}
                                type="text"                             
                                onChange={e=>{context.setearBusquedaHome(e.target.value)}}
                                value={context.busquedaHome}></input>
                                <div id="mensCaracteres" className={context.msj}>
                                    No admite caracteres especiales
                                </div>
                                <div className="displayMsjtriangle"></div>
                                <Button
                                type="submit"
                                id="botonHome">                             
                                <FaSearch/>
                                </Button>
                            </div>
                        </div>            
                    </div>
    </div>
  )
}

export default Busqueda