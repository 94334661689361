import { useEffect, useState, useContext } from "react";
import Context from '../context';
import axios from 'axios';
import {pathBack} from '../paths'

function SelectTemaNormativa(props) {

    const context=useContext(Context);
    

      useEffect(()=>{
        if(context.datosTemas.length==0){
          context.fetchTitulo('tteno')
        }
      },[])


   return (
    <>
        <select onChange={e=>context.pushParam([e.target.value.split('/'),'tema'],false)} id='t' name='t' className='form-control input-lg'>
              <option value={`${''}/${'tema'}`}>{context.tituloTemas}</option>            
              {context.datosTemas!==undefined && context.datosTemas.length?
                <>
                    {context.datosTemas?.map((tem,i)=>{
                      return(
                        <option key={i} value={`${tem?.id}/${tem?.descripcion}`}>{tem?.descripcion}</option>
                      )
                    }
                    )}
                </>
                  :
                  null
              }             
        </select>
    </>
   )
}

export default SelectTemaNormativa;