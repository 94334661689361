import {Row} from 'react-bootstrap'



function RefBusqueda(props){

    const arrayRefs=['Administración Central', 'Organismo Desconcentrado', 'Organismo Descentralizado', 'Sociedad del Estado/Otros Entes']

    return(
        <div id="referenciasBusqueda" >
         <Row className="mx-0 my-4 rowRefBusqueda align-items-center">  
         <h4 className="mb-2 mr-4">Referencias</h4>  
         <div className="listaRefs d-flex mb-2 align-items-center" >  
             {arrayRefs?.map((li,index)=>{
               return(
                 <div key={index} >
                    <div className="d-flex align-items-center mr-4">
                      <div className={`mr-2 item${index}`}></div>
                    <div className="refContent">{li}</div>
                    </div>
                 </div>
               )
             })}
             </div>
         </Row>
       </div>
    )
 	
}

export default RefBusqueda