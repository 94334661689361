import DesplegarMasAut from "./desplegarMasAut";
import Designacion1raAutoridad from "./designacion1raAutoridad";
import {Col} from 'react-bootstrap'
import { useEffect, useState, useContext} from "react";
import Context from '../context';


function AutoridadesFichaUnidad(props){

    
    const colorFont=props.colorFont
    const bgColor=props.bgColor
    const context=useContext(Context);
    const unidad=props.unidad

    const [aut2, setAut2]=useState([])

    useEffect(()=>{
     
      if(context.dataFicha.length>1){       
        setAut2(context.dataFicha?.slice(1,context.dataFicha.length))
      }
    },[context.dataFicha])


    useEffect(()=>{
     
    },[aut2])

    return(
        <div className="colenRow" >
        <h2 className={`${colorFont} rounded  mx-3 p-3 bgFicha_${bgColor} text-center ft-bold `}>
          AUTORIDAD{aut2.length!==0&&'ES'}
        </h2> 
        <div className={`justify-content-between borderFicha_${bgColor} rounded mx-3 d-flex flex-column tablaCargos`} >
        <div>
            <div className="d-flex labelsTabla justify-content-between mb-3">
              <Col className="px-2" xl={5} lg={5} md={4} sm={5} xs={4}>
                <p className={`text-nowrap`}> Cargo y nombre </p>
              </Col>
              <Col className="px-2" xl={3} lg={3} md={4} sm={3} xs={4}>  
                <p > Norma de Designación </p>
              </Col>
              <Col className="px-2" xl={2} lg={2} md={2} sm={3} xs={2}>  
                <p className={`text-nowrap`}> Fecha </p>
              </Col>
            </div>
            <div id="aut1" className="columnaFicha  justify-content-between">
              <Col  id="cargoBox" xl={6} lg={5} md={5} sm={5} xs={4} className={`mx-0 ft-21 px-2`}>
                <p><span className="ft-bold">{unidad.cargo} {unidad.autoridad!==null?unidad.autoridad.toUpperCase():""}</span></p>
              </Col>
                {context.normaFicha?.length ?
                  <Designacion1raAutoridad bgColor={bgColor} unidad={unidad}/>
                  :
                  ''
                }
            </div>
          </div>          
                {aut2.length ?
                  <DesplegarMasAut aut2={aut2}/>
                 :
                 ''
                }
        </div>
      </div>
    )
}

export default AutoridadesFichaUnidad;