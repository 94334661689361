import {Row} from 'react-bootstrap'
import {FaRegFilePdf, FaDownload} from 'react-icons/fa'


function DescargaSerie(props){

    const serie=props.serie
    const hrefArchivo=props.hrefArchivo
    const hrefInforme=props.hrefInforme

    return(
            <Row id="rowSerieDatos" className="mt-0 mx-0 pl-4">
                {serie?.informe !== null &&
                    <button className="mr-3 botonGaleria buttonsClass buttonSeries bg-dark my-2">
                        <a rel="noopener noreferrer" href={hrefInforme} target='_blank'>
                            <div className="d-flex align-items-center">
                                <FaRegFilePdf size={15} className="pr-1"  /> Informe Completo
                            </div>
                        </a>
                    </button>
                }
                {serie?.datos !== null &&
                    <button className="botonGaleria buttonsClass buttonSeries bg-dark my-2">
                        <a rel="noopener noreferrer" href={hrefArchivo} target='_blank'>
                            <div className="d-flex align-items-center">
                                <FaDownload size={15} className="pr-1"/>Archivos de Datos
                            </div>    
                        </a>
                    </button>
                }
            </Row>
    )

}

export default DescargaSerie;