
import {FaTrash} from 'react-icons/fa'

function LimpiarBusquedaButton(props){
    return(
        
        <button onClick={props.customClickEvent} className="d-flex align-items-center bg-dark buttonsClass">            
            <FaTrash className="mr-1"/> 
            <div>Limpiar</div>
        </button>
    )
 	
}

export default LimpiarBusquedaButton