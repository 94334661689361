
import {Row} from 'react-bootstrap'

function InformacionFuentes(props){

    const galery=props.galery

    return(
        <Row className="mt-5 mx-0">                 
            <div className="fichaDatos">                    
                <p>Publicador: <small>{galery?.publicador}</small></p>
                <p>Fuente: <small>{galery?.fuente}</small></p>
                <p>Período: <small>{galery?.periodo}</small></p>
                <p>Unidades: <small>{galery?.unidades}</small></p>
                <p>Frecuencia: <small>{galery?.frecuencia}</small></p>
                <p>Fecha de publicación: <small>{galery?.fpublicacion}</small></p>
                <p>ID: <small>{galery?.id}.{galery?.version}_{galery?.codigo}</small></p>
            </div>                
        </Row>
    )
}

export default InformacionFuentes;