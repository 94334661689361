import {Link, useLocation} from 'react-router-dom';
import { useContext } from "react";
import {Container, Row} from 'react-bootstrap'
import '../css/styles.css'
import {pathImg} from '../components/paths'
import {FiMenu} from 'react-icons/fi'
import $ from 'jquery'
import Context from '../components/context';


function Menu(props) {

    const location=useLocation()
    const context=useContext(Context);
    const secciones=[
        {name:'MINISTERIOS', path:'/ministerios'},
        {name:'ORGANISMOS', path:'/organismos'},
        {name:'NORMATIVAS', path:'/normativas'},
        {name:'DATOS', path:'/datos'}]
    
    const toggleMenu=()=>{
        $('#colMenu').toggleClass("oculto");
    }    
 

    const handleMenu=()=>{
      toggleMenu(); 
    }

    const handleClick=()=>{
      context.resetOrg()
      context.setDataError(null)
      $('#colMenu').removeClass('oculto');
      context.resetMinJurNor();
      context.resetValues()
      sessionStorage.removeItem('jurOrganismos')
      sessionStorage.removeItem('tadmOrg')  
      sessionStorage.removeItem('jurNor')  
      sessionStorage.removeItem('minNorm')  
      context.setearJurOrganismos(undefined)
      context.resetBusquedaOrg([])   
      context.tipoNormativa('ba')
      context.setearSearchValue('')
      context.setearBusquedaHome('')  
    }

return(
  <>
    {location.pathname!=="/database_error" ?
    <nav id="commonMenu">
      <Container className="menuYlogo px-0">
        <Row className="rowMenu justify-content-between py-0 align-items-center px-0">         
            <div  id="logoMenu" className="d-flex align-items-center justify-content-between mr-3 ">
                <Link id="logoHome" to="/">
                  <img height="60px" alt='Logo de Mapa del Estado' title="Mapa del Estado" src={`${pathImg}/logo_mapa.png`}/>               
                </Link>
                <div onClick={handleMenu} id="fimenu">
                  <FiMenu  size={30}/>  
                </div>              
            </div>  
            <div id="colMenu"className="px-0 " >      
              <ul id="ulMenu" className="text-right my-0">
                {secciones?.map((seccion,index)=>{
                  return(
                    <Link key={index} onClick={handleClick} to={seccion.path}>
                      <li onClick={()=>{window.scrollTo(0,0)}} className="d-inline-block nav-item">
                        <div className="menuLink  ft-dark ">{seccion?.name}</div>
                      </li>
                    </Link>
                  )
                }
                )}
              </ul>
            </div>        
        </Row>  
      </Container>
    </nav>
    :
    <>
      <Container>
        <div id="logoMenu" className="d-flex align-items-center justify-content-between px-4 py-3">        
            <img height="60px" alt='Logo de Mapa del Estado' title="Mapa del Estado" src={`${pathImg}/logo_mapa.png`}/> 
        </div>
      </Container>
    </>
    }    
    <nav id="commonMenu2">
      <Container className="menuYlogo px-0">
        <Row className="rowMenu justify-content-between py-0 align-items-center px-0">         
            <div  id="logoMenu" className="d-flex align-items-center justify-content-between mr-3 px-0">
                <Link id="logoHome" to="/">
                  <img height="60px" alt='Logo de Mapa del Estado' title="Mapa del Estado" src={`${pathImg}/logo_mapa.png`}/>               
                </Link>
                <div onClick={handleMenu} id="fimenu">
                  <FiMenu  size={30}/>  
                </div>              
            </div>  
            <div id="colMenu"className="px-0 " >      
              <ul id="ulMenu" className="text-right my-0">
                {secciones?.map((seccion,index)=>{
                  return(
                    <Link key={index} onClick={handleClick} to={seccion?.path}>
                      <li onClick={()=>{window.scrollTo(0,0)}} className="d-inline-block nav-item">
                        <div className="menuLink ">{seccion?.name}</div>
                      </li>
                    </Link>
                  )
                })}
              </ul>
            </div>        
        </Row>  
      </Container>
    </nav>
  </>
) 
}

export default Menu;