
import {FaSearch} from 'react-icons/fa'

function BuscarButton(props){

    // Muestra por pantalla el boton 'Buscar' y la función que ejecuta el evento onClick varía según la prop que recibe

    return(
        
        <button onClick={props.customClickEvent} className="d-flex align-items-center buttonsClass ft-white bg-dark">
         <FaSearch className="mr-1"/> Buscar 
        </button>
    )
 	
}

export default BuscarButton