import React from 'react'
import {Link} from 'react-router-dom';
import Context from '../../../components/context';
import {useContext, useEffect} from 'react'
import {pathImgPerfil } from '../../../components/paths'
import Subjurisdicciones from './subjurisdicciones';
import {sinFoto} from '../../../contenido/constantes'

const Presidencia = (props) => {

  const context=useContext(Context);
  
  const n1Pres= context.listaMinHome && context.listaMinHome[0] ? context.listaMinHome[0] : null
  
  const nombreImg=  n1Pres?.versionImgP ? `${n1Pres?.n1}.png?v=${n1Pres?.versionImgP}` : `${sinFoto}.png`
     
  function handleSrc(e){
    e.target.src=`${pathImgPerfil}/${sinFoto}.png`
  }

  useEffect(()=>{
    return()=>{
      context.setWidthSecres('widthcero')
    }
  },[])

  return (
    <div>
            <div className={`cardHome d-flex contenedor00 ${context.widthSecres=='widthauto'? 'justify-content-center' : 'justify-content-end'}`}>
                <div className="contenedor_pre">
                    <div className="bg-white bg-shadow4 py-1 px-0 m-2 d-flex justify-content-start col00" >
                        <Link className={`${context.listaMinHome[0]?.clase} linkhome`} 
                        to={`ministerios/${n1Pres?.n1}`}>
                            { n1Pres  ?
                             <div className="text-left px-1 imgFlexHome">
                               <img 
                                onError={handleSrc}
                                fluid 
                                src={`${pathImgPerfil}/${nombreImg}`}/>                          
                             </div> 
                             :
                             null
                            }
                            <div className="flex-wrap card-body text-left px-1 py-0" >
                                <h1 className="jurbox bg-oscuro py-3">
                                    {context.listaMinHome[0]?.unidad}
                                </h1>
                                <h2 className="jurbox ft-lt-black">
                                    {context.listaMinHome[0]?.autoridad}
                                </h2>              
                            </div>
                        </Link>   
                    </div> 
                </div>
                <Subjurisdicciones slice={1}/>   
                {/* <Subjurisdicciones slice={2}/> */}
            </div>
    </div>
  
  )
}

export default Presidencia