


function HeaderDatosGaleria(props){

    const galery= props.galery
    const metaGalery=props.metaGalery

    return(
            <header>
                <div className="py-3 Header headerDatos bg-claro">
                    <div class="container px-0">
                        <div class="col-xs-10 col-sm-10 col-lg-12 col-sm-offset-1 mx-0 px-0">
                            <h3>{galery?.titulo}</h3>                            
                            <p>ID: {metaGalery?.subtitulo}</p>
                            <p>Fecha de publicación: {galery?.fpublicacion}</p>
                        </div>                          
                    </div>
                </div>  
            </header>
    )
}
export default HeaderDatosGaleria;