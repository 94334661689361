import Context from '../components/context';
import {Link} from 'react-router-dom'; 
import {React, useEffect,  useContext}  from "react";
import $ from 'jquery';
import {FaSortDown, FaSortUp} from 'react-icons/fa'

function Breadcrumb(props){

const context=useContext(Context);

useEffect(()=>{
sessionStorage.setItem('bread', JSON.stringify(context.breadcrumb.length!==0?context.breadcrumb:[{
    label:'Home',
    url:'/',
    nivel:0
}]))
},[context.breadcrumb])

function handleClick(){   
    $('#colMenu').removeClass('oculto');      
    context.resetMinJurNor();
    context.resetValues()
    sessionStorage.removeItem('jurOrganismos')
    sessionStorage.removeItem('TN')
    sessionStorage.removeItem('tadmOrg')  
    sessionStorage.removeItem('jurNor')  
    sessionStorage.removeItem('minNorm')  
    sessionStorage.removeItem('pathNormativa')  
    context.setearJurOrganismos(undefined)
    context.resetBusquedaOrg([])  
    context.tipoNormativa('ba')
    context.setearSearchValue('')
}

function toggleBC(){
    $('#olBC').toggleClass("breadcrumbOculto");
    $('#down').toggleClass("breadcrumbOculto");
    $('#up').toggleClass("breadcrumbOculto");
}

if(context.breadcrumb.length!==0){
    return(
        <>        
            <nav id="breadcrumbNav" className="py-1 ">
            {context.e404!==true ?
            <ol id="olBC" className="py-0 my-0 px-3 breadcrumbOculto">
                {
                    context.breadcrumb?.map((crumb,index)=>                        
                           <span key={index}> 
                            {(context.breadcrumb.length>1&&index!==0)? <span className="mx-1"> / </span>:<></>}
                            {context.breadcrumb.length-1!==index?
                            <Link className="linkBreadcrumb" onClick={handleClick} to={crumb.url}>{crumb.label}</Link>
                            :<span id="lastLabel">{crumb.label}</span>}
                           </span> 
                        )
                }
            </ol> 
        :
        <div className="ml-2">Página no encontrada</div>    
        }
       { context.e404!==true && <div className="px-3 my-2" id="navegacion" onClick={toggleBC}><FaSortDown id="down" size={15}/>
            <FaSortUp id="up" className="breadcrumbOculto" size={15}/></div>}
        </nav>  
    </>
    )
            }
}

export default Breadcrumb;