
import {Row, Col, Spinner} from 'react-bootstrap';
import Unidad_Escalar from './unidadEscalar';
import $ from 'jquery'
import {useEffect, useContext} from 'react'
import {FaSitemap, FaPrint, FaSearch,FaListUl} from "react-icons/fa";  
import Context from '../context';
import Grafico from '../../grafico/grafico'
import {buscarUnidad} from './buscarUnidad'
import {buscarUnidadGrafico} from './buscarUnidadGrafico'
import {FiDelete} from 'react-icons/fi'
import {BiZoomIn, BiZoomOut} from 'react-icons/bi'


 
function Jurisdicciones_Escalar(props) {
//Componente que devuelve el Organigrama Escalar iterando el componente Unidad_Escalar.
//Es llamado desde Ministerio y desde Organismo.

const context=useContext(Context); 

// $(document).ready(function () {
//   setTimeout(()=> {
//     if(result?.length==0){
//           $('.hideMe').hide();
//       $('.showErr').show();
//     }
  
//   }, 10000);
// });

var zoom=100;


useEffect(() => {
 
  if(context.radio=='rb-comp'){
    if(context.opcion!=='rb-comp' ){
        context.setOpcion('rb-comp')
  }
  }
  else if(context.radio=='as'){
    if(context.opcion!=='as' ){
        context.setOpcion('as')
  }
  }
},
[context.radio])


useEffect(() => {

   
  context.setValFilt('')
   context.calcPorcGenYPorcRyJ(props.result)
  if(props.idu==false){
    context.getCantRyJ(props.nUno)
  }
 else{
    context.setInitialChart([])
    context.setCantCargosGraf([])
 }

  
  
}, []);



useEffect(()=>{
  const timer = setTimeout(() => {
    if(result?.length==0){
      context.verError() 
    }          
}, 8000);
return () => clearTimeout(timer);

},[context.jurisdiccion])


function EnterSearch(e){
  if(e.keyCode == 13){
    if (context.tipoOrganigrama==='Escalar'){
      buscarUnidad()
    }
      else{
      buscarUnidadGrafico()
      }
  }
}

function fn(e){
  context.setValidarFilt(e.target.value)
  createDelete()
}

function setFontSize(val){
  zoom=zoom+val
  $('.ulEscalar').css('font-size',zoom+'%')
}

function remove(){ 
  //Vacía el input del Buscador de Unidad/Autoridad
  $('.found').removeClass('found');
var filtro= document.querySelector('#filtrar');
filtro.value="";
var remove=document.querySelector('#delete2');
remove.style.display="none";
}

function createDelete(){
  //Agrega al DOM el boton de borrar que al presionarlo vacía el input del buscador de texto mediante la funcion remove()
	
	var filtro= document.querySelector('#filtrar');  
	  if(filtro.value!==undefined){
		if(filtro.value!==''){
		  document.querySelector('#delete2').style.display="inline";
		}
		else{
		  document.querySelector('#delete2').style.display="none";
		}      
	}
	else{
	  document.querySelector('#delete2').style.display="none";
	}
	return false;
  }


  
  const result= props.result
  
  let anterior='';

 
  const compare=(cuo)=>{
    if(anterior===cuo){     
      anterior=cuo
      return false;
    }
    else{      
      anterior=cuo       
      return true;
    }
  }
  //Verifica si el elemento actual del array pertenece a la misma unidad que el elemento anterior
  // y si pertenece, imprime un <p> en vez de <li>.

 

    return (
      <>
      {result?.length!==0?    
      <>
      
      {
      <Row id="orgEscalarYgraf" className="my-1 mx-0">
          
          <Col className="px-0" xl={12} md={12} lg={12}>

              <div id="panel2" className='d-flex justify-content-between bg-blueGrey my-2 p-2'>
                  <Col id="colFiltrar" className="d-flex align-items-center justify-content-between px-1" lg={7} md={6} sm={6} >     
                    {/* createDelete */}
                        <input id="filtrar" value={context.varFilt} onKeyUp={EnterSearch} onChange={e=>fn(e)} placeholder='Buscar Unidad/Autoridad' />
                        
                        <Col className="px-0 text-right panelZoom" xl={2} lg={2} md={2} sm={3} xs={3}>
                          <div id="delete2">
                            <FiDelete onClick={remove}/>
                          </div>     
                          <FaSearch id="filtrarIcon"  onClick={context.tipoOrganigrama==='Escalar'?buscarUnidad:buscarUnidadGrafico} />      
                        </Col>  
                  </Col>
                  <div className="d-flex align-items-center">
                    <button onClick={()=>setFontSize(5)} title="Aumentar tamaño de fuente." className="buttonTipoVista">
                      <BiZoomIn className="ft-04" size={20}/>
                    </button>
                    <button title="Disminuir tamaño de fuente." onClick={()=>setFontSize(-5)} className="buttonTipoVista">
                      <BiZoomOut size={20} className="ft-04 mr-1" /></button> 
                  </div>            
              </div>
              <ul id="ul_escalar" className="ulEscalar pl-0">              
                {result?.map((elem,index)=>{
                  return(
                   <Unidad_Escalar jur={props.jur} unidad={props.unidad} idu={props.idu} tipo={props.tipo} key={index} compare={compare(elem.cuuo)} item={elem.idtipoadministracion} array={elem.unidad} dato={elem}/>  
                  )         
                }
                  )} 
              </ul>
          </Col> 
        {/*
          <Col xl={4} lg={10}>
           <Grafico idu={props.idu} nUno={props.nUno} unidad={props.unidad} />           
          </Col>    
              */}          
      </Row>
     }
      </>
      :
      <Row className="loader justify-content-center p-3">
        <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>
        <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
      </Row>    
      } 

      </>
  )  
  //imprime en pantalla cada unidad de la jurisdiccion consultada.
}

export default Jurisdicciones_Escalar;



