import { useEffect,useState,useContext } from "react";
import {Container, Row,  Col, Spinner} from 'react-bootstrap'
import {pathBack, pathBack2, pathDatos } from '../components/paths'

import Context from '../components/context';
import { useParams, useHistory} from "react-router-dom";

import UnidadSerieDatos from '../components/serieDatos/unidadSerieDatos'
import HeaderDatosGaleria from "../components/datos/headerDatosGaleria";
import ContenidoInforme from "../components/datos/contenidoInforme";


function DatosGaleria(props){


const param=useParams()
const id=param.id
const history=useHistory()
const context=useContext(Context);
const [textoDato, setTextoDato]=useState([])
const [pathInformes, setPathInformes]=useState('')

  
useEffect(()=>{
    context.setFooterPath(pathBack2)
    context.setearTitleDoc('Mapa - Datos')   
    window.scrollTo(0,0)
},[])


  useEffect(() => {
    const timer = setTimeout(() => {
        if(!context.galery){
           context.verError() 
        }          
    }, 8000);
    return () => clearTimeout(timer);
  }, []);
  


  useEffect(()=>{
    
    if(context.galery!==null && context.galery!==undefined){
        setTextoDato(context.galery?.introduccion.split("\r\n"))       
        setPathInformes(`${pathDatos}/${context.galery?.codigo}/${context.galery?.fpublicacion.replace('-','').slice(0,6)}`)
        context.setearBreadCrumb([{label:'Home', url:"/"},
        {label:'Datos' , url:'/datos'},{label: context.galery? context.galery?.codigo: null,url:`/datos/${context.galery?.codigo}`} ]) 
    }
    else{      
         context.getGaleriaDatos(id)
    }
  },[context.galery])
   

useEffect(()=>{
    context.setGalery(null)
    context.setearBreadCrumb([{label:'Home', url:"/"},
    {label:'Datos' , url:'/datos'} ])
},[id])

useEffect(()=>{
   
},[context.metaGalery])

    return(
        <>  
          {context.galery  ?
                <>
                    <HeaderDatosGaleria galery={context.galery} metaGalery={context.metaGalery}/>                    
                    <ContenidoInforme textoDato={textoDato} pathInformes={pathInformes} galery={context.galery} metaGalery={context.metaGalery}/>

                    {context.series &&
                    <Container fluid className="seriesDatos bg-primary py-3 my-0 bg-25">
                            <Container className="px-0 my-5">
                                <>
                                    <h3>
                                        Informes Anteriores
                                    </h3>
                                    <Container className="serieCards">
                                        <Row className="mx-0">
                                            {context.series?.map((serie,i)=>{
                                                return(
                                                    <>
                                                    {serie?.datos!== undefined?
                                                        <UnidadSerieDatos key={i} serie={serie} galery={context.galery}/>
                                                        :
                                                        ''
                                                    } 
                                                    </>   
                                                )
                                            })
                                            }                   
                                        </Row>
                                    </Container>
                                </>
                            </Container>
                    </Container>
                    }
                </>
                :
                <>
                    <Row  className="loader justify-content-center p-3">
                        <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>
                        <p className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
                    </Row>            
                </>
          }
        </>
    )
}

export default DatosGaleria;