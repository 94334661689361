import $ from 'jquery';
import {  toast } from 'react-toastify';

//script: estructura_oescalar.php en la línea 106 y en organismos_oescalar.php en la linea 73

//Función para reemplazar las letras ñ por n. 
function rempN(s){
	s = s.replace(/ñ/gi,"n");
	return s;
}

var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','ü':'u', 'Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
function accent_fold (s) {
  if (!s) { return ''; }
  var ret = '';
  for (var i = 0; i < s.length; i++) {
	ret += accent_map[s.charAt(i)] || s.charAt(i);
  }
  return ret;
}//Función que reemplaza letras con acento por letras sin acento.

//Función principal, que compara el texto ingresado en el input con los textos contenidos en elementos <li> dentro de <div id='estru-res'>.
export function buscarUnidad(){	
	var unidades=[]
	var autoridades=[]
	$('.found').removeClass('found');	//Quita la clase 'found' (que agrega resaltado a los elementos que coinciden con la búsqueda) de los resultados de la búsqueda anterior.
	var filtro= document.getElementById('filtrar');	
	var valorBuscado=rempN(filtro.value);	
	autoridades=document.getElementsByClassName('aut')
	unidades= document.getElementsByClassName("liEscalar")
	
	unidades=[...unidades,...autoridades]; 
	
	if(valorBuscado!==''){
	for(let row of unidades){		
		var contenidoUnidad=rempN($(row).text())		
		//Borra los caracteres que conforman los elementos HTML para no ser encontrados en la búsqueda.		
    	if(accent_fold(contenidoUnidad).toLowerCase().indexOf(accent_fold(valorBuscado).toLowerCase())!== -1){
    		row.classList.add('found');			
    	}
    }
	var coincidencias=document.getElementsByClassName('found');
	
	if(coincidencias.length===0){		
		toast.error('No hay coincidencias',{
			position: "bottom-center",
			autoClose: 5000,    
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
	})	
		
	}else{		
		var myElement = document.getElementsByClassName('found')[0];
		var topPos = myElement.offsetTop;		
		document.getElementById('ul_escalar').scrollTop = topPos-100;
		//Scrollea automaticamente hasta el primer elemento que coincide con la búsqueda.
	}	
    return false;
}
}

