import {Container, Row, Col, Button} from 'react-bootstrap'
import {FaSearch} from 'react-icons/fa'


function ReferenciasOrg(props){

    const arrayRefs=['Organismo Desconcentrado', 'Organismo Descentralizado', 'Sociedad del Estado/Otros Entes']

    return(
        <div id="referenciasOrgs" >
         <Row className="refListItems mx-0 my-2 justify-content-between align-items-center mb-2">
               <h4 className="ft-gris03 mb-2">Referencias</h4>          
             <div className="coloresRefs mb-2">
                {arrayRefs.map((li,index)=>{                  
                  return(
                    <div key={index}>
                      <div className="d-flex align-items-center mr-4 refOrgs">
                        <div className={`mr-2 itemO${index} refItem`}></div>
                      <div className="refContent">{li}</div>
                      </div>
                    </div>
                  )
                })}
            </div>
         </Row>
       </div>
    )
 	
}

export default ReferenciasOrg