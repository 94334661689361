import {s03_titulo,s03_0101_p01,s03_0101_p02,s03_0101_p02b,s03_0101_p03,s03_0101_p04, notaAclaratoria} from '../../contenido/index'
import {Container} from 'react-bootstrap' 
import $ from 'jquery'
import axios from 'axios'
import { useState, useEffect} from 'react'
import {pathBack} from '../paths'

function AcercaDe(props){
    const [verMasMenos, setVerMasMenos] = useState('Ver más')
    const [jurisdiccionAcercaDe, setJurisdiccionAcercaDe]=useState(null)
    // Muestra por pantalla la la seccion Acerca de Mapa del estado que se encuentra en el footer

    function handleNota(){
        var nota=document.getElementById('nota')
        if(nota.style.display=='block'){
            // nota.style.display='none'
            $('#nota').slideUp()
        }
        else{
            // nota.style.display='block' 
            $('#nota').slideDown()
        }
        var text = $('#notaVerOcultar').text();
        $('#notaVerOcultar').text( text ==  "Ver más"? "Ver menos" : "Ver más")
        
    }

    useEffect(()=>{
        //Pide dato de jurisdicción de parameters.
        axios.post(pathBack,`db=b&id=4`)
        .then(function(res){
            setJurisdiccionAcercaDe(res?.data?.parameters && res?.data?.parameters[0]? res?.data?.parameters[0].firmaj_alt : 'Ministerio de Desregulación y Transformación del Estado')
        }
        ) 
        .catch(error=>{
          console.log(error)
        }) 
    },[])

    return(
        <div className="cntFlexHome py-5 acerca bg-primary ">
            <div className="contenedor_items">
                <h2 className="ft-lt-black">{s03_titulo}</h2>
                <p className='lead mb-0'><small>{`${s03_0101_p01}${jurisdiccionAcercaDe}.`}</small></p>
                <p className='lead mb-0'><small>{s03_0101_p02}</small></p>
                <p className='lead mb-0'><small>{s03_0101_p02b}</small></p>
                <p className='lead mb-0'><small>{s03_0101_p03}</small></p>
                <p className='lead mb-0'><small>{s03_0101_p04}</small></p>
                {notaAclaratoria.length?
                <div id="nota" className="my-5">
                {
                    notaAclaratoria.map((parraf, i)=>{
                        return(
                            <p className='lead mb-0 mt-1 my-3'>
                                <small>
                                {parraf} 
                                </small>                    
                            </p>
                        )
                    })
                }</div>
                :
                <></>}
                <p onClick={handleNota} id="notaVerOcultar" className="ft-dark my-2">{verMasMenos}</p>
            </div>             
        </div>
    )
}
export default AcercaDe;