import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md'
import $ from 'jquery'
import {Col} from 'react-bootstrap'
import { useEffect, useContext } from 'react'
import Context from '../context';


function Designacion1raAutoridad(props){
    
  const context=useContext(Context);
   
  const formatearFecha=(fecha)=>(`${fecha?.slice(8,10)}-${fecha?.slice(5,7)}-${fecha?.slice(0,4)}`)

  function setearMasNormasDes(){
    $('#masNdesig').css('display','none')
    $('#menosNdesig').css('display','block')
    $('.noneNormaDes').css('display','block')
  }

  function resetMasNormas(){
      $('.noneNormaDes').css('display','none')
      $('#masNdesig').css('display','block')
      $('#menosNdesig').css('display','none')
  }

 
useEffect(()=>{
  
},[])

  return(
      <>
        <Col className="px-2" xl={3} lg={3} md={3} sm={3} xs={4} id="desigAut1 " > 
          {context.normaFicha!==undefined && context.normaFicha!==null && context.normaFicha.length!==0 &&
            <div id="normasFichaUnidad">
                  {context.normaFicha?.map((ndesig,i)=>{ 

                    let linkDesig= ndesig.slice(ndesig.indexOf('('),ndesig.length).replace(/\(/g,'')
                    let pDesig=ndesig.slice(0, ndesig?.indexOf('(')).replace(/\[/g,'').replace(/\]/g,'')

                    return(
                      <div key={i} className={ i<1?'divNormas':'noneNormaDes'}>
                        {ndesig?.indexOf('(')!==-1&& linkDesig!=='' && linkDesig.slice(0,4).toUpperCase()==="HTTP"?
                          <div className=" designaciones">
                            <a rel="noopener noreferrer" className="aDesig" target="_blank" href={linkDesig}>
                              <p className="mb-0">
                              {pDesig}
                              </p>
                            </a>
                            {i==0 && context.normaFicha.length>2&&
                            <div className="mx-2">
                              <div className="d-flex justify-content-center">
                                  <MdKeyboardArrowDown id="masNdesig" title="Ver todas las normas" onClick={setearMasNormasDes}  className="ft-dark buttonMasNormas" size={25}/>
                              </div>
                              <div className="d-flex justify-content-center">
                                <MdKeyboardArrowUp id="menosNdesig" title="Ver menos" onClick={resetMasNormas}  className="ft-dark buttonMasNormas" size={25}/>
                              </div>
                            </div>
                            }
                          </div>
                        :
                          <p className="mb-0">
                            {pDesig}                  
                          </p>
                        }
                      </div>
                    )
                  }
                  )}
            </div>
          }
        </Col>
        <Col className="px-2" xl={2} lg={2} md={2} sm={3} xs={3}>
           <p>
              {props.unidad?.aut_falta!==null?formatearFecha(props.unidad?.aut_falta):''}
           </p>
        </Col>
      </>
    )
}

export default Designacion1raAutoridad;