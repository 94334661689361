import React, { useEffect, useState, useContext } from "react";
import Context from '../components/context';
import { Row, Spinner, Container} from 'react-bootstrap'
import UnidadOrganizativa from "../components/fichaUnidades/unidadOrganizativa/unidadOrganizativa";
import AutoridadesFichaUnidad from '../components/fichaUnidades/autoridadesFichaUnidad'
import { pathBackFicha, pathBack3 } from "../components/paths";
import { useParams} from "react-router-dom";
import $ from 'jquery'
import AccionesFichaUnidad from "../components/fichaUnidades/accionesFichaUnidad"
import HeaderFichaUnidad from '../components/fichaUnidades/headerFichaUnidad'

const FichaUnidad = ({match}) => {

//Retorna una ficha de unidad, que contiene los datos devueltos por la API usando id=17. Se puede llegar a este componente desde una búsqueda en Home o cliqueando una unidad organizativa desde el Organigrama escalar.
  const [bgColor, setBgColor]=useState('')
  const [colorFont, setColorFont]=useState('') 
  const [nombreJur, setNombreJur]=useState('') //se le asigna el nombre del organismo o ministerio al que pertenece la unidad, para agregarlo al breadcrumb
//   const busqueda=match.params.search
  const param=useParams()
  const busqueda=param.search    
  const context=useContext(Context);
  const [unidad, setUnidad]=useState(null)
  const [idAdm, setIdAdm]=useState(null)


useEffect(() => {     
    context.setearRadio('rb-comp')
    sessionStorage.setItem('radio', 'rb-comp')    
    window.scrollTo(0,0)
    if(busqueda){
       //Si se llegó a la ficha por búsqueda de Home, asigna al estado busquedaHome el valor del parámetro 'search' de la URL. 
       context.setearBusquedaHome(busqueda)
    }
  }, [context.busquedaHome]);  //Cada vez que cambia busquedaHome, cambia el breadCrumb


  if(match.params.jur){      
      var minorg= capitalizar(match.params.minorg)
  }
  

  function capitalizar(v){
     //Cambia a mayúscula la primer letra.
     return v.charAt(0).toUpperCase()+v.slice(1)
  }
  

useEffect(()=>{
    if(match.params.search){
      context.setFooterPath(pathBack3)
    }
    else(
      context.setFooterPath(pathBackFicha)
    )
    //Establece el path donde se solicitan los datos del footer, según en que página se esté.
    //Se determina por medio de obtener los parámetros de la URL, que indican si llegó a la ficha de
    //unidad por el escalar o por la búsqueda de Home.
    context.setearTitleDoc('Mapa - Detalle Unidad')
    
},[])//Cada vez que cambia nombreJur, pide los datos de la unidad a la API, y cambia el breadcrumb.
// nombreJur, idJur, context.busquedaHome


useEffect(()=>{    
    if(context.dataFicha?.length!==0){   
      setUnidad(context.dataFicha[0])     
    }
    else{
      setUnidad(null)
      context.getDataFicha(match.params.idu, match.params.search)
    }
},[context.dataFicha])


useEffect(()=>{
    context.setDataFicha([])
},[match.params.idu, match.params.search])


useEffect(()=>{

  if(unidad){
     context.setNormaFicha(unidad?.ndesignacion? unidad.ndesignacion?.split(");") : []) 
     context.setAcciones(unidad?.unidad_funciones ? unidad?.unidad_funciones?.split('\r\n') : [])
    //Divide por cada salto de línea en elementos de un array el campo unidad_funciones y lo asigna a Acciones.  
    setIdAdm(unidad?.idtipoadministracion ? unidad?.idtipoadministracion : '')
        
    if(match.params.jur){
      if(match.params.minorg==='ministerios'){
        setNombreJur(unidad?.jurisdiccion)
        context.setearBreadCrumb(
          [{label:'Home',url:"/", nivel:0},
          {label:minorg , url:`../../../${match.params.minorg}`, nivel:1},
          {label:unidad.jurisdiccion,url:`../../../${match.params.minorg}/${unidad.n1}`, nivel:2},
          {label:unidad.unidad,url:`/${unidad.n1}/${match.params.idu}`, nivel:3}])//Agrega al breadcrumb el string 'Búsqueda'  
      }
      else if(match.params.minorg==='organismos'){        
        setNombreJur(unidad?.unidadsuperior)
        context.setearBreadCrumb(
            [{label:'Home',url:"/", nivel:0},
            {label:minorg , url:`../../../${match.params.minorg}`, nivel:1},           
            {label:unidad?.unidadsuperior,url:`../../../${match.params.minorg}/${unidad?.idunidadsuperior}`, nivel:2},
            {label:unidad?.unidad,url:`/${unidad?.idunidadsuperior}/${match.params.idu}`, nivel:3}])//Agrega al breadcrumb el string 'Búsqueda'
      }
    }
    else if(match.params.search){
      //Si existe el parámetro 'search' en la url, significa que se llegó desde una búsqueda en Home. Agrega el elemento actual al breadcrumb.   
      context.setearBreadCrumb([{label:'Home',url:"/"},
      {label:`Búsqueda: ${match.params.search.replace(/-/g,' ')}` , url:`/busqueda/${match.params.search}`},{label:unidad?.unidad, url:`/busqueda/${context.busquedaHome}/${match.params.idu}`}])
    }
  }
},[unidad])


useEffect(()=>{
    if(idAdm!==null){
      changebgColor(idAdm)
      changeFont(idAdm)
    }
}, [idAdm])
  
  //Crea un array a partir del dato ndesignacion, obtenido por API, usando como separador de elementos ']('
 

  function changeFont(idta){  
    var color=null
    if(idta!==''){
       switch(idta>3?4:idta){
        case 1: color='ft-dark'   
        break;
        case 2: color='ft-dark'
        break;
        case 3: color='ft-aero'
        break;
        case 4: color='ft-gris'       
     }
   }
   else{
       color=''
   }
   setColorFont(color)
  }



  function changebgColor(idta){    
    var color2=null
    let ft=0
    if (idta>3){
        ft=4
    }
    else{
        ft=idta
    }
    if(idta!==''){
      switch(ft){
        case 1:  color2='bg-ac'
        break;
        case 2 : color2='bg-ac'
        break;
        case 3:  color2='bg-aero'
        break;
        case 4:  color2='bg-gris'       
      }
    }
    else{
      color2=''
    }
    setBgColor(color2)
  }


  window.addEventListener("afterprint", function(event) {if($('#masAutoridades').css('display')=='block'){
   $('#masAutoridades').css('display', 'none')
   $('.noneNormas').css('display', 'none')     
  } 
    });
  


return(  
      <div id="contenedor_f" className="bg-primary bgFicha" >
        { context.dataFicha.length ?             
              <div className="container py-5 containerFicha">
                  <div className="busquedaUnidad py-2 bg-shadow4">
                    {unidad  &&                  
                        <>
                          <HeaderFichaUnidad unidad={unidad} bgColor={bgColor? bgColor : ''}/>
                          <UnidadOrganizativa unidad={unidad} bgColor={bgColor? bgColor : ''} colorFont={colorFont? colorFont: ''}/>
                          <AutoridadesFichaUnidad unidad={unidad} colorFont={colorFont?colorFont:''} bgColor={bgColor? bgColor:''} />                           
                          <AccionesFichaUnidad colorFont={colorFont} bgColor={bgColor}/>                         
                        </>
                    }
                  </div>
              </div>          
            :
            <Row className="loader justify-content-center p-3">          
              <Spinner animation="border" style={{color: "#9fb7cf"}} size="md" />
            </Row>              
            } 
    </div>
)

}

export default FichaUnidad;