import { useEffect, useState, useContext} from "react";
import Header from '../common/header'
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import {pathBack, pathBack1, pathOrganigrama} from '../components/paths'
import {Link} from 'react-router-dom';
import Context from '../components/context';
import {FiDelete} from 'react-icons/fi'
import {headTitulo, headVolanta, autoridades} from '../contenido/ministerios'
import {altNormativa,altDesig,altWeb,altCSV,altOrgPDF, altEsc, altXLS} from '../contenido/alt'
import $ from 'jquery'
import {useHistory} from 'react-router-dom'
import {db} from '../../configuracion/environment'
import axios from 'axios'
import { pathIconos } from "../components/paths";

function Ministerios(props) {

 const context=useContext(Context);   
 const body=`db=${db}&id=4&tl=vi`
 const [resultado, setResultado]=useState([]);
 const arrayChars=['(','[',']','¨','º','~',';',':','´','@','|','!','¡','¿','·','%','&',',','>','<','?','*','+','^','$','{','}',')','\\','/']
 const [msj2, setMsj2]=useState('dispnoneMsj')
 const history=useHistory()
 const [listajuris, setListaJuris]=useState([])

 useEffect( ()=>{

  context.setResBusqueda(null)
      context.setearTitleDoc('Mapa - Ministerios')
      context.setearE404(false) 
      context.setearBreadCrumb([{label:'Home',url:"/", nivel:0},
      {label:'Ministerios' , url:'/ministerios', nivel:1}])
      context.resetValues();
      context.resetMinJurNor();      
      if(listajuris?.length===0){
        axios.post(pathBack, body)
        .then((res)=>{
          setListaJuris(res.data?.datos)
        })
        .catch(e=>console.log(e))
      }
      else if(context.searchValue!==''){
        filtrarLista()
      }
      else{
        setResultado(listajuris)
      }
      context.setearRadio('rb-comp')
      sessionStorage.setItem('radio', 'rb-comp') 
      context.setearTN(); //Predetermina el Tipo de Normativa como 'Busqueda Avanzada' para la vista Normativas
      if(context.dataError==0){
        history.push('/database_error')
       }
      },[listajuris, context.searchValue])
       
 
        var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
        function accent_fold (s) {
          if (!s) { return ''; }
          var ret = '';
          for (var i = 0; i < s.length; i++) {
          ret += accent_map[s.charAt(i)] || s.charAt(i);
          }
          return ret;
        };//Función que reemplaza letras con acento por letras sin acento.


        
        useEffect(() => {
          window.scrollTo(0,0)
          context.setearSearchValue('')
          const timer = setTimeout(() => {
              if(resultado.length==0){
                 if(context.dataError==0){
                  history.push('/database_error')
                 }
                 else{
                   context.verError()
                 }                  
              }          
          }, 8000);
          return () => clearTimeout(timer);
        }, []);
        

    function filtrarLista(){
      var array=listajuris?.filter(min=>accent_fold(min?.unidad+' '+min?.autoridad).toLowerCase().indexOf(accent_fold(context?.searchValue).toLowerCase())!== -1)
        setResultado(array)
        if(array.length==0){
          setResultado([]) 
        }
    }

    function remove(){ 
      var filtro= document.querySelector('#filtrarTextoOrg');
      filtro.value="";
      var remove=document.querySelector('#delete');
      remove.style.display="none";
      context.setearSearchValue('')    
      setResultado([])
    }

    function createDelete(){         
      var filtro= document.querySelector('#filtrarTextoOrg');  
        if(filtro?.value!==undefined){
          if(filtro.value!==''){
            document.querySelector('#delete').style.display="inline";
          }
          else{
            document.querySelector('#delete').style.display="none";
          }      
      }
      else{
            document.querySelector('#delete').style.display="none";
      }
      return false;
    }

    const changeTexto=(e)=>{
        const valor=e.target.value;
        if(arrayChars.includes(valor[valor.length-1])==true ){
          setMsj2('displayMsj')
        }
        else{
            createDelete()
            context.setearSearchValue(valor); 
            setMsj2('dispnoneMsj')
        } 
    }


  return (
    <>
    <Header h1={headTitulo} txt={headVolanta}/>
    <div className="ft-lt-black  infoGralMin py-3 my-0  ">
        <Row className='justify-content-center align-items-start mx-0 px-2'>
            <Col xl={3} sm={12} className="mx-4 text-center mIcons">
              <a target="_blank" rel="noopener noreferrer"
               href={`${pathOrganigrama}${autoridades}.pdf`}>
               <div>
                  <img src={`${pathIconos}/icon_organigrama.svg`} className="d-inline" width="80px"
                  alt='Organigrama APN - Administración Central'
                  title='Organigrama APN - Administración Central'
                  /> 
                  <div>
                    <p>Organigrama APN - Administración Central</p>
                  </div> 
                </div>               
              </a>
            </Col>
            <Col xl={3} sm={12} className="mx-4 text-center mIcons">
              <a href={`${pathBack}db=${db}&id=9&fi=xls`} rel="noopener noreferrer" >
                <img width= "80px" src={`${pathIconos}/icon_xls.svg`}
                title={altXLS}
                alt={altXLS}/>
                <div>
                  <p>{altXLS}</p>
                </div>
              </a> 
            </Col>  
            <Col xl={3} sm={12} className="mx-4 text-center mIcons">
              <a href={`${pathBack}db=${db}&id=9&fi=csv`} rel="noopener noreferrer" >
                <img width= "80px" src={`${pathIconos}/icon_csv.svg`}
                title={altCSV}
                alt={altCSV}/>
                <div>
                  <p>{altCSV}</p>
                </div>
              </a> 
            </Col>  
                 
        </Row>                                          
     </div> 
    <Container fluid className="py-5 bg-primary contMins">
      

    <div id="estructura" className="justify-content-center px-0">
      <div className="my-4 justify-content-center contBuscadorMins">
        <div className="colBuscadorOrg">
              <div className="bg-white d-flex justify-content-between buscadorinputOrg">
                  <input
                  id="filtrarTextoOrg"
                  type="text"                
                  placeholder="&#xf002;  Buscar por Ministerio y Autoridad"
                  aria-label="Buscar por Ministerio"
                  onChange={changeTexto}
                  value={context.searchValue}            
                  />
                  <div className={msj2}>No admite caracteres especiales</div>
                  <div id="delete" className="pt-1 pr-3">
                      <FiDelete size={20} onClick={remove}/>
                  </div>
              </div>
        </div>
      </div>
      <div className="contlistamin">
            {resultado?.length?
            <ul className="pl-0 listaMin">
              {resultado?.map((elem,i)=>
              <div key={i}>
                {elem?.n1=='000' &&
                  <>
                  </>
                }
                {i==2 &&
                    <Row className="mx-0">
                         <h3 className="ml-1">MINISTERIOS</h3>
                    </Row>
                 }
                <Row key={i} className="mx-0 liEstructura bg-white py-3 my-2 align-items-center">
                  <div className="unidadlistamins">
                  <Link  to={`/ministerios/${elem?.n1}`}>
                    <li>
                      <strong className="ft-dark">{elem?.unidad?.toUpperCase()}</strong>                    
                    </li>  
                  </Link>
                    <p className="ft-lt-black autju my-0"><strong>{elem?.autoridad}</strong></p>
                  </div>
                  <div className="text-right iconsMin">
                    <a target="_blank" rel="noopener noreferrer" title={altOrgPDF} href={`${pathOrganigrama}${elem.n1}.pdf`}>
                      <img  src={`${pathIconos}/icon_organigrama.svg`} className="d-inline" width= "45px" alt={altOrgPDF}/>                 
                    </a>
                    <Link to={`/ministerios/${elem.n1}`}>
                      <img  width= "45px" src={`${pathIconos}/icon_estructura.svg`} title={`Organigrama Escalar de ${elem.nombre_corto}`} alt={`${altEsc} ${elem.nombre_corto}`}/>
                    </Link>         
                    <Link to={`/normativas/estructura/${elem.n1}`} title={altNormativa} alt=        {altNormativa} >
                        <img  width= "45px" src={`${pathIconos}/icon_normativa.svg`} />
                    </Link>
                    <Link to={`/normativas/designaciones/${elem.n1}`} title={altDesig} >                 
                        <img  width= "45px" src={`${pathIconos}/icon-designaciones_fondo.svg`} />
                    </Link>
                    <a href={`${pathBack}db=${db}&id=9&fi=xls&n1=${elem?.n1}`} rel="noopener noreferrer" >
                      <img width= "45px" src={`${pathIconos}/icon_xls.svg`}
                      title={altXLS}
                      alt={altXLS}/>                 
                      </a> 
                    <a href={elem.web} rel="noopener noreferrer" target="_blank" title={`Sitio Web de ${elem?.nombre_corto}`}>
                        <img  width= "45px" src={`${pathIconos}/icon_web.svg`} alt={altWeb}/>
                      </a>
                  </div>             
                </Row>
               </div>      
                 )} 
            </ul>
            :
            <>
            {
              context.searchValue=='' && resultado?.length===0 &&
            <Row className="loader justify-content-center p-3">
               <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>
               <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>
            </Row> 
            }
            </>
            }
            {context.searchValue!=='' && resultado?.length===0 &&
            <div className="alert alert-warning p-2">Sin resultados</div>
            }
          </div>
        </div> 
        </Container>
        </>
  )
}

export default Ministerios;