import { useContext} from "react";
import Context from '../context';


function NormativaBox(props) {
  
    //Retorna un bloque con la información obtenida de cada registro devuelto por la API con los parámetros 
    const normativa=props.normativa;

    const arrayTag=props.arrayTag

    return (
    <div className="normativa my-4" >
        <h4>{normativa?.jurisdiccion}-{normativa?.norma_tipo}-{normativa?.numero}<span className="mx-3">-</span>{normativa?.fechapub}</h4>
        {normativa?.abstract ?
         <p>{normativa?.abstract}
         {normativa.abstract?.length==500?
            <span>...</span>
            :
            null            
         }
         </p>
         :
          <p>{normativa?.detalle && normativa?.detalle?.length<500 ? normativa.detalle : normativa.detalle+'...'}</p>
          }              
        <span class="ampliar">{normativa?.url_bo!==null && <a href={normativa?.url_bo} target='_blank'>Boletín Oficial</a>}<> </></span>
        <span class="ampliar">{normativa?.url_infoleg!==null && <a href={normativa?.url_infoleg} target='_blank'>- InfoLeg</a>}</span><br/>         
        {arrayTag?.map((tag,index)=>{
              if(index!==0){
                return(
                  <span key={index} className="tags">
                  #{tag}<> </> 
                  </span>            
                ) }  
              } )
            }
    </div >
  )
  
}

export default NormativaBox;