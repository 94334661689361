import { pathIconos } from '../paths'
import { Spinner} from 'react-bootstrap'
import { pathOrganigrama, pathBack} from '../paths'
import {Link} from 'react-router-dom';
import {altOrgPDF, altEsc, altXLS} from '../../contenido/alt'

import {db} from '../../../configuracion/environment'

function OrganismoBox(props) {
//Retorna una tarjeta con la información de un organismo obtenida de la API. 


    const item=calcReferencia(props.idTipo-1)

    function calcReferencia(num){
      //Si el numero que ingresa es mayor o igual a 4 retorna 3. De acuerdo a este numero será la clase que se asigna al footer de la tarjeta, variando el color.
        if(num>=4){ return 3}
        else return num
    }

    return (
        <>
        {props.unidad? 
        <div className="my-3 bg-shadow px-0 divOrg">
        <div className=" orgBox  px-0 m-0 p-0" >
            <div className="tituloOrg p-3">          
                <div className="autOrg">
                <p className={`unidadOrg${props.length} mb-1`}>{props.unidad.toUpperCase()}</p>     
                <p className="ft-lt-black mb-1 pAut">{props.aut}</p>
                </div>        
            </div> 
            <div className="datosOrg p-3">
                <div>
                    <p className="ft-light"><strong>Jurisdicción: {props.jur}</strong></p>      
                    <p className="ft-light">Reporta a: {props.repo}</p>
                    <p className="ft-light">Sigla: {props.sigla}</p>                    
                    <p className="ft-light">{props.tipo}</p>
                </div>
                <div className="mt-2">
                    {props.web &&
                  <a target="_blank" rel="noopener noreferrer" href={props.web}>
                    <img  width="45px" src={`${pathIconos}/icon_web.svg`} title={`Sitio Web de ${props.unidad}`} alt={`Sitio Web de ${props.unidad}`}/>
                  </a>}
                 {props.escalar=='S' &&
                  <Link to={`/organismos/${props.link}`}>
                    <img  width= "45px" src={`${pathIconos}/icon_estructura.svg`} title={`${altEsc} ${props.unidad}`} alt={`${altEsc} ${props.unidad}`}/>
                  </Link>
                  }
                  {props.escalar=='S' &&
                    <a href={`${pathBack}db=${db}&id=14&fi=xls&idu=${props.link}`} rel="noopener noreferrer" >
                    <img  width= "45px" src={`${pathIconos}/icon_xls.svg`}
                    title={altXLS}
                    alt={altXLS}/>                 
                    </a> 
                  }
                  {props.orgpdf=='S' &&
                  <a target="_blank" rel="noopener noreferrer" href={`${pathOrganigrama}${props.organigrama}.pdf`}>
                    <img src={`${pathIconos}/icon_organigrama.svg`} width= "45px" title={altOrgPDF} alt={altOrgPDF}/>                 
                  </a>
                  }

                </div>
            </div>
            
        </div>
         <div className={`footerOrgBox bb${item}`}></div>
         </div>
        : <Spinner className="m-3" animation="grow" style={{color: "#9fb7cf"}} size="sm"/>}
            </>
            )       

}

export default OrganismoBox;