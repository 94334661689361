import {title2} from '../contenido/databaseError'
import {Container, Row, Col} from 'react-bootstrap'
import { useEffect } from "react";


function DatabaseError(props){
 

    useEffect(()=>{
        
        window.scrollTo(0,0)
    },[])


return(
<Container className="pagNoDisp">
    <Row className="mx-0 pndRow py-3">
        <Col className="logoPagND px-5" xl={6} lg={6} md={6} sm={12} xs={12}>
            <div>
                <h2 className="ft-37bb my-4">{title2}</h2>
            </div>
        </Col>
        <Col className="align-items-center d-flex px-5 firmaJgm" xl={6} lg={6} md={6} sm={12} xs={12}>
           <div className='ft-gris03'>
               <p>
                   Jefatura de<br/>
                   Gabinete de Ministros<br/>
                   <strong>Argentina</strong>
               </p>
           </div>
        </Col>
    </Row>
</Container>
 )   

}

export default DatabaseError;