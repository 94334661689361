import { Doughnut, Bar} from 'react-chartjs-2';
import React from "react";
import {Link, useHistory} from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import annotationPlugin from 'chartjs-plugin-annotation';
import Context from '../components/context';
import {Chart, ArcElement, Tooltip, Legend, BarElement,Title, CategoryScale, LinearScale} from 'chart.js'
import { useEffect, useContext, useState } from "react";
//import PiramideCargos from './piramideCargos'

function Grafico(props){
  const context=useContext(Context); 
  Chart.register(ChartDataLabels); //Necesario para la librería de los Labels que están dentro de las barras.
  // Chart.register(annotationPlugin); //Necesario para la librería que sombrea un área del gráfico. 
  Chart.register(ArcElement);
  Chart.register(ArcElement, Tooltip, Legend, Title, LinearScale, CategoryScale, BarElement);
  var titulo= Chart.defaults.plugins.title
  titulo.color='#767f86'
  titulo.font.size=15
  var fem=context.porcentajeFem!==null ? context.porcentajeFem.toFixed(0) : null
  var masc=context.porcentajeMasc!==null ? context.porcentajeMasc.toFixed(0) : null
  useEffect(()=>{context.getPortalNumerosGraf()},[])
  const tituloGrafGen= `Distribución por género ${context.tipoVista!=='' && props.idu==false? 'de '+context.tipoVista : ''}`
  const data = {
    labels: ['Femenino', 'Masculino'],
    datasets: [
      {        
        data:isNaN(fem)==true&&isNaN(masc)==true? [0,0] : [fem, masc] ,
        backgroundColor:['#9283be', '#37bbed']
      },
    ],
  };
  useEffect(()=>{},[context.porcentajeFem, context.porcentajeMasc, fem, masc, context.disclaimerGraf, data])
  return(
    <>
      {/*
      <div id="containerGraf"> 
        {isNaN(fem)==true&&isNaN(masc)==true?'':
        <div id="containerDoughnut" className={`my-2 py-3 ${context.w100 == true? 'w100' : ''}`}>
          <div className="d-flex flex-column flex-doughnut">
            <div>
              <p className="grafDetalle mb-1 ft-bold" >{props.unidad.jurisdiccion!==undefined && props.unidad.jurisdiccion!==null? props.unidad.jurisdiccion: props.unidad}</p>
              <p className="grafTitle">{tituloGrafGen}</p>
              <div className="graf my-2">
                <Doughnut  data = {data} options={{
                  maintainAspectRatio: false,
                  plugins:{                     
                    title:{               
                      padding:{
                      top: 0,
                      bottom: 0,
                      },                    
                      text: tituloGrafGen, display:false,
                      labels: {
                        font: {
                          family: 'Verdana',
                          color:'#444'
                        }
                      }
                    },
                    legend: {
                      display:true,
                      position: 'right',
                    },
                    datalabels: {
                      formatter: function(value) { 
                        if(value!==undefined){
                          return value!=='0' && value!== 0 && (isNaN(value)!==true)? value+'%' : null;
                        }                        
                      },
                      display: true,
                      color: '#fff',               
                    },
                  }
                }}
                />
              </div>
              <p className="grafDetalle mb-1"><small>Porcentajes sobre el total de cargos ocupados.</small></p>
            </div>
            {context.disclaimerGraf!==null &&
              <div className="my-3">
                    <p id="disclaimGraf" className="ft-grey font-Encode mx-3">
                      <small>{context.disclaimerGraf}</small>
                    </p>
              </div>
            }
          </div>
        </div>
        }
          {context.radio=='rb-comp' && props.idu==false &&
            <div className="grafBar my-4 py-3">
            <div className="d-flex flex-column flex-piramide">
              <div>
                <p className="grafDetalle my-1 ft-bold" >{props.unidad.jurisdiccion!==null && props.unidad.jurisdiccion!==undefined? props.unidad.jurisdiccion : props.unidad}</p>
                <p className="grafTitle">Pirámide de Niveles Organizacionales</p>

                <PiramideCargos idu={props.idu} nUno={props.nUno}/>   
                <p className="grafDetalle mb-1"><small>Total de unidades organizativas (UO) por nivel.</small></p>
              </div>       
              {context.disclaimerGraf!==null &&
              <div className="my-3">
                    <p id="disclaimGraf" className="ft-grey font-Encode mx-3">
                      <small>{context.disclaimerGraf}</small>
                    </p>
              </div>
              }
                </div>    
            </div>
            }
      </div>*/}
    </>
    )
}

export default Grafico;