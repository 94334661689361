import {label_apellido} from '../../contenido/normativaFiltros'
import {Container, Row, Col} from 'react-bootstrap'
import { useEffect,useContext } from "react";
import Fecha from '../fecha'
import Context from '../context';
import {pathBack} from '../paths'
import $ from 'jquery';
import {db} from '../../../configuracion/environment'
import BuscarButton from '../buscarButton'
import SelectJurisdiccion from './selectJurisdiccion';
import LimpiarBusquedaButton from '../limpiarBusquedaButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DesignacionesFiltros(props) {
 
const query=`db=${db}&id=8&ob=nd`;

const handleReset=()=>{
  
  context.setMsj4('dispnoneMsj')
  //Al cliquear Limpiar Búsqueda se resetean los valores de los inputs.
  $('#textDes').val('')
  $('#dni').val('')
  $('#from').val('')
  $('#to').val('')
  var select = $('#n1');
  select.val(select.children('option:first').val());  
  context.resetValues()
}


const context=useContext(Context);

  useEffect(()=>{ 
    $(window).click(function() {
      context.setMsj4('dispnoneMsj')
    });
  
    if(context.newParam && context.newParam.length!==0){       
      context.concatParam(context.newParam)//Concatena a la URL un nuevo parámetro. Manda como argumento el ultimo elemento del array.
    } 
    if(context.newParam===null || context.newParam.length===0){  
      context.setearBusquedaDesig()//Deja de mostrar datos y vuelve al buscador de designaciones.
    }


}, [context.newParam])//Cada vez que se cambia un parámetro de búsqueda, se suma el nuevo valor al array newParam, y se concatena al string de la URL, URLbuild. 



function handleSubmitDes(){
  if(context.URLbuild!==query){    
     context.setearParametros(context.newParam)
     context.fetchNormativas(pathBack, context.URLbuild)
  }
  else{
    toast.error('Debe seleccionar al menos un filtro',{
			position: "bottom-center",
			autoClose: 5000,    
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			})
  } 

 }//Si la URL formada es distinta de la inicial, almacena el valor de cada parámetro de búsqueda en un estado. Hace pedido a la api utilizando la URL formada. Si no hay parametros de búsqueda, sale un alert. 

 
   return (

   <Container className="py-3">

      <Row className=" mx-0 textDesRow align-items-center justify-content-between">
          <Col className="mx-0 px-0 textDesCol " xs={12} sm={12} lg={7} xl={6}>
              <input type="text" 
              id="textDes" 
              class="form-control input-lg" 
              name="detalle" 
              value={context.TN==='df'?context.textDes:''} 
              autocomplete="off" width="125" 
              placeholder={label_apellido} 
              onChange={e=>context.setearTextDes(e.target.value)}/>
              <div className={context.msj4}>No admite caracteres especiales</div>
          </Col>
      </Row>
      <Row className="pb-3 mx-0 align-items-end rowTags">
            <Col className="mx-2 colTags dniCol" lg={2}>
                <input type='number'
                class="form-control input-lg"
                id="dni" name="dni"
                value={context.dni} 
                autocomplete="off" width="50"
                placeholder='DNI' 
                onChange={e=>context.setearDni(e.target.value)}/> 
            </Col >  
            <Col lg={3} sm={12} className="mx-2 juriDesig">        
                <SelectJurisdiccion/>
            </Col> 
            <div  className="fechasDes px-0">
              <Fecha/>
            </div>
      </Row>
      <Row className="pb-3 px-0 col-lg-6 col-sm-9 mx-0 my-3"> 
        <BuscarButton customClickEvent={handleSubmitDes}/>
        <div className="mx-2">
          <LimpiarBusquedaButton customClickEvent={handleReset}/>
        </div>
      </Row>
      <ToastContainer
      position="top-center"
      autoClose={5000}
      toastStyle={{ backgroundColor: "#e7c5c5" }}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />   
   </Container>
   )
 }
 
 export default DesignacionesFiltros;