import Header from '../common/header'
import ListaOrganismos from '../components/organismos/listaOrganismos'
import {pathBack,pathOrganigrama,  } from '../components/paths'
import { useEffect, useState, useContext } from "react";
import Context from '../components/context';
import {headTitulo, headVolanta, autOrganismos} from '../contenido/organismos'
import {Container, Col, Row, Spinner} from 'react-bootstrap'
import $ from 'jquery'
import { useHistory } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import {FiDelete} from 'react-icons/fi'
import {altCSV, altOrgPDF, altXLS} from '../contenido/alt'
import ReferenciasOrg from '../components/organismos/referenciasOrg'
import LimpiarBusquedaButton from '../components/limpiarBusquedaButton';
import {db} from '../../configuracion/environment'
import { pathIconos } from '../components/paths';

function Organismos(props) {


  const arrayChars=['(','[',']','¨','º','~',';',':','´','@','|','!','¡','¿','·','%','&',',','>','<','?','*','+','^','$','{','}',')','\\','/']
 const [msj3, setMsj3]=useState('dispnoneMsj')
 const context=useContext(Context);   
 const body=`db=${db}&id=4&tl=vi`
 const [resultado, setResultado]=useState(false);
   

useEffect(() => {
  var select = $('#selectJurorg');
  select.val(select.children('option:first').val());
  var select = $('#selectTipoOrg');
  select.val(select.children('option:first').val());
  // window.scrollTo(0,500)
  $(window).click(function() {
    setMsj3('dispnoneMsj')
  });
 
 
}, []);


 useEffect(()=>{
  window.scrollTo(0,0)
  context.setResBusqueda(null)
  context.setMsj3('dispnoneMsj')
  context.setMsj4('dispnoneMsj')
  context.setearTitleDoc('Mapa - Organismos')
  context.setearRadio('rb-comp') 
  sessionStorage.setItem('radio', 'rb-comp')
  context.setearE404(false)

  context.setearBreadCrumb([{label:'Home',url:"/", nivel:0},
  {label:'Organismos' , url:'/organismos', nivel:1}])
  //Agrega al breadcrumb el string 'Organismos'
  context.resetMinJurNor();
  if(context.organismos?.length==0){
     context.getOrganismos('orgs');//Pide a la api todos los organismos, ordenados alfabéticamente
  }
  if(context.listaMin?.length===0){
     context.fetchMinisterios(pathBack, body);
  }   
 
  context.fetchTa()
  context.resetValues() 
  context.setearTN();
 
  },[])

  

  function createDelete(){
    var filtro= document.querySelector('#filtrarTextoOrg');  
      if(filtro.value!==undefined){
        if(filtro.value!==''){
          document.querySelector('#delete').style.display="flex";
        }
        else{
          document.querySelector('#delete').style.display="none";
        }      
    }
    else{
      document.querySelector('#delete').style.display="none";
    }
    return false;
  }


  function remove(){ 
    var filtro= document.querySelector('#filtrarTextoOrg');
    filtro.value="";
    var remove=document.querySelector('#delete');
    remove.style.display="none";
    context.setearSearchValue('')    
    setResultado(false)
  }


  const changeTexto=(e)=>{
    const valor=e.target.value; 
    if(arrayChars.includes(valor[valor.length-1])==true){
      setMsj3('displayMsj')
    }
    else{
     setMsj3('dispnoneMsj')
     createDelete()    
     context.setearSearchValue(valor);
    }   
  }


  const handleReset=(e)=>{
    e.preventDefault()
    var select = $('.selects');
    select.val(select.children('option:first').val());
    context.setearJurOrganismos([])
    context.setearSearchValue('')
    context.setearFiltro(false)
    document.querySelector('#delete').style.display="none";
    context.setTadmOrg([])
    sessionStorage.removeItem('jurOrganismos')
    sessionStorage.removeItem('tadmOrg')   
  }

      return (   
      <>
      <Header h1={headTitulo} num={'claro'} txt={headVolanta}/>
      <Container fluid className="bg-primary mx-0 px-3 py-3 infGralOrgs">
           <div className="row mx-0 align-items-start 
           orgsInfGralIcons justify-content-center">           
             <Col xl={3} sm={12} className="text-center">
             <a rel="noopener noreferrer"
              target="_blank" 
               href={`${pathOrganigrama}${autOrganismos}.pdf`}>
                <div>
                    <img src={`${pathIconos}/icon_organigrama.svg`} className="d-inline" width= "80px"
                     alt={altOrgPDF}
                     title={altOrgPDF}
                     /> 
                    <div className="mx-3">
                     <p>{altOrgPDF}</p>  
                    </div>   
                </div>              
             </a>
             </Col>
             <Col xl={3} sm={12} className="mx-4 text-center mIcons">
              <a href={`${pathBack}db=${db}&id=14&fi=xls`} rel="noopener noreferrer">
                <img width= "80px" src={`${pathIconos}/icon_xls.svg`}
                title={altXLS}
                alt={altXLS}/>
                <div>
                  <p>{altXLS}</p>
                </div>
              </a> 
            </Col>  
             <Col xl={3} sm={12} className="text-center">
             <a rel="noopener noreferrer" href={`${pathBack}db=${db}&id=14&fi=csv`}>
                    <img src={`${pathIconos}/icon_csv.svg`} width= "80px"
                     title={altCSV}
                     alt={altCSV}/>
                     <div>
                      <p>{altCSV}</p>
                    </div>                 
             </a>
             </Col>       
           </div>
      </Container>
      <Container className="mt-2 pt-2 titulo_org_container">
        <ReferenciasOrg/>
      </Container>
      <article className="container px-2 py-4 mb-4">
          <form className=" contenedorFiltrosOrganismos">            
              <div className="flexSelectsOrg">      
                  <select className="buscadorinputOrg selectJuriOrg selects" id="selectJurorg" onChange={e=>{                    
                    if(e.target.value!==""){
                      context.setearJurOrganismos(e.target.value.split('/'))
                    }
                    else{
                      context.setearJurOrganismos([])
                    }
                    }}  >
                    <option value="">-- Jurisdicción --</option>
                        {context.listaMin?.length!==0 &&
                          <>
                           {context.listaMin?.map((jurisdiccion, index)=>{
                            return(
                            <option key={jurisdiccion?.unidad} value={`${jurisdiccion?.unidad}/${jurisdiccion?.n1}`}>{jurisdiccion?.unidad}</option>
                            )
                        })}
                        </>
                        }
                  </select>                
                  <select className="buscadorinputOrg selects selectTipoAdm" id="selectTipoOrg" onChange={e=>{
                                       
                    if(e.target.value!==""){
                      context.setTadmOrg(e.target.value.split('/'))
                      sessionStorage.setItem('tadmOrg', JSON.stringify(e.target.value.split('/')))
                    }
                    else{
                      context.setTadmOrg([])
                      sessionStorage.removeItem('tadmOrg')                      
                    }
                    
                    }} 
                    
                    >
                    <option value="">--Tipo de Administración--</option>
                        {context.tipoOrganismos?.map((tipoOrganismo)=>{
                            return(
                            <option value={`${tipoOrganismo?.descripcion}/${tipoOrganismo?.id}`} key={tipoOrganismo?.descripcion}>{tipoOrganismo?.descripcion}</option>
                            )
                        })}
                  </select> 
                  <LimpiarBusquedaButton customClickEvent={handleReset}/>
                </div>            
                
                <div className="col-md-12 px-0 bg-white colBuscadorOrg mt-2">
                  <div className="bg-white d-flex justify-content-between buscadorinputOrg">
                      <input
                        id="filtrarTextoOrg"
                        type="text"
                        placeholder="&#xf002; Nombre de Organismo/Ente"
                        aria-label="Buscar por Organismo"               
                        onChange={changeTexto}
                        value={context.searchValue}            
                        />
                    <div className={msj3}>No admite caracteres especiales</div>
                    <div id="delete" className=" pr-3">
                            <FiDelete size={20} onClick={remove}/>
                    </div>
                  </div>
                </div>
          </form>
      </article>
      <>  {context.organismos?.length!==0 ?       
             <ListaOrganismos busqueda={context.searchValue} ta={context.tadmOrg}/>
             :
             <Row className="loader justify-content-center p-3">
             <Spinner className="hideMe" animation="border" size="md" style={{color: "#9fb7cf"}}/>    
             <p  className="showErr p-3 ft-gris03">Ocurrió un error. Intente nuevamente.</p>        
            </Row>
          }
      </>
     </> 
  )
    

}

export default Organismos;







      