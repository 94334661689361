import React from 'react'
import {Link} from 'react-router-dom';
import Context from '../../components/context';
import {useContext} from 'react'
import {pathImgPerfil } from '../../components/paths'
import {sinFoto} from '../../contenido/constantes'

const Ministerios = () => {

  const context=useContext(Context);
  const mins= context.listaMinHome? context.listaMinHome.filter((unidad)=>unidad.n1>'001') : []

//ORDENA LOS MINISTERIOS CON UN ARRAY 
//   const sortMins=(mins)=>{
//     var customOrder= ['026', '011', '005', '002', '010', '004', '003', '014']
//     var sortArray=[]
//     mins?.forEach((elem, i)=>{
//         var indice = customOrder.length? customOrder.indexOf(elem.n1) : i
//         if(indice!==-1){
//           sortArray[indice]=elem
//         }    
//     })  
//     return sortArray 
//   }

  function handleSrc(e){
    e.target.src=`${pathImgPerfil}/${sinFoto}.png`
  }

  return (
        <div className="fullwidth">   
            <div className="d-flex justify-content-center homeMins">                    
                {mins?.map((elem,indice)=>{ 
                    
                    const estiloH3 = elem?.unidad?.length < 50 ? 'fontSizeMayor' : 'fontSizeMenor' // Cambia el tamaño de la fuente según la longitud del texto               
                    {/* Ministerios */}
                    
                    const nombreImg=  elem?.versionImgP ? `${elem?.n1}.png?v=${elem?.versionImgP}` : `${sinFoto}.png`

                    return( 
                        <div key={indice} className="bg-white d-flex bg-shadow4 p-1 col04" >
                            <Link className={`${elem?.clase} linkhome`} to={`ministerios/${elem?.n1}`}>
                                <div className="imgFlexHome" >  
                                    <img 
                                     onError={handleSrc}
                                    className="imgMin" src={`${pathImgPerfil}/${nombreImg}`}
                                    title={elem?.unidad} />
                                </div>
                                <div className="textColumn text-left px-0 py-0">
                                    <h3  className={`${estiloH3} jurbox bg-oscuro`}>
                                        {elem?.unidad}
                                    </h3>
                                    <h4 className="px-2 minAut jurboxmin ft-lt-black">
                                        {elem?.autoridad}
                                    </h4>                             
                                </div>
                            </Link> 
                        </div> 
                    )  
                })
                } 
            </div> 
        </div> 
  )
}

export default Ministerios