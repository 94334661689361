import { useEffect} from "react";



function Header(props){


	useEffect(()=>{

	},[props.num, props.h1])//Se actualiza el componente cuando varían las props num y h1.

	

return(   
    <header>
	<div className={`py-3 Header bg-${props.num}`}>
		<div class="container px-0">
		    <div className="pl-0 text-left col-xs-10 col-sm-10 col-lg-12 col-sm-offset-1">
			    <h1 title={props.txt}>{props.h1}</h1>				
			   {props.h2&& <h2>{props.h2}</h2>}			    
			   {props.txt && <p>{props.txt}</p>}
		    </div>    
	    </div>
	</div>  
    </header>
)
}

export default Header;

