export const frm_titulo = 'Consulta de Normativas';
export const label_detalle = 'Buscar por texto exacto';
export const label_resultadosBA='Búsqueda sobre los resultados obtenidos'
export const label_tn = '-- Tipo de Norma --';
export const value_tn3 = 'Decreto';
export const value_tn4 = 'Decisión Administrativa';
export const value_tn7 = 'Resolución Conjunta';
export const value_tn2 = 'Ley';
export const value_tn5 = 'Resolución';
export const value_tn6 = 'Disposición';
export const value_tn8 = 'DNU';
export const label_nn = 'N° de Norma';
export const label_n1 = '-- Jurisdicción --';
export const label_t = '-- Temas --';
export const value_t1 = 'Estructura';
export const value_t2 = 'Designaciones';
export const value_t3 = 'Objetivos';
export const value_t4 = 'Extraescalafonarios (CFN)';
export const label_desde = 'Desde';
export const label_hasta = 'Hasta';
export const label_button = 'Buscar';
export const label_apellido='Apellido o Unidad Organizacional';
export const filtroPH=  'Filtrar resultados'