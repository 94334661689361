import { useEffect, useState, useContext } from "react";
import { Row, Col} from 'react-bootstrap'
import {FaSitemap, FaPrint, FaSearch,FaListUl} from "react-icons/fa";  
import {BiZoomIn, BiZoomOut} from 'react-icons/bi'
import Context from '../context';
import {buscarUnidad} from './buscarUnidad'
import {buscarUnidadGrafico} from './buscarUnidadGrafico'
import {FiDelete} from 'react-icons/fi'
import $ from 'jquery';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Tipo_Vista(props) {
  //Retorna la recursera que se encuentra arriba del orgranigrama escalar y que está compuesta por el título con el tipo de organigrama, el buscador de Unidad/Autoridad, los botones para cambiar el tipo de organigrama y el botón de Imprimir página.
  var zoom=100;
  const context=useContext(Context);
  const [varButton, setVarButton]=useState(false)
  const [titulo, setTitulo]=useState('ORGANIGRAMA')
  const change=(e)=>{
    const valor=e.currentTarget.value; 
    context.changeOrganigrama(valor)
  }

//Función que agrega el ícono "Delete" una vez que el input del buscador contiene texto.
  function createDelete(){
    var remove=document.getElementById('delete');
    var filtro= document.getElementById('filtrar');	
      if(filtro.value!==''){
      remove.style.display="inline";
    }
    else{
      remove.style.display="none";
    }
    return false;
  }



function createDelete(){
  //Agrega al DOM el boton de borrar que al presionarlo vacía el input del buscador de texto mediante la funcion remove()
	
	var filtro= document.querySelector('#filtrar');  
	  if(filtro.value!==undefined){
		if(filtro.value!==''){
		  document.querySelector('#delete2').style.display="inline";
		}
		else{
		  document.querySelector('#delete2').style.display="none";
		}      
	}
	else{
	  document.querySelector('#delete2').style.display="none";
	}
	return false;
  }
  
  function remove(){ 
    //Vacía el input del Buscador de Unidad/Autoridad
    $('.found').removeClass('found');
	var filtro= document.querySelector('#filtrar');
	filtro.value="";
	var remove=document.querySelector('#delete2');
	remove.style.display="none";
  }

function setFontSize(val){
   zoom=zoom+val
   $('.ulEscalar').css('font-size',zoom+'%')
}


function fn(e){
  context.setValidarFilt(e.target.value)
  createDelete()
}

 	


function EnterSearch(e){
  if(e.keyCode == 13){
    if (context.tipoOrganigrama==='Escalar'){
      buscarUnidad()
    }
      else{
      buscarUnidadGrafico()
      }
  }
}

  useEffect( ()=>{
     
    context.setValidarFilt('')
    if(context.radio=="rb-ee" || context.radio=="rb-oe" || context.radio=="ueet"){
      setVarButton(true)
    }
    else{
      setVarButton(false)
    }
    changeTitulo();
   },[context.radio, context.tipoOrganigrama]) //Se renderiza el componente nuevamente cada vez que cambia el tipo de organigrama. 

   const changeTitulo=()=>{   
    if(context.tipoOrganigrama=='Escalar'){ 
    setTitulo('ORGANIGRAMA ESCALAR')
   } 
   else if(context.tipoOrganigrama=='Grafico'){
    setTitulo('ORGANIGRAMA GRÁFICO DE UNIDADES ORGANIZATIVAS')
   }
  }

  function imprimir (){
    window.print("");
  }
  
  return (
    <>
    <Row className="mb-0 subtitulo ft-azul mt-3 align-items-end mx-0 px-0 justify-content-between pb-1">   
     <Col className="px-0 mx-0 align-items-start tipoVistaEscalar d-flex" lg={8} md={8} xl={8} sm={10}>         
            {context.tipoOrganigrama==='Grafico'?<FaSitemap size={20}  className="sitemap mb-0 ml-2 ft-04"/>:<FaListUl size={20} className="mr-2 ft-04"/> }        
            <Col className="mx-0 px-0 titleCol" lg={10} sm={11}>
             {context.radio !== 'rb-oe' && context.radio !== 'rb-ee' &&
              <h3 className="ml-1">{titulo} </h3>}      
             {props.min && <small className="noSpace">| {context.tipoVista}</small>}
            </Col>        
     </Col>
    
     <Col className="tvIconos d-flex text-right pr-0" lg={2} md={3}  xl={1} sm={2}>
       
        <button onClick={change} title="ORGANIGRAMA ESCALAR" alt="ORGANIGRAMA ESCALAR" className="buttonTipoVista" value="Escalar" disabled={context.tipoOrganigrama=='Escalar'?true:false}>
          <FaListUl size={20}  className="mr-1 mb-0 ft-04"/> 
        </button>
        <button onClick={change} title="ORGANIGRAMA GRÁFICO DE UNIDADES ORGANIZATIVAS" alt="ORGANIGRAMA GRÁFICO DE UNIDADES ORGANIZATIVAS" disabled={context.tipoOrganigrama=='Grafico'||varButton?true:false} className="buttonTipoVista" value='Grafico'>
          <FaSitemap size={20} className="sitemap mb-0 ml-1 ft-04"/>
        </button>  
        <button onClick={imprimir} alt="Imprimir" title="Imprimir" className="buttonTipoVista" >
          <FaPrint  size={20} className="ml-1 ft-04"/>
        </button>
     </Col>

    </Row>
         <ToastContainer
position="top-center"
autoClose={5000}
toastStyle={{ backgroundColor: "#e7c5c5" }}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>    
    </>
  );
}

export default Tipo_Vista;