import {Container} from 'react-bootstrap'

function Errores(props){


    return(
    <>
    { props.desc &&
        <Container className="my-4 p-3" id="cuadroDeErrores">
            <h4>Error</h4> 
            <p>{props.desc}</p>
        </Container>}
    </>
)
 
    }

export default Errores;